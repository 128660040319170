import { observable, action, toJS } from 'mobx';

export default class KickFromChatParamsModel {
  @observable userSpec: string = '';

  constructor({ userSpec }) {
    this.userSpec = userSpec;
  }

  @action.bound
  setUserSpec(value) {
    this.userSpec = value;
  }

  validate = () => {
    const errors = [];
    if (!this.userSpec || !this.userSpec.trim()) {
      errors.push('Не заполнено поле кого удалить из беседы.');
    }

    return errors;
  };

  toJson() {
    return toJS({
      member_spec: this.userSpec
    });
  }

  static createDefault(): KickFromChatParamsModel {
    return new KickFromChatParamsModel({
      userSpec: ''
    });
  }

  static fromJson(params): KickFromChatParamsModel {
    return new KickFromChatParamsModel({
      userSpec: params.member_spec
    });
  }
}
