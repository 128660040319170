import React from 'react';

import HelpWithText from './HelpWithText';

type Props = {
  url: string,
  text?: string
};

const DocsHelp = ({ url, text }: Props) => (
  <a href={url} target="_blank">
    <HelpWithText isLink title={text} description="Инструкция" />
  </a>
);

DocsHelp.defaultProps = {
  text: 'Помощь'
};

export default React.memo(DocsHelp);
