import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Skeleton, message, Button, Divider } from 'antd';

import { landing as landingUrl } from 'config/urls';
import EditBarLanding from 'components/landing/elements/EditBarLanding';
import ItemComponent from 'components/landing/elements/ItemComponent';
import LandingModel from 'models/Landing/LandingModel';
import { PageLoadableComponent } from 'components/PageLoader/PageLoader';
import { getHashParam, HASH_PARAMS } from 'types/hashParams';
import { landingGlobalLink } from 'types/moduleLanding';

import EditButtons from './EditButtons';
import {
  Root,
  ButtonWrapper,
  ItemsContainer,
  LinkBack,
  GlobalLinkBack,
} from './styles';

type Props = {
  preview: boolean;
  id?: string;
};

const ModuleLanding: React.FC<Props> = (props) => {
  const { preview, id } = props;

  const [isEditMode, setIsEditMode] = useState(false);

  const landing = useMemo(() => LandingModel.createDefault(), []);

  const handleLoad = useCallback(async (): Promise<void> => {
    if (preview) {
      // id может не быть, в отображении возьмется дефолтный лендинг
      await landing.loadView(id);
      if (landing?.vkPixel) {
        VK.Retargeting.Init(landing.vkPixel);
        VK.Retargeting.Hit();
      }
    } else {
      await landing.get(id);
    }
  }, []);

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  useEffect(() => {
    setIsEditMode(!preview);
  }, [preview]);

  const handleSave = useCallback(async (): Promise<void> => {
    const result = await landing.save();
    if (result) {
      message.success('Изменения сохранены');
    }
  }, [landing]);

  if (!landing.isLoaded && !isEditMode) {
    return <PageLoadableComponent />;
  }

  return (
    <Skeleton loading={!landing.isLoaded} active>
      <Root>
        <EditBarLanding landing={landing} isEditMode={isEditMode} />
        <ItemsContainer>
          {landing.itemList.map((item, index) => (
            <React.Fragment key={index}>
              <ItemComponent
                isEditMode={isEditMode}
                item={item}
                key={index}
                kind={item.kind}
                landing={landing}
                index={index}
              />
              {!isEditMode &&
                landing.itemsCount > 1 &&
                index < landing.itemsCount - 1 && <Divider />}
            </React.Fragment>
          ))}
        </ItemsContainer>
      </Root>
      {!isEditMode && window.IS_ADMIN && (
        <>
          {getHashParam(HASH_PARAMS.landingId) ? (
            <GlobalLinkBack href={landingGlobalLink()} target="_blank">
              <Button icon={<ArrowLeftOutlined />}>редактировать</Button>
            </GlobalLinkBack>
          ) : (
            <LinkBack to={landingUrl(id)}>
              <Button icon={<ArrowLeftOutlined />}>редактировать</Button>
            </LinkBack>
          )}
        </>
      )}
      {isEditMode && (
        <ButtonWrapper>
          <EditButtons id={id} onSave={handleSave} />
        </ButtonWrapper>
      )}
    </Skeleton>
  );
};

export default observer(ModuleLanding);
