import { observable, action, computed, toJS } from 'mobx';
import apiUrl from 'config/api';
import api from 'store/api';

import { CHAIN_TYPE } from '../types';
import BlockModel from './Block';
import apiUrls from '../config/api';
import { BlockPreviewModel } from './Block/BlockPreviewModel';

export const parseBlocks = (blocksToParse) =>
  blocksToParse.reduce(
    (acc, next) => ({
      ...acc,
      [next._id]: BlockPreviewModel.fromJson(next),
    }),
    {}
  );

class BlockStore {
  rootStore = null;

  @observable isCreatingNewBlock: boolean = false;

  @observable isLoading: boolean = false;

  @observable blockPreviews: Map<
    string,
    BlockModel | BlockPreviewModel
  > = new Map();

  @computed
  get blocksList() {
    return [...this.blockPreviews.values()];
  }

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action
  addBlock(block: BlockModel) {
    if (block.kind === CHAIN_TYPE.BLOCKCHAIN) {
      this.blockPreviews.set(block.id, block);
    }
  }

  @action
  async loadBlockPreviews() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    const { response } = await api(apiUrl.blocksList, 'GET', {
      limit: 5000,
      offset: 0,
      previews_only: true,
      kind: CHAIN_TYPE.BLOCKCHAIN,
    });

    if (response) {
      const blocksToParse = response.results;
      this.blockPreviews.merge(parseBlocks(blocksToParse));
    }
    this.isLoading = false;
  }

  @action
  setLoading = (isLoading) => (this.isLoading = isLoading);

  @action
  saveBlock = (block: BlockModel) => {
    this.addBlock(block);
  };

  @action
  createNewBlock = async (name, isCallback = false) => {
    this.isCreatingNewBlock = true;

    const newBlock = BlockModel.createDefault(
      {
        kind: CHAIN_TYPE.BLOCKCHAIN,
        name,
        withStepsGroup: false,
        isCallback,
      },
      this.rootStore
    );

    const result = await newBlock.save();

    this.isCreatingNewBlock = false;

    if (result) {
      this.blockPreviews.set(newBlock.id, newBlock);
      return newBlock;
    }

    return false;
  };

  @action
  deleteBlock = async (blockId) => {
    const { response } = await api(apiUrls.blocksDelete, 'POST', {
      _id: blockId,
    });
    if (response) {
      this.blockPreviews.delete(blockId);
      return true;
    }
    return false;
  };

  getBlock = (blockId): BlockModel | BlockPreviewModel | null =>
    this.blockPreviews.get(blockId);
}

export default BlockStore;
