import {
  isCorrectGoogleDocsRange,
  isCorrectGoogleDocsUrl
} from 'utils/validation';

export const validateSheetUrl = sheetUrl => {
  const errors = [];

  if (sheetUrl === '' || sheetUrl.trim() === '') {
    errors.push('URL таблицы не может быть пустым.');
  } else if (!isCorrectGoogleDocsUrl(sheetUrl)) {
    errors.push('Некорректный URL таблицы.');
  }

  return errors;
};

export const validateSheetRange = range => {
  const errors = [];

  if (!range) {
    errors.push('Введите ячейку или диапазон');
  } else if (!isCorrectGoogleDocsRange(range)) {
    errors.push('Некорректная ячейка. Формат: A1 или A1:B1 для диапазона');
  }

  return errors;
};
