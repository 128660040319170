import { combineReducers } from 'redux';
import update from 'immutability-helper';
import { all } from 'redux-saga/effects';
import user from './user';
import commands from './commands';
import settings from './settings';
import variables from './variables';
import lists from './lists';
import jobs from './jobs';

update.extend('$auto', function(value, object) {
  return object ? update(object, value) : update({}, value);
});

export const actions = {
  user: user.actions,
  variables: variables.actions,
  commands: commands.actions,
  settings: settings.actions,
  lists: lists.actions,
  jobs: jobs.actions
};

export const reducers = combineReducers({
  user: user.reducers,
  variables: variables.reducers,
  commands: commands.reducers,
  settings: settings.reducers,
  lists: lists.reducers,
  jobs: jobs.reducers
});

export const sagas = function* rootSaga() {
  yield all([
    ...commands.sagas,
    ...variables.sagas,
    ...settings.sagas,
    ...user.sagas,
    ...jobs.sagas
  ]);
};
