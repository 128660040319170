import { action, computed, observable } from 'mobx';
import * as Sentry from '@sentry/browser';
import connect from '@vkontakte/vk-bridge';

import apiProxy from 'store/api';
import api from 'config/api';
import initVk from 'utils/initVk';
import { BASIC_SCOPE, validateScope } from 'config/permissions';

import type * as T from './UserStore.types';
import normalizeGroupInfo from './normalize/normalizeGroupInfo';

class UserStore {
  rootStore;

  @observable groupInfo: T.GroupInfo;

  @observable userInfo: any;

  @observable isLoading = false;

  // Показывает ошибку "зайдите через вк"
  @observable vkAccessError = false;

  // Не удалось заинитить вк, скорее всего проблема с разрешением сообщения
  @observable initVKError = false;

  // Ошибка, если отключены сообщения в группе
  @observable initVKErrorAllowMessages = false;

  // Забанен?
  @observable isBannedError = false;

  @observable banReason = null;

  // Ошибка при логине / регистрации от вк
  @observable isVKServerError = false;

  // Все остальные типы ошибок
  @observable isSomeError = false;

  // Ошибки колбека
  @observable isCallbackOk = true;

  options = {};

  constructor(root) {
    this.rootStore = root;
  }

  @computed
  get userAge() {
    if (!this.userInfo?.bdate) {
      return null;
    }

    const bdateArray = this.userInfo.bdate.split('.');
    if (bdateArray.length === 3) {
      const [, , year] = bdateArray;
      const currentYear = new Date().getFullYear();
      return currentYear - Number(year);
    }

    return null;
  }

  @computed
  get isError() {
    return (
      this.vkAccessError ||
      this.initVKError ||
      this.isBannedError ||
      this.isVKServerError ||
      this.isSomeError ||
      this.initVKErrorAllowMessages
    );
  }

  @computed
  get errorMessage(): string {
    if (this.vkAccessError) {
      // eslint-disable-next-line max-len
      return 'Пожалуйста, зайдите в приложение в ВК с компьютера или через полную версию ВКонтакте (кнопка в мобильной версии ВК), чтобы выдать приложению права доступа, а также убедитесь, что «Сообщения сообщества» включены в Управлении сообществом';
    }
    if (this.initVKErrorAllowMessages) {
      return 'Пожалуйста, зайдите в настройки сообщества и включите «Сообщения сообщества», в настройках «Сообщения».';
    }
    if (this.initVKError) {
      return 'Не удалось получить необходимые разрешения от ВКонтакте. Перезагрузите страницу и попробуйте еще раз';
    }
    if (this.isBannedError) {
      return `Доступ к данной группе заблокирован. Причина: ${
        this.banReason || 'спам'
      }`;
    }
    if (this.isVKServerError) {
      return 'При инициализации SmartBot в вашей группе произошла ошибка. Убедитесь, что у Вас в группе включены сообщения. \n Откройте "управление группой", выберите вкладку "сообщения" и включите их! \n Затем заново зайдите в приложение';
    }
    if (this.isSomeError) {
      return 'Произошла ошибка. Перезагрузите страницу и попробуйте еще раз';
    }

    return '';
  }

  // eslint-disable-next-line class-methods-use-this
  get hasAdminAccess() {
    return window.IS_ADMIN || window.IS_DEV;
  }

  @action.bound callbackErrorAllowMessages() {
    console.log('callbackErrorAllowMessages');
    this.initVKErrorAllowMessages = true;
  }

  @action.bound
  async handleResponse(response): Promise<boolean> {
    if (response.error) {
      if (response.errorData?.error === 'banned') {
        this.isBannedError = true;
        this.banReason = response.errorData?.reason || 'Спам';
      } else if (response.errorData?.error === 'vk_error') {
        this.isVKServerError = true;
      } else {
        this.isSomeError = true;
      }
      return false;
    }

    window.scope = response?.response?.token_scopes || BASIC_SCOPE;

    return true;
  }

  @action.bound
  async registration(): Promise<boolean> {
    const { moduleStore } = this.rootStore;

    try {
      const access_token = window.ACCESS_TOKEN;
      const responseFromRegistration = await apiProxy<{
        managers: T.ManagerApi[];
        callback_ok: boolean;
        modules: T.ModuleApi[];
      }>(`${api.register}${window.LOGIN_PARAMS}`, 'POST', {
        token_scopes: BASIC_SCOPE,
        group_access_token: access_token,
      });

      const success = this.handleResponse(responseFromRegistration);

      const { response } = responseFromRegistration;

      if (success) {
        window.ADMINS = response?.managers;
      }

      this.isCallbackOk = response?.callback_ok;

      moduleStore.setModules(response?.modules);

      return success;
    } catch (error) {
      this.isSomeError = true;
      return false;
    }
  }

  @action.bound
  async setToken(scope = BASIC_SCOPE): Promise<boolean> {
    try {
      const responseFromSetToken = await apiProxy(api.setAccessToken, 'POST', {
        token_scopes: scope,
        group_access_token: window.ACCESS_TOKEN,
      });
      const result = this.handleResponse(responseFromSetToken);

      if (result) {
        window.scope = scope;
      }

      return result;
    } catch (error) {
      this.isSomeError = true;
      return false;
    }
  }

  @action.bound
  async loginErrorFlow(error, errorData): Promise<boolean> {
    if (error?.response?.status === 401) {
      if (!window.IS_IFRAME) {
        this.vkAccessError = true;
        return false;
      }

      const success = await initVk(undefined, this.callbackErrorAllowMessages);

      if (!success) {
        this.vkAccessError = true;
        return false;
      }

      return this.registration();
    }
    if (errorData?.error === 'banned') {
      this.isBannedError = true;
      this.banReason = errorData?.reason || 'Спам';
    }
    this.isSomeError = true;
    return false;
  }

  @action.bound
  async initFlow(): Promise<boolean> {
    const { moduleStore } = this.rootStore;

    const { response, error, errorData } = await apiProxy<{
      _id: string;
      managers: T.ManagerApi[];
      callback_ok: boolean;
      options: any;
      modules: T.ModuleApi[];
      token_scopes: string[];
      group_info: T.GroupInfoApi;
    }>(`${api.login}${window.LOGIN_PARAMS}`);

    if (error) {
      return this.loginErrorFlow(error, errorData);
    }

    this.isCallbackOk = response?.callback_ok;
    this.options = response?.options || {};

    moduleStore.setModules(response.modules);

    if (window.IS_PRODUCTION) {
      Sentry.configureScope((scope) => {
        scope.setTag('bot_id', response._id);
      });
    }

    window.BOT_ID = response._id;
    window.scope = response.token_scopes;
    window.ADMINS = response.managers;
    const needPermissions = !validateScope(BASIC_SCOPE);

    if (response?.group_info) {
      this.groupInfo = normalizeGroupInfo(response.group_info);
    }

    if (!window.IS_IFRAME) {
      window.FULLSCREEN = true;
      return true;
    }

    try {
      this.userInfo = await connect.send('VKWebAppGetUserInfo', {});
    } catch (e) {}

    if (!needPermissions) {
      return true;
    }

    const successInitVk = await initVk(
      undefined,
      this.callbackErrorAllowMessages
    );
    if (!successInitVk) {
      this.vkAccessError = true;
      return false;
    }

    return this.setToken();
  }
}

export default UserStore;
