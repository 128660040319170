import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { globalSpacingStyle, mediaSizes } from 'styles/constants';

export const Root = styled.div`
  display: block;
  position: relative;
  overflow: auto;
  min-height: 100%;
`;

export const ButtonWrapper = styled.div`
  position: fixed;
  right: 50px;
  bottom: 50px;

  .is-mobile & {
    right: 20px;
    bottom: 30px;
  }
`;

export const ItemsContainer = styled.div`
  position: relative;
  padding: ${globalSpacingStyle.m};
  max-width: ${mediaSizes.maxWidthLanding};
  margin: 0 auto;
`;

export const LinkBack = styled(Link)`
  display: block;
  position: absolute;
  top: ${globalSpacingStyle.l};
  left: ${globalSpacingStyle.l};
  font-size: 16px;
`;

export const GlobalLinkBack = styled.a`
  display: block;
  position: absolute;
  top: ${globalSpacingStyle.l};
  left: ${globalSpacingStyle.l};
  font-size: 16px;
`;

export const UserViewWrapper = styled.div`
  min-height: 100%;
  padding-bottom: 50px;
`;

export const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: right;
  background: white;
  padding: ${globalSpacingStyle.m} ${globalSpacingStyle.l};
  box-sizing: border-box;
  font-weight: bold;
  color: rgb(8, 49, 107);

  .is-mobile & {
    text-align: center;
  }
`;
