import ProductItemModel from 'models/Landing/Elements/ProductItemModel';
import SubscribeItemModel from 'models/Landing/Elements/SubscribeItemModel';
import BasicItemModel from 'models/Landing/Elements/BasicItemModel';
import { LANDING_ITEM_KIND } from 'types/moduleLanding';

export default items => {
  const map = new Map();
  items.forEach(item => {
    switch (item.kind) {
      case LANDING_ITEM_KIND.SUBSCRIPTION:
        map.set(item._id, SubscribeItemModel.fromJson(item));
        break;
      case LANDING_ITEM_KIND.PRODUCT:
        map.set(item._id, ProductItemModel.fromJson(item));
        break;
      case LANDING_ITEM_KIND.BASIC:
        map.set(item._id, BasicItemModel.fromJson(item));
        break;
      default:
        map.set(item._id, BasicItemModel.fromJson(item));
    }
  });
  return map;
};
