import React from 'react';
import { message } from 'antd';
import moment from 'moment';
import Cookies from 'js-cookie';
import queryString from 'query-string';

import api, { vkApiProxy as vk, vkClientApi } from 'store/api';
import apiUrl from 'config/api';
import generateId from 'utils/generateId';

import getUserAccessToken from '../../../utils/getUserAccessToken';

import {
  Wrapper,
  Button,
  Avatar,
  Iframe,
  Referrer,
  ReferrerInfo,
  HeadWrapper,
} from './styles';

export default class NewLanding extends React.Component {
  static getReferrer() {
    const r = /referrer=([a-zA-Z\d\-_]{6,32})/gm;
    const groups = r.exec(window.location.hash);
    return groups && groups.length >= 2 ? groups[1] : null;
  }

  static getUtmParams() {
    const utmKeys = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content',
    ];

    // удаляем из хэша /, ?, #
    const cleanedHash = window.location.hash.replace(/[#?/]+/, '');
    const searchParams = new URLSearchParams(cleanedHash);

    const utmParams = {};

    searchParams.forEach((value, key) => {
      if (utmKeys.includes(key)) {
        utmParams[key] = value;
      }
    });

    return utmParams;
  }

  state = {
    groupName: `public${window.GROUP_ID}`,
    groupPhoto: null,
    buttonVisible: false,
    isSubscribed: false,
  };

  referrer = NewLanding.getReferrer();

  utmParams = NewLanding.getUtmParams();

  async componentDidMount() {
    if (this.referrer) {
      Cookies.set('referrer', encodeURIComponent(this.referrer), {
        sameSite: 'None',
        secure: true,
      });
    } else {
      Cookies.remove('referrer');
    }

    if (!window.GROUP_ID) {
      return;
    }

    if (window.IS_IFRAME) {
      window.USER_TOKEN = await getUserAccessToken(window.APP_ID);
    }

    const { response: result } = await vk(
      'groups.getById',
      { group_id: window.GROUP_ID },
      window.USER_TOKEN
    );

    let { groupPhoto, groupName } = this.state;
    if (result) {
      const [{ name, photo_50 }] = result;
      groupName = name;
      groupPhoto = photo_50;
    }

    const { response, error } = await api(
      `${apiUrl.deliveryIsSubscribed}${window.LOGIN_PARAMS}`
    );

    if (response) {
      this.setState({
        isSubscribed: response.subscribed,
      });
    }

    this.setState({
      buttonVisible: true,
      groupName,
      groupPhoto,
    });
  }

  onSubscribe = async () => {
    const { isSubscribed } = this.state;
    const key = generateId();

    if (!isSubscribed) {
      const success = await vkClientApi('VKWebAppAllowMessagesFromGroup', {
        group_id: Number(window.GROUP_ID),
        key,
      });
      if (!success) {
        return;
      }
    }

    const url = isSubscribed
      ? apiUrl.deliveryUnsubscribe
      : apiUrl.deliverySubscribe;

    const { response, error } = await api(
      `${url}${window.LOGIN_PARAMS}`,
      'POST'
    );

    if (!error) {
      this.setState((state) => ({
        isSubscribed: !state.isSubscribed,
      }));

      message.success(
        `Вы успешно ${
          isSubscribed ? 'отписались от рассылки' : 'подписались на рассылку'
        }`
      );
    }
  };

  getInstallBtn = () => {
    return (
      <a
        href={`https://vk.com/add_community_app?aid=${window.APP_ID}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button type="primary">Установить в свое сообщество</Button>
      </a>
    );
  };

  render() {
    const { buttonVisible, isSubscribed, groupName, groupPhoto } = this.state;

    return (
      <Wrapper>
        {buttonVisible && window.GROUP_ID && (
          <HeadWrapper>
            <a
              target="_blank"
              href={`https://vk.com/write-${window.GROUP_ID}`}
              rel="noopener noreferrer"
            >
              <Button type="primary">Написать боту</Button>
            </a>
            <Button type="primary" onClick={this.onSubscribe}>
              {isSubscribed
                ? 'Отписаться от всех рассылок'
                : 'Подписаться на рассылки'}
            </Button>
            <Avatar bg={groupPhoto} /> {groupName}
          </HeadWrapper>
        )}
        {this.referrer && (
          <Referrer>
            Вы перешли по промокоду: {this.referrer}
            <ReferrerInfo>
              При первой оплате Вы получите дополнительно +10% на баланс!
            </ReferrerInfo>
            <div>{this.getInstallBtn()}</div>
          </Referrer>
        )}
        <Iframe
          src={`https://smartbot-vk.ru/?${queryString.stringify(
            this.utmParams
          )}`}
        />
      </Wrapper>
    );
  }
}
