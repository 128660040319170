import { observable, action, toJS } from 'mobx';
import { TransitionParamsModel } from './TransitionParams';

export class NoAnswerTimerModel {
  @observable enabled: boolean = false;

  @observable interval: number = 0;
  @observable unit: string = 'minutes';

  @observable transition: TransitionParamsModel = new TransitionParamsModel();

  constructor(enabled, interval, unit, transition) {
    this.enabled = enabled;
    this.interval = interval;
    this.unit = unit;
    this.transition = transition;
  }

  @action
  toggle = () => {
    this.enabled = !this.enabled;
  };

  @action
  setInterval = interval => {
    this.interval = interval;
  };

  @action
  setUnit = unit => {
    this.unit = unit;
  };

  @action
  updateTransition = this.transition.update;

  toJson() {
    return toJS({
      enabled: this.enabled,
      interval: this.interval,
      unit: this.unit,
      ...this.transition.toJson()
    });
  }

  static createDefault(): NoAnswerTimerModel {
    return new NoAnswerTimerModel(
      false,
      5,
      'minutes',
      TransitionParamsModel.createDefault()
    );
  }

  static fromJson(params): NoAnswerTimerModel {
    return new NoAnswerTimerModel(
      params.enabled,
      params.interval,
      params.unit,
      TransitionParamsModel.fromJson(params)
    );
  }
}

export default class WaitParamsModel {
  @observable noAnswerTimer: NoAnswerTimerModel = new NoAnswerTimerModel();

  constructor(noAnswerTimer) {
    this.noAnswerTimer = noAnswerTimer;
  }

  validate = () => {
    let errors = [];
    if (this.noAnswerTimer?.enabled) {
      errors = this.noAnswerTimer.transition.validate();
    }
    return errors;
  };

  toJson() {
    return toJS({
      no_answer_timer: this.noAnswerTimer.toJson()
    });
  }

  static createDefault(): WaitParamsModel {
    return new WaitParamsModel(NoAnswerTimerModel.createDefault());
  }

  static fromJson(params): WaitParamsModel {
    return new WaitParamsModel(
      NoAnswerTimerModel.fromJson(params.no_answer_timer)
    );
  }
}
