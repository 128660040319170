import { action, observable, toJS } from 'mobx';

import apiUrls from 'config/api';
import api from 'store/api';
import type { SubscribeItemType } from 'types/moduleLanding';
import {
  LANDING_ITEM_KIND,
  ITEM_STATUS,
  LANDING_ITEM_BUTTON
} from 'types/moduleLanding';

import ItemModel, { LandingButtonModel } from './ItemModel';

class SubscribeItemModel extends ItemModel {
  @observable listId: string = null;

  @observable status: string = ITEM_STATUS.UNSUBSCRIBED;

  @action.bound
  setDescription(value) {
    super.setDescription(value);
  }

  @action.bound
  setTitle(value) {
    super.setTitle(value);
  }

  @action.bound
  setListId(value) {
    this.listId = value;
  }

  constructor({
    id,
    kind,
    title,
    description,
    imageUrl,
    blockId,
    listId,
    buttons,
    status
  }) {
    super({
      kind,
      title,
      description,
      imageUrl,
      blockId,
      listId,
      id,
      buttons
    });
    this.listId = listId;
    if (status) {
      this.status = status;
    } else {
      this.status = ITEM_STATUS.UNSUBSCRIBED;
    }
  }

  @action.bound
  async subscribe() {
    const { response } = await api(
      `${apiUrls.mailingsSubscribe}${window.LOGIN_PARAMS}`,
      'POST',
      {
        list_id: this.listId,
        block_id: this.blockId
      },
      {
        errorConfig: {
          isShowError: true,
          defaultMessage: 'Ошибка! Не получилось подписаться на рассылку'
        }
      }
    );

    if (response) {
      this.status = ITEM_STATUS.SUBSCRIBED;
      return true;
    }
    return false;
  }

  @action.bound
  async unsubscribe() {
    const { response, error } = await api(
      `${apiUrls.mailingsUnsubscribe}${window.LOGIN_PARAMS}`,
      'POST',
      {
        list_id: this.listId,
        block_id: this.blockId
      },
      {
        errorConfig: {
          isShowError: true,
          defaultMessage: 'Ошибка! Не получилось отписаться от рассылки'
        }
      }
    );

    if (response) {
      this.status = ITEM_STATUS.UNSUBSCRIBED;
      return true;
    }
    return false;
  }

  getSubscribeButton(): LandingButtonModel {
    return this.buttons[LANDING_ITEM_BUTTON.subscription.subscribe];
  }

  getUnsubscribeButton(): LandingButtonModel {
    return this.buttons[LANDING_ITEM_BUTTON.subscription.unsubscribe];
  }

  toJson() {
    return toJS({
      list_id: this.listId,
      ...super.toJson()
    });
  }

  static createDefault(): SubscribeItemModel {
    return new SubscribeItemModel({
      kind: LANDING_ITEM_KIND.SUBSCRIPTION,
      title: 'Заголовок',
      description: 'Описание',
      imageUrl: null,
      blockId: null,
      listId: null,
      status: ITEM_STATUS.UNSUBSCRIBED,
      buttons: {
        [LANDING_ITEM_BUTTON.subscription
          .subscribe]: LandingButtonModel.createDefault('Подписаться', true),
        [LANDING_ITEM_BUTTON.subscription
          .unsubscribe]: LandingButtonModel.createDefault('Отписаться', false)
      }
    });
  }

  static fromJson(params: SubscribeItemType): SubscribeItemModel {
    return new SubscribeItemModel({
      listId: params.list_id,
      status: params.status,
      ...super.fromJson(params)
    });
  }
}

export default SubscribeItemModel;
