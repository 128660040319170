import styled from 'styled-components';

import { color, globalSpacingStyle } from 'styles/constants';

export const Title = styled.div`
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: ${color.placeholder};
`;
