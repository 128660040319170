import { observable, action, toJS } from 'mobx';

class SleepParamsModel {
  @observable interval = 0;

  @observable unit = 'minutes';

  @observable wakeOnMessage = false;

  constructor(interval, unit, wakeOnMessage) {
    this.interval = interval;
    this.unit = unit;
    this.wakeOnMessage = wakeOnMessage;
  }

  @action
  setInterval = (interval) => {
    if (interval > 0) {
      this.interval = interval;
    }
  };

  @action
  setUnit = (unit) => {
    this.unit = unit;
  };

  @action
  toggleWakeOnMessage = () => {
    this.wakeOnMessage = !this.wakeOnMessage;
  };

  toJson() {
    return toJS({
      interval: this.interval,
      unit: this.unit,
      wake_on_message: this.wakeOnMessage,
    });
  }

  validate = () => {
    return [];
  };

  static createDefault(): SleepParamsModel {
    return new SleepParamsModel(5, 'minutes', true);
  }

  static fromJson(params): SleepParamsModel {
    return new SleepParamsModel(
      params.interval,
      params.unit,
      params.wake_on_message
    );
  }
}

export default SleepParamsModel;
