import { observable, action, toJS, computed } from 'mobx';

import { toBeautyJson } from 'components/SQEditor/JsonEditor';
import { validateJson } from 'utils/validation';

import { HTTP_REQUEST_BODY_MODE, HTTP_REQUEST_METHOD } from './types';

class KeyValueField {
  @observable key = '';

  @observable value = '';

  constructor({ key, value }) {
    this.key = key;
    this.value = value;
  }

  @action.bound
  setKey(key) {
    this.key = key;
  }

  @action.bound
  setValue(value) {
    this.value = value;
  }

  static createDefault(): KeyValueField {
    return new KeyValueField({
      key: '',
      value: '',
    });
  }
}

class KeyValueBody {
  @observable fields: KeyValueField[] = [];

  constructor({ fields }) {
    this.fields = fields;
  }

  @action.bound
  deleteField(index) {
    this.fields.splice(index, 1);
  }

  @action.bound
  addField() {
    this.fields.push(KeyValueField.createDefault());
  }

  toJson() {
    return this.fields.reduce(
      (acc, next) => ({
        ...acc,
        [next.key]: next.value,
      }),
      {}
    );
  }

  static createDefault(): KeyValueBody {
    return new KeyValueBody({
      fields: [KeyValueField.createDefault()],
    });
  }

  static createEmpty(): KeyValueBody {
    return new KeyValueBody({
      fields: [],
    });
  }

  static fromJson(json) {
    try {
      return new KeyValueBody({
        fields: Object.keys(json).reduce(
          (acc, next) => [
            ...acc,
            new KeyValueField({ key: next, value: json[next] }),
          ],
          []
        ),
      });
    } catch (e) {
      return new KeyValueBody({ fields: [] });
    }
  }
}

class HttpRequestParams {
  @observable url = '';

  @observable method = HTTP_REQUEST_METHOD.GET;

  @observable headers: KeyValueBody;

  @observable params = {};

  @observable bodyMode = HTTP_REQUEST_BODY_MODE.FORM;

  @observable body: KeyValueBody | string;

  @observable respBodyTarget = '%body%';

  @observable respHeadersTarget = '%headers%';

  @observable respStatusTarget = '%status%';

  @computed
  get isShowBody(): boolean {
    return [
      HTTP_REQUEST_METHOD.POST,
      HTTP_REQUEST_METHOD.PATCH,
      HTTP_REQUEST_METHOD.PUT,
    ].includes(this.method);
  }

  constructor({
    url,
    method,
    headers,
    params,
    bodyMode,
    body,
    respBodyTarget,
    respHeadersTarget,
    respStatusTarget,
  }) {
    this.url = url;
    this.method = method;
    this.headers = headers;
    this.params = params;
    this.bodyMode = bodyMode;
    this.body = body;
    this.respBodyTarget = respBodyTarget;
    this.respHeadersTarget = respHeadersTarget;
    this.respStatusTarget = respStatusTarget;
  }

  @action.bound
  setMethod = (method: HTTP_REQUEST_METHOD) => {
    this.method = method;

    if (!this.isShowBody) {
      this.setBodyMode(HTTP_REQUEST_BODY_MODE.NONE);
    }
  };

  @action.bound
  setUrl = (url: string) => {
    this.url = url;
  };

  @action.bound
  setBody = (body: KeyValueBody | string) => {
    this.body = body;
  };

  @action.bound
  setRespBodyTarget = (target: string) => {
    this.respBodyTarget = target;
  };

  @action.bound
  setRespStatusTarget = (target: string) => {
    this.respStatusTarget = target;
  };

  @action.bound
  setRespHeadersTarget = (target: string) => {
    this.respHeadersTarget = target;
  };

  @action.bound
  setBodyMode = (bodyMode: HTTP_REQUEST_BODY_MODE) => {
    const isValidJsonBody = validateJson(this.body);

    switch (bodyMode) {
      case HTTP_REQUEST_BODY_MODE.FORM:
        if (isValidJsonBody) {
          this.body = KeyValueBody.fromJson(JSON.parse(this.body as string));
        } else {
          this.body = KeyValueBody.createDefault();
        }
        break;
      case HTTP_REQUEST_BODY_MODE.JSON:
        if (this.bodyMode === HTTP_REQUEST_BODY_MODE.FORM) {
          this.body = toBeautyJson((this.body as KeyValueBody).toJson());
        } else if (isValidJsonBody) {
          this.body = toBeautyJson(JSON.parse((this.body as string) || '{}'));
        } else {
          this.body = toBeautyJson({ key: 'value' });
        }
        break;

      case HTTP_REQUEST_BODY_MODE.NONE:
        this.body = null;
        break;
      case HTTP_REQUEST_BODY_MODE.RAW:
        this.body = '';
        break;
      default:
        if (this.bodyMode === HTTP_REQUEST_BODY_MODE.FORM) {
          this.body = toBeautyJson((this.body as KeyValueBody).toJson());
        }
        break;
    }

    this.bodyMode = bodyMode;
  };

  validate = (): string[] => {
    const errors = [];
    if (!this.url) {
      errors.push('Укажите url, на который необходимо отправить запрос');
    }
    if (
      this.bodyMode === HTTP_REQUEST_BODY_MODE.JSON &&
      !validateJson(this.body)
    ) {
      errors.push('Укажите валидный JSON в теле запроса');
    }
    return errors;
  };

  toJson() {
    return toJS({
      url: this.url,
      method: this.method,
      headers: this.headers.toJson(),
      params: this.params,
      body_mode: this.bodyMode,
      body:
        this.bodyMode === HTTP_REQUEST_BODY_MODE.FORM
          ? (this.body as KeyValueBody).toJson()
          : this.body,
      resp_body_target: this.respBodyTarget,
      resp_status_target: this.respStatusTarget,
      resp_headers_target: this.respHeadersTarget,
    });
  }

  static createDefault(): HttpRequestParams {
    return new HttpRequestParams({
      url: '',
      method: HTTP_REQUEST_METHOD.GET,
      headers: KeyValueBody.createEmpty(),
      params: {},
      bodyMode: HTTP_REQUEST_BODY_MODE.NONE,
      body: null,
      respBodyTarget: '%body%',
      respHeadersTarget: '%headers%',
      respStatusTarget: '%status%',
    });
  }

  static fromJson(json: any) {
    let { body } = json;

    if (json.body_mode === HTTP_REQUEST_BODY_MODE.FORM) {
      body = KeyValueBody.fromJson(json.body);
    }

    return new HttpRequestParams({
      url: json.url,
      method: json.method,
      headers: KeyValueBody.fromJson(json.headers),
      params: json.params,
      bodyMode: json.body_mode,
      body,
      respBodyTarget: json.resp_body_target,
      respHeadersTarget: json.resp_headers_target,
      respStatusTarget: json.resp_status_target,
    });
  }
}

export { KeyValueField, KeyValueBody };
export default HttpRequestParams;
