// @flow
import React from 'react';
import { observer } from 'mobx-react';
import { Divider } from 'antd';

import ListsSelector from 'components/Auditories/ListsSelector';

import { Title } from '../styles';

type Props = {
  title: string,
  value: string,
  onChange: (value: string) => void
};

@observer
class EditSelectorList extends React.Component<Props> {
  render() {
    const { title, value, onChange } = this.props;
    return (
      <div>
        <Divider>
          <Title>{title}</Title>
        </Divider>
        <>
          <ListsSelector
            onSelect={onChange}
            selectedList={value}
            canSelectAll
          />
        </>
      </div>
    );
  }
}

export default EditSelectorList;
