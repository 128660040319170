import { toJS } from 'mobx';

import CarouselTemplate from './templates/CarouselTemplate';
import type * as T from './TemplateModel.types';

type TempalteModelData = { type: T.Type };

class TemplateModel {
  type: T.Type;

  constructor(data: TempalteModelData) {
    const { type } = data;

    this.type = type;
  }

  toJson = (): { [key: string]: any } => {
    return toJS({
      type: this.type,
    });
  };

  validate = (): string[] => {
    return [];
  };

  static createModelByType<D extends TempalteModelData>(
    data: D
  ): TemplateModel {
    switch (data.type) {
      case 'carousel': {
        return new CarouselTemplate(data);
      }
      default:
        return new TemplateModel(data);
    }
  }
}

export { TempalteModelData };

export default TemplateModel;
