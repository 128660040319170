import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';

import {
  createList,
  CREATE_LIST,
  uploadUsersToList
} from 'store/lists/actions';
import Modal from 'components/Modal';
import Input from 'components/Input';

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onCreate: () => void
};

const Element = styled.div`
  margin: 10px 0;
`;

export default ({ isOpen, onClose = () => {}, onCreate = () => {} }: Props) => {
  const inputRef = useRef();
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);
  const [createdList, setCreatedList] = useState(null);

  const onCreateList = useCallback(
    data => {
      setFile(null);
      inputRef.current.value = '';
      onCreate(data);
      onClose();
    },
    [onCreate, onClose]
  );

  const uploadFile = async createdListId => {
    const { response } = await uploadUsersToList(createdListId, file);
    if (response) {
      message.success('Подписчики успешно загружены!');
      return true;
    }
    return false;
  };

  const onSave = async () => {
    const name = inputRef.current.input.value;
    if (!name) {
      return;
    }

    if (createdList) {
      if (await uploadFile(createdList._id)) {
        onCreateList(createdList);
      }
      return;
    }

    const createResult = await dispatch(createList(name));

    if (createResult.type === CREATE_LIST) {
      message.success('Список успешно создан!');

      const createdResultList = createResult?.payload?.list;

      if (file && createdResultList) {
        const res = await uploadFile(createdResultList._id);
        if (res) {
          onCreateList(createdResultList);
        } else {
          setCreatedList(createdResultList);
        }
      } else {
        onCreateList(createdResultList);
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Создать новый список"
      footer={[
        <Button type="primary" htmlType="button" onClick={onSave}>
          {createdList ? 'Загрузить список' : 'Сохранить'}
        </Button>
      ]}
    >
      <Input placeholder="Введите название" ref={inputRef} />
      <Element>
        <p>
          Загрузите csv-файл со ссылками на пользователей по одному в строке
          <br />
          Вы можете сделать это позже в разделе левого меню
          &quot;Пользователи&quot;
        </p>
        <Upload
          accept=".txt,.csv"
          fileList={[file].filter(f => f)}
          beforeUpload={f => {
            setFile(f);
            return false;
          }}
          onRemove={f => setFile(null)}
        >
          <Button>
            <UploadOutlined /> Выберите файл
          </Button>
        </Upload>
      </Element>
    </Modal>
  );
};
