import { observable, action, toJS } from 'mobx';

import { validateSheetRange, validateSheetUrl } from './utils';

export default class GoogleSheetsSetParamsModel {
  @observable sheetUrl = '';

  @observable list = '';

  @observable range = '';

  @observable value = '';

  constructor({ sheetUrl, list, range, value }) {
    this.sheetUrl = sheetUrl;
    this.list = list;
    this.range = range;
    this.value = value;
  }

  @action.bound
  setUrl(sheetUrl) {
    this.sheetUrl = sheetUrl;
  }

  @action.bound
  setList(list) {
    this.list = list;
  }

  @action.bound
  setRange(range) {
    this.range = range;
  }

  @action.bound
  setValue(value) {
    this.value = value;
  }

  validate() {
    const errors = [];

    errors.push(...validateSheetUrl(this.sheetUrl));
    errors.push(...validateSheetRange(this.range));

    return errors;
  }

  toJson() {
    return toJS({
      spreadsheet_url: this.sheetUrl,
      value: this.value,
      range: this.range,
      list: this.list
    });
  }

  static createDefault(): GoogleSheetsSetParamsModel {
    return new GoogleSheetsSetParamsModel({
      sheetUrl: '',
      list: '',
      range: 'A1',
      value: ''
    });
  }

  static fromJson(params): GoogleSheetsSetParamsModel {
    const data = {
      value: params.value,
      list: params.list,
      range: params.range,
      sheetUrl: params.spreadsheet_url
    };

    return new GoogleSheetsSetParamsModel(data);
  }
}
