import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { globalSpacingStyle } from 'styles/constants';

export const ShowPreview = styled(Link)`
  position: relative;
  margin-right: ${globalSpacingStyle.s};

  .is-mobile & {
    display: block;
    margin-right: 0;
    margin-bottom: ${globalSpacingStyle.s};
  }
`;
