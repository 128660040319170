// @flow
import { Checkbox } from 'antd';
import React from 'react';
import styled from 'styled-components';

import HelpWithText from 'components/Help/HelpWithText';
import { globalSpacingStyle } from 'styles/constants';

type Props = {
  onChange: e => void,
  value: boolean
};

const StyledCheckbox = styled(Checkbox)`
  margin-top: ${globalSpacingStyle.s};
`;

const StyledHelp = styled.span`
  margin-left: ${globalSpacingStyle.m};
`;

export default (props: Props) => (
  <StyledCheckbox onChange={props.onChange} checked={props.value}>
    Открыть диалог с сообществом
    <StyledHelp>
      <HelpWithText
        title=""
        description="При нажатии на кнопку, у пользователя в новой вкладке
                 откроется диалог с вашим сообществом, где установлен бот"
      />
    </StyledHelp>
  </StyledCheckbox>
);
