// @flow
import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import AceEditor from 'react-ace';
import 'ace-builds';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-tomorrow';

import generateId from 'utils/generateId';

import { useEditorVarCompletions } from './hooks';

type Props = {
  jsonStr: string,
  onChange: (jsonStr: string) => void
};

export const toBeautyJson = json => JSON.stringify(json, null, '\t');

const JsonEditor = ({ jsonStr, onChange }: Props) => {
  const editorRef = useRef(null);
  const [uniqId] = useState(() => generateId());

  const varsCompletions = useEditorVarCompletions();

  return (
    <AceEditor
      placeholder="Введите JSON"
      width="100%"
      maxLines={Infinity}
      ref={editorRef}
      mode="json"
      theme="tomorrow"
      enableLiveAutocompletion={varsCompletions}
      value={jsonStr}
      onChange={onChange}
      name={uniqId}
      editorProps={{ $blockScrolling: true }}
    />
  );
};

export default observer(JsonEditor);
