import { observable, action, toJS } from 'mobx';
import { arrayMove } from 'react-sortable-hoc';

import { STEP } from 'types/step';
import generateId from 'utils/generateId';
import BlockModel from 'models/Block';

import { ConditionModel } from '../Condition';
import StepModel from '../Step/StepModel';

import { pushDown, pushUp } from './utils';

export default class StepsGroupModel {
  id: string = generateId();

  block = null;

  @observable name = '';

  @observable condition: ConditionModel = ConditionModel.createEmpty();

  @observable steps: Array<StepModel> = [];

  constructor({
    id,
    condition,
    steps,
    block,
    name,
  }: {
    id: string;
    name?: string;
    condition: ConditionModel;
    steps: StepModel[];
    block: BlockModel;
  }) {
    this.id = id;
    this.name = name;
    this.condition = condition;
    this.steps = steps;
    this.block = block;
  }

  static createDefaultStep(kind): Array<StepModel> {
    const steps = [];

    if (kind === STEP.SEND_MESSAGE_AND_WAIT) {
      steps.push(StepModel.createDefault(STEP.SEND_MESSAGE));
      steps.push(StepModel.createDefault(STEP.WAIT));
    } else {
      steps.push(StepModel.createDefault(kind));
    }

    return steps;
  }

  @action.bound
  setName(name: string) {
    this.name = name;
  }

  @action
  addStep = (kind: STEP) => {
    this.steps.push(...StepsGroupModel.createDefaultStep(kind));
  };

  @action
  deleteStep = (id) => {
    this.steps = this.steps.filter((s) => s._id !== id);
    // if (
    //   this.steps.length === 0 &&
    //   this.block &&
    //   this.block.kind === CHAIN_TYPE.BLOCKCHAIN
    // ) {
    //   this.block.deleteStepsGroup(this.id);
    // }
  };

  searchStep(id: string) {
    return this.steps.find((step) => step._id === id);
  }

  @action
  reorderSteps = (index: number, position: 'up' | 'down') => {
    if (position === 'up') {
      this.steps = pushUp(this.steps, index);
    } else if (position === 'down') {
      this.steps = pushDown(this.steps, index);
    }
  };

  @action
  addStepByIndex = (kind: STEP, index: number) => {
    const newSteps = StepsGroupModel.createDefaultStep(kind);

    const firstPart = this.steps.slice(0, index + 1);
    const secondPart = this.steps.slice(index + 1, this.steps.length);

    this.steps = [...firstPart, ...newSteps, ...secondPart];
  };

  @action
  sortSteps = (oldPosition, newPosition) => {
    this.steps = arrayMove(this.steps, oldPosition, newPosition);
  };

  validate = (isLast = false) => {
    let result = true;

    // if (this.steps.length === 0) {
    //   return false;
    // }

    result = this.condition.validate() && result;

    (this.steps || []).forEach((s, i) => {
      if (!s.validate(isLast && i === this.steps.length - 1)) {
        result = false;
      }
    });

    return result;
  };

  toJson() {
    return toJS({
      _id: this.id,
      ...this.condition.toJson(),
      name: this.name,
      steps: this.steps.map((s) => s.toJson()),
    });
  }

  static createDefault(block: BlockModel): StepsGroupModel {
    return new StepsGroupModel({
      id: generateId(),
      condition: ConditionModel.createEmpty(),
      steps: [StepModel.createDefault(STEP.SEND_MESSAGE)],
      block,
    });
  }

  static createWithEventTypeCondition(block: BlockModel): StepsGroupModel {
    return new StepsGroupModel({
      id: generateId(),
      condition: ConditionModel.createDefaultWithEventType(),
      steps: [],
      block,
    });
  }

  static fromJson(data, block): StepsGroupModel {
    return new StepsGroupModel({
      id: data._id,
      name: data.name,
      condition: ConditionModel.fromJson(data),
      steps: data.steps.map((stepData) => StepModel.fromJson(stepData)),
      block,
    });
  }
}
