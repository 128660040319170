import { action, observable, toJS } from 'mobx';

interface GoogleModuleStateModelContructor {
  token: boolean;
}

interface GoogleModuleStateModelFromJsonData {
  token_ok: boolean;
}

class GoogleModuleStateModel {
  @observable token = false;

  constructor({ token }: GoogleModuleStateModelContructor) {
    this.token = token;
  }

  @action.bound
  setToken(value: boolean): void {
    this.token = value;
  }

  toJson() {
    return toJS({
      token_ok: this.token,
    });
  }

  static createDefault(): GoogleModuleStateModel {
    return new GoogleModuleStateModel({
      token: false,
    });
  }

  static fromJson(
    params: GoogleModuleStateModelFromJsonData
  ): GoogleModuleStateModel {
    const data = {
      token: params.token_ok,
    };

    return new GoogleModuleStateModel(data);
  }
}

export { GoogleModuleStateModelContructor, GoogleModuleStateModelFromJsonData };

export default GoogleModuleStateModel;
