function findGetParameter(parameterName: string, loc: string): string {
  return (loc || window.location.search)
    .substr(1)
    .split('&')
    .reduce((acc, param) => {
      const [key, value] = param.split('=');

      return key === parameterName ? value : acc;
    }, null);
}

export default findGetParameter;
