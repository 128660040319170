import { action, observable, toJS } from 'mobx';

import apiUrls from 'config/api';
import api from 'store/api';
import type { ProductItemType } from 'types/moduleLanding';
import { LANDING_ITEM_BUTTON, LANDING_ITEM_KIND } from 'types/moduleLanding';
import { isMax } from 'utils/validation';

import yamErrorMessages from './config/yamErrorMessages';
import ItemModel, { LandingButtonModel } from './ItemModel';

export const MIN_YANDEX_PRICE = 2;

class ProductItemModel extends ItemModel {
  @observable name: string = '';

  @observable price: number = 100;

  @action.bound
  setName(value) {
    this.name = value;
  }

  @action.bound
  setTitle(value) {
    super.setTitle(value);
    this.setName(value);
  }

  @action.bound
  setDescription(value) {
    super.setDescription(value);
  }

  @action.bound
  setPrice(value) {
    this.price = Number(value);
  }

  @action.bound
  validate(): boolean {
    super.validate();

    if (isMax(String(this.price), 1024)) {
      this.errors.set('price', 'Максимальное число символов 1024');
    }
    if (this.price < MIN_YANDEX_PRICE) {
      this.errors.set(
        'price',
        `Укажите стоимость больше ${MIN_YANDEX_PRICE - 1}₽`
      );
    }
    if ((this.name || '').trim().length <= 0) {
      this.errors.set('title', 'Укажите название');
    }
    if (isMax(this.name, 1024)) {
      this.errors.set('title', 'Максимальное число символов 1024');
    }

    return !this.hasErrors;
  }

  constructor({
    id,
    kind,
    title,
    description,
    imageUrl,
    blockId,
    name,
    price,
    buttons
  }) {
    super({
      id,
      kind,
      title,
      description,
      imageUrl,
      blockId,
      buttons
    });
    this.name = name;
    this.price = Number(price);
  }

  getPayButton(): LandingButtonModel {
    return this.buttons[LANDING_ITEM_BUTTON.product.pay];
  }

  static createDefault(): ProductItemModel {
    return new ProductItemModel({
      kind: LANDING_ITEM_KIND.PRODUCT,
      title: 'Заголовок',
      description: 'Описание',
      imageUrl: null,
      blockId: null,
      name: 'Заголовок',
      price: 100,
      buttons: {
        [LANDING_ITEM_BUTTON.product.pay]: LandingButtonModel.createDefault(
          'Оплатить',
          false
        )
      }
    });
  }

  // купить товар
  @action.bound
  async pay(landingId) {
    const { response, error } = await api(
      `${apiUrls.yamPay}${window.LOGIN_PARAMS}`,
      'POST',
      {
        landing_id: landingId,
        item_id: this.id
      },
      {
        errorConfig: {
          isShowError: true,
          defaultMessage: errorData =>
            window.IS_ADMIN
              ? 'Ошибка при попытке произвести оплату'
              : errorData.desc || yamErrorMessages[errorData.error]
        }
      }
    );

    if (error) {
      return {
        isError: true
      };
    }

    if (response) {
      return {
        isError: false,
        redirectUrl: response.redirect_url
      };
    }
  }

  toJson() {
    return toJS({
      name: this.name,
      price: String(this.price),
      ...super.toJson()
    });
  }

  static fromJson(params: ProductItemType): ProductItemModel {
    return new ProductItemModel({
      ...super.fromJson(params),
      name: params.name,
      price: params.price
    });
  }
}

export default ProductItemModel;
