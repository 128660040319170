// @flow
import React from 'react';

import { LANDING_ITEM_KIND, LandingItemKindType } from 'types/moduleLanding';
import EditButtonProduct from 'components/landing/elements/ButtonProduct';
import EditButtonSubscription from 'components/landing/elements/ButtonSubscription';
import EditBasic from 'components/landing/elements/Basic';
import type { LandingItemModel } from 'models/Landing/Elements/models';
import LandingModel from 'models/Landing/LandingModel';

type Props = {
  item: LandingItemModel,
  index: number,
  landing: LandingModel,
  kind: LandingItemKindType,
  isEditMode?: boolean
};

const components = {
  [LANDING_ITEM_KIND.SUBSCRIPTION]: EditButtonSubscription,
  [LANDING_ITEM_KIND.PRODUCT]: EditButtonProduct,
  [LANDING_ITEM_KIND.BASIC]: EditBasic
};

const ItemComponent = (props: Props) => {
  const { kind, ...rest } = props;

  const Component = components[kind];

  return <Component {...rest} />;
};

ItemComponent.defaultProps = {
  isEditMode: false
};

export default ItemComponent;
