export const pushUp = (list, index) => [
  ...list.slice(0, index - 1),
  list[index],
  list[index - 1],
  ...list.slice(index + 1, list.length)
];

export const pushDown = (list, index) => [
  ...list.slice(0, index),
  list[index + 1],
  list[index],
  ...list.slice(index + 2, list.length)
];
