/* eslint-disable no-unsafe-finally */
import { message } from 'antd';
import {
  call,
  put,
  takeLatest,
  take,
  fork,
  cancel,
  cancelled,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { JOB } from 'types/jobs';
import api from 'store/api';
import apiUrls from 'config/api';

import user from './actions';

const normalizePaymentInfo = (response) => ({
  ...response,
  myPlan: response.pricings.find((p) => p.name === response.pricing),
});

function* getPaymentInfoOnce() {
  const { response, error } = yield call(api, apiUrls.paymentsInfo);
  if (response) {
    try {
      yield put(user.setPaymentInfo(normalizePaymentInfo(response)));
    } catch (e) {}
  }
}

function* apiCallTask() {
  try {
    while (true) {
      const { response, error } = yield call(api, apiUrls.paymentsInfo);
      if (response) {
        try {
          yield put(user.setPaymentInfo(normalizePaymentInfo(response)));
        } catch (e) {}
      }
      yield call(delay, 10 * 1000);
    }
  } finally {
    if (yield cancelled()) {
      return;
    }
  }
}

function* getPaymentInfoTask() {
  while (yield take(user.GET_PAYMENT_INFO)) {
    const bgTask = yield fork(apiCallTask);
    yield take(user.STOP_GET_PAYMENT_INFO);
    yield cancel(bgTask);
  }
}

function* getFetchStatus() {
  try {
    while (true) {
      const { response, error } = yield call(api, apiUrls.jobStatus, 'GET', {
        job_name: JOB.NAME.FETCH_GROUP_USERS,
      });
      if (response) {
        if (response.status === JOB.STATUS.FINISHED) {
          message.success('Обкачка аудитории успешно завершена');
          yield put(user.deliveryFetched());
        }
        if (response.status === JOB.STATUS.FAILED) {
          yield call(api, apiUrls.jobStart, 'POST', {
            job_name: JOB.NAME.FETCH_GROUP_USERS,
            params: {},
          });
        }
        if (response.status === JOB.STATUS.IN_PROGRESS) {
          yield put(user.fetchUsersSetProgress(response.progress));
        }
      }
      yield call(delay, 5 * 1000);
    }
  } finally {
    if (yield cancelled()) {
      return;
    }
  }
}

function* fetchDeliveryUsers() {
  const { response, error } = yield call(api, apiUrls.jobStart, 'POST', {
    job_name: JOB.NAME.FETCH_GROUP_USERS,
    params: {},
  });
  if (response) {
    const bgTask = yield fork(getFetchStatus);
    yield take(user.SET_DELIVERY_FETCHED);
    yield cancel(bgTask);
  }
}

export default [
  getPaymentInfoTask(),
  takeLatest(user.GET_PAYMENT_INFO_ONCE, getPaymentInfoOnce),
  takeLatest(user.FETCH_USERS, fetchDeliveryUsers),
];
