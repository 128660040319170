import styled from 'styled-components';
import { Button, InputNumber } from 'antd';

import ButtonLink from 'components/ButtonLink';
import { color, globalSpacingStyle, transition } from 'styles/constants';

export const Title = styled.span`
  color: ${color.primary};
`;

export const Container = styled.div`
  position: relative;
`;

export const ButtonWrapper = styled.div`
  opacity: 0;
  position: absolute;

  right: -35px;
  top: -35px;

  ${Container}:hover & {
    opacity: 1;
  }

  transition: ${transition('opacity')};

  .is-mobile & {
    right: 0;
  }
`;

export const ButtonWrapperImage = styled.div`
  opacity: 0;
  position: absolute;
  right: ${globalSpacingStyle.m};
  top: ${globalSpacingStyle.m};

  ${Container}:hover & {
    opacity: 1;
  }

  transition: ${transition('opacity')};
`;

export const AddElementBarWrapper = styled.div`
  position: relative;
  opacity: 0;
  transition: ${transition('opacity')};
  pointer-events: none;
  ${Container}:hover & {
    opacity: 1;
    pointer-events: auto;
  }
`;

export const ItemEditButton = styled(Button)`
  margin-left: ${globalSpacingStyle.s};
`;

export const Error = styled.div`
  //position: absolute;
  margin: ${globalSpacingStyle.s} 0;
  color: ${color.error};
`;

export const ButtonLinkWrapper = styled(ButtonLink)`
  display: block;
  position: relative;
  text-align: left;
  margin-top: ${globalSpacingStyle.m};
`;

export const NumberInput = styled(InputNumber)`
  width: 100%;
`;
