import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

import { VAR_TYPE, VARS, VARS_TITLES } from 'types/vars';

const varsItemsSelector = (state) => state.variables.items;
const specialVarsSelector = (state) =>
  state.variables.specials.map((v) => ({ ...v, special: true }));

export const varsListSelector = createSelector(
  [varsItemsSelector],
  (varsItems) => Object.values(varsItems)
);

export const allVarsListSelector = createSelector(
  [varsListSelector, specialVarsSelector],
  (varsList, specialVars) => [...varsList, ...specialVars]
);

export const varsListForMention = createSelector(
  [allVarsListSelector],
  (varsList) =>
    varsList
      .filter((item) => !item.hidden)
      .map(({ key }) => key.replace(/%/, ''))
);

export const globalVarsListSelector = createSelector(
  [varsListSelector],
  (varsList) => varsList.filter((v) => v.level === 'bot')
);

export const localVarsListSelector = createSelector(
  [varsListSelector],
  (varsList) => varsList.filter((v) => v.level === 'chat')
);

export const numVarsSelector = createSelector([varsListSelector], (varsList) =>
  varsList.filter((v) => v.type === VAR_TYPE.num)
);

export const numVarsOptionsSelector = createSelector(
  [numVarsSelector],
  (varsList) =>
    varsList.map(({ _id, key, type }) => ({
      value: key,
      label: key,
      type,
    }))
);

export const localVarsListNumberSelector = createSelector(
  [localVarsListSelector],
  (varsList) =>
    varsList.map(({ _id, key, type }) => ({
      value: key,
      label: key,
      type,
    }))
);

export const userVarsOptionsSelector = createSelector(
  [varsListSelector],
  (varsList) =>
    varsList.map(({ _id, key, type }) => ({
      value: key,
      label: key,
      type,
    }))
);

export const useUserVarsOptions = () => {
  return useSelector(userVarsOptionsSelector);
};

// Функция для получения ключей условий из переменных
export const useAllVarsOptions = (allowedVars = null) => {
  let specialVars = useSelector(specialVarsSelector);
  if (allowedVars) {
    specialVars = specialVars.filter((v) => allowedVars.includes(v.key));
  }

  const userVars = useSelector(varsListSelector);

  return [...specialVars, ...userVars].map((v) => ({
    special: v.special,
    label: (VARS_TITLES[v.key] && `${VARS_TITLES[v.key]} (${v.key})`) || v.key,
    value: v.key,
    type: v.type,
  }));
};
