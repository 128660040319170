export const local = '/local/';
export const stage = '/stage/';

export const api = (() => {
  let apiAfter = '/api/';
  if (SMARTBOT_ENV) {
    apiAfter = SMARTBOT_ENV === 'stage' ? stage : local;
  }

  return `${process.env.API_URL || ''}${apiAfter}`;
})();

export default {
  login: `${api}bot/login`,
  register: `${api}bot/register`,
  setAccessToken: `${api}bot/set_access_token`,
  commandsList: `${api}faq/list`,
  commandsSearch: `${api}faq/search_question`,
  commandsAdd: `${api}faq/add`,
  commandsEdit: `${api}faq/update`,
  commandsDelete: `${api}faq/delete`,
  getSettings: `${api}bot/get_settings`,
  setSettings: `${api}bot/set_settings`,
  enableUploadMode: `${api}/bot/enable_upload_mode`,
  getVars: `${api}vars/list`,
  getSpecialVars: `${api}vars/list_special_vars`,
  addVar: `${api}vars/add`,
  updateVar: `${api}vars/update`,
  deleteVar: `${api}vars/delete`,
  paymentsInfo: `${api}payments/get_info`,
  removeAutoPaymentMethod: `${api}payments/remove_auto_payment_method`,
  getPricings: `${api}payments/get_pricings`,
  getPrices: `${api}payments/get_prices`,
  setPricing: `${api}payments/set_pricing`,
  pay: `${api}payments/pay`,
  donate: `${api}payments/donate`,
  getScreenNotification: `${api}bot/get_screen_notification`,
  deliveryList: `${api}mailings/list`,
  deliveryGet: `${api}mailings/get`,
  deliveryAdd: `${api}mailings/plan`,
  deliveryNow: `${api}mailings/send`,
  deliveryUpdate: `${api}mailings/update`,
  deliveryDelete: `${api}mailings/delete`,
  deliveryCancel: `${api}mailings/cancel`,
  deliveryIsSubscribed: `${api}mailings/is_subscribed`,
  deliverySubscribe: `${api}mailings/subscribe`,
  deliveryUnsubscribe: `${api}mailings/unsubscribe`,
  deliveryGetCoverage: `${api}mailings/get_coverage`,
  deliveryDownloadRecipientsCsv: `${api}mailings/download_recipients_csv`,
  jobStatus: `${api}jobs/get_status`,
  jobStart: `${api}jobs/start`,
  jobCancel: `${api}jobs/cancel`,
  blocksList: `${api}blocks/list`,
  blocksAdd: `${api}blocks/add`,
  blocksUpdate: `${api}blocks/update`,
  blocksTestRun: `${api}blocks/test_run`,
  blocksGet: `${api}blocks/get`,
  blocksDelete: `${api}blocks/delete`,
  chainsList: `${api}chains/list`,
  chainsAdd: `${api}chains/add`,
  chainsUpdate: `${api}chains/update`,
  chainsGet: `${api}chains/get`,
  chainsSearch: `${api}chains/search`,
  chainsDelete: `${api}chains/delete`,
  reactionAccessibilityUpdate: `${api}chains/enable`,
  managersGet: `${api}bot/get_managers`,
  statsListEvents: `${api}stats/list_event_keys`,
  statsByDate: `${api}stats/stats_by_date`,
  statsDownloadUsers: `${api}stats/download_users_csv`,
  statsRemoveKey: `${api}stats/remove_key`,
  usersList: `${api}users/list`,
  usersListChats: `${api}users/list_chats`,
  usersSetVar: `${api}users/set_var`,
  usersDownloadCSV: `${api}users/download_csv`,
  usersUploadCSV: `${api}users/upload_csv`,
  usersAdd: `${api}users/add`,
  usersRemove: `${api}users/remove`,
  usersClear: `${api}users/clear`,
  importGetToken: `${api}/import/get_token`,
  canImport: `${api}/import/can_import`,
  importBot: `${api}/import/import_bot`,
  templatesList: `${api}/templates/list`,
  templatesUse: `${api}/templates/use`,
  attachmentsList: `${api}/vk_attachments/list`,
  attachmentsDelete: `${api}/vk_attachments/delete`,
  attachmentsUploadCarouselPhoto: `${api}/vk_attachments/upload_carousel_photo`,
  referralsList: `${api}/referrals/get_stats`,
  referralsAddLink: `${api}/referrals/add_link`,
  referralsRemoveLink: `${api}/referrals/remove_link`,
  referralsTransferBalance: `${api}/payments/transfer_referral_balance`,
  paymentMethodsList: `${api}/payment_methods/list_methods`,
  paymentMethodsSetSettings: `${api}/payment_methods/set_auto_payments_settings`,
  paymentMethodsRemove: `${api}/payment_methods/remove_method`,
  modulesList: `${api}/modules/list`,
  moduleGet: `${api}/modules/get_by_kind`,
  moduleUpsert: `${api}/modules/upsert`,
  moduleProlong: `${api}/modules/prolong`,
  listsGet: `${api}/lists/list`,
  listsCreate: `${api}/lists/add`,
  listsRename: `${api}/lists/rename`,
  listsDelete: `${api}/lists/delete`,
  blockTransitions: `${api}/chains/get_chain_transitions`,
  plannedEventsAdd: `${api}planned_events/add`,
  plannedEventsRemove: `${api}planned_events/remove`,
  plannedEventsList: `${api}planned_events/list_all`,
  googleAuth: `${api}gauth/auth`,
  googleAuthRevoke: `${api}gauth/revoke`,
  googleRedirect: `${api}gauth/redirect`,
  landingAdd: `${api}landings/add`,
  landingUpdate: `${api}landings/update`,
  landingDelete: `${api}/landings/delete`,
  landingList: `${api}landings/list`,
  landingGet: `${api}landings/get`,
  landingLoad: `${api}landings/load`,
  landingTransition: `${api}landings/transition`,
  filesUpload: `${api}files/upload`,
  filesGet: `${api}`,
  mailingsUnsubscribe: `${api}mailings/unsubscribe`,
  mailingsSubscribe: `${api}mailings/subscribe`,
  yamPay: `${api}yam/pay`,
  checkSQ: `${api}vars/check_sq_syntax`,
  renderSQ: `${api}vars/render_message`,
  domainConfirmationAdd: `${api}domain_confirmations/add`,
  domainConfirmationList: `${api}domain_confirmations/list`,
  domainConfirmationConfirm: `${api}domain_confirmations/confirm`,
  domainConfirmationDelete: `${api}domain_confirmations/delete`,
  widgetsPreview: `${api}widgets/preview`,
  getLimitsInfo: `${api}bot/get_limits_info`,
  getLimitsPrice: `${api}bot/get_limits_price`,
  setLimits: `${api}bot/set_limits`,
};
