/* eslint-disable */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import getter from 'utils/getter';
import connect, {
  AnyRequestMethodName,
  RequestPropsMap,
} from '@vkontakte/vk-bridge';
import getUserAccessToken from '../utils/getUserAccessToken';
import { message } from 'antd';
import {
  parseErrorLimitExceeded,
  parseErrorBadRequest,
  parseLimitMinUnderflow,
} from 'types/apiErrors/parsers';
import apiErrorsCodes from 'types/apiErrors/codes';
import { ReactNode } from 'react';

const API_VERSION = '1.0';

type CallApiResult<RD> =
  | RD
  | { status: string; data: RD }
  | AxiosResponse<{ status: string; data: RD }>;

async function callApi<RD = any>(
  url: string,
  methodType: AxiosRequestConfig['method'] = 'GET',
  config: AxiosRequestConfig = {}
): Promise<CallApiResult<RD>> {
  try {
    const result: AxiosResponse<{ status: string; data: RD }> = await axios({
      method: methodType,
      url,
      ...config,
    });

    if (result.headers['x-smartbot-token']) {
      window.smartbot_token = result.headers['x-smartbot-token'];
    }
    if (result.status !== 200 && getter(result, 'data', 'status') !== 'ok') {
      return Promise.reject(result);
    }
    if (getter(result, 'data', 'status') === 'error') {
      return Promise.reject(result);
    }

    return getter(result, 'data', 'data') || getter(result, 'data') || result;
  } catch (error) {
    return Promise.reject<any>(error);
  }
}

const parsersErrorByCode: {
  [key in apiErrorsCodes]?: ErrorConfigDefaultMessageFunc;
} = {
  [apiErrorsCodes.limitExceeded]: parseErrorLimitExceeded,
  [apiErrorsCodes.badRequest]: parseErrorBadRequest,
  [apiErrorsCodes.limitMinUnderflow]: parseLimitMinUnderflow,
};

type ErrorConfigDefaultMessageFunc = (
  errorData: object & { code?: string },
  code: string
) => ReactNode | string;

type ErrorConfig = {
  isShowError?: boolean;
  defaultMessage?: ReactNode | string | ErrorConfigDefaultMessageFunc;
};

async function apiProxy<RD = any, D extends object = any>(
  endpoint: string,
  methodType: AxiosRequestConfig['method'] = 'GET',
  data: D = null,
  config: AxiosRequestConfig & { errorConfig?: ErrorConfig } = {},
  multipartFormData: boolean = false
): Promise<{
  response?: RD;
  error?: any;
  errorData?: any;
}> {
  if (
    (config.data === null || config.data === undefined) &&
    methodType !== 'GET'
  ) {
    config.data = data;
  }

  if (!config.headers) {
    config.headers = {};
  }

  if (multipartFormData) {
    Object.assign(config.headers, { 'Content-Type': 'multipart/form-data' });

      const formData = new FormData();

      Object.entries(data).forEach(([key, value]) => {
        const keyFormData = key.toString();

        if (value) {
          if (Array.isArray(value)) {
            value.forEach((item) => {
              formData.append(keyFormData, item);
            });
          } else {
            formData.append(keyFormData, value);
          }
        }
      });

      config.data = formData;
  }

  if (window.smartbot_token) {
    Object.assign(config.headers, {
      'X-Smartbot-Token': window.smartbot_token,
    });
  }
  Object.assign(config.headers, {
    'X-Smartbot-Api-Version': API_VERSION,
  });

  if (methodType.toUpperCase() === 'GET') {
    config.params = {
      ...data,
      uid: window.userId,
    };
  } else {
    config.params = {
      uid: window.userId,
    };
  }

  try {
    const response = await callApi<RD>(endpoint, methodType, config) as RD;

    return {
      response,
    };
  } catch (catchError) {
    const error: AxiosError<any> = catchError;

    const errorData = getter(error, 'response', 'data', 'data') || {};

    const errorConfig = config?.errorConfig || {};
    const parserByCode: ErrorConfigDefaultMessageFunc =
      errorConfig?.isShowError !== false && parsersErrorByCode[errorData?.code];

    if (errorConfig?.isShowError || parserByCode) {
      message.error({
        key: errorData?.code,
        content:
          (parserByCode && parserByCode(errorData, errorData?.code)) ||
          (errorConfig?.defaultMessage &&
          typeof errorConfig?.defaultMessage === 'function'
            ? errorConfig?.defaultMessage(errorData, errorData?.code)
            : errorConfig?.defaultMessage) ||
          'Ошибка! Перезагрузите страницу или попробуйте позже',
      });
    }

    return {
      error,
      errorData,
    };
  }
}

type VkCallParams = Omit<
  RequestPropsMap['VKWebAppCallAPIMethod']['params'],
  'v' | 'access_token'
>;

async function vkCall(
  method: string,
  params: VkCallParams,
  access_token: string
) {
  return connect.send('VKWebAppCallAPIMethod', {
    method,
    params: {
      ...params,
      v: '5.103',
      access_token,
    },
  });
}

async function vkApiProxy(
  method: string,
  params: VkCallParams,
  access_token: string
) {
  try {
    const accessToken =
      access_token || (await getUserAccessToken(window.APP_ID));

    const result = await vkCall(method, params, accessToken);
    return { error: null, ...result };
  } catch (error) {
    return { response: null, error };
  }
}

async function vkClientApi(
  method: AnyRequestMethodName,
  params: RequestPropsMap['VKWebAppCallAPIMethod']
): Promise<boolean> {
  try {
    await connect.send(method, { ...params });
    return true;
  } catch (error) {
    return false;
  }
}

export { callApi, vkCall, vkApiProxy, vkClientApi };

export default apiProxy;
