import { Button as BaseButton } from 'antd';
import styled from 'styled-components';

import { globalSpacingStyle } from 'styles/constants';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  color: rgb(8, 49, 107);

  .is-mobile & {
    height: auto;
  }
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 100vh;
  border: none;
  outline: none;
`;

export const HeadWrapper = styled.div`
  text-align: right;
  background: white;
  padding: ${globalSpacingStyle.m} ${globalSpacingStyle.l};
  box-sizing: border-box;
  font-weight: bold;
  color: rgb(8, 49, 107);

  .is-mobile & {
    text-align: center;
  }
`;

export const Button = styled(BaseButton)`
  margin-left: ${globalSpacingStyle.m};

  .is-mobile & {
    display: block;
    margin: ${globalSpacingStyle.s} auto;
  }
`;

export const Avatar = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 ${globalSpacingStyle.m};
  background: url(${props => props.bg}) no-repeat center;
  background-size: contain;

  .is-mobile & {
    margin-left: 0;
  }
`;

export const Referrer = styled.div`
  text-align: center;
  font-size: ${globalSpacingStyle.l};
  padding-bottom: ${globalSpacingStyle.m};
`;

export const ReferrerInfo = styled.div`
  font-size: 14px;
`;
