import type * as T from '../UserStore.types';

function normalizeGroupInfo(groupInfo: T.GroupInfoApi): T.GroupInfo {
  const {
    id,
    is_closed,
    members_count,
    name,
    photo_50,
    photo_100,
    photo_200,
    screen_name,
    type,
  } = groupInfo;

  return {
    id,
    name,
    type,
    isClosed: is_closed,
    screenName: screen_name,
    membersCount: members_count,
    photo50: photo_50,
    photo100: photo_100,
    photo200: photo_200,
  };
}

export default normalizeGroupInfo;
