export const REACTION = {
  DEFAULT_ANSWER: 'default_answer',
  GROUP_LEAVE: 'group_leave',
  SWEAR_WORDS: 'swear_words',
  PAYMENT: 'payment',
  FIRST_MESSAGE: 'first_message',
  REPOST: 'wall_repost',
  LIKE_ADD: 'like_add',
  LIKE_REMOVE: 'like_remove',
  GROUP_JOIN: 'group_join',
  PRE_FILTER: 'pre_filter',
  ADMIN_REPLY: 'admin_reply',
  MARKET_ORDER_NEW: 'market_order_new',
  MARKET_ORDER_EDIT: 'market_order_edit',
  PHOTO_ATTACH: 'photo_attach',
  VIDEO_ATTACH: 'video_attach',
  AUDIO_ATTACH: 'audio_attach',
  STICKER_ATTACH: 'sticker_attach',
  DOC_ATTACH: 'doc_attach',
  MARKET_ATTACH: 'market_attach',
  LEAD_FORMS_NEW: 'lead_forms_new',
};

export const REACTION_MODE = {
  SIMPLE: 'simple',
  COMPLEX: 'complex',
};

export type ChainModeType = $Values<typeof REACTION_MODE>;

export const REACTIONS_LIST = Object.values(REACTION);

export const HIDDEN_REACTIONS = [
  REACTION.MARKET_ORDER_NEW,
  REACTION.MARKET_ORDER_EDIT,
];

export const FREE_REACTIONS = [
  REACTION.DEFAULT_ANSWER,
  REACTION.GROUP_LEAVE,
  REACTION.SWEAR_WORDS,
];

export const REACTIONS_WITH_REALMS = [
  REACTION.FIRST_MESSAGE,
  REACTION.DEFAULT_ANSWER,
  REACTION.SWEAR_WORDS,
  REACTION.PHOTO_ATTACH,
  REACTION.VIDEO_ATTACH,
  REACTION.AUDIO_ATTACH,
  REACTION.STICKER_ATTACH,
  REACTION.DOC_ATTACH,
  REACTION.MARKET_ATTACH,
  REACTION.PRE_FILTER,
];

export const ONLY_VARS_REACTIONS = [
  REACTION.GROUP_JOIN,
  REACTION.GROUP_LEAVE,
  REACTION.PAYMENT,
  REACTION.STICKER_ATTACH,
  REACTION.LIKE_ADD,
  REACTION.LIKE_REMOVE,
  REACTION.REPOST,
  REACTION.MARKET_ORDER_NEW,
  REACTION.MARKET_ORDER_EDIT,
  REACTION.LEAD_FORMS_NEW,
];

export type REACTION_TYPES =
  | REACTION.FIRST_MESSAGE
  | REACTION.GROUP_JOIN
  | REACTION.GROUP_LEAVE
  | REACTION.DEFAULT_ANSWER
  | REACTION.SWEAR_WORDS
  | REACTION.PHOTO_ATTACH
  | REACTION.VIDEO_ATTACH
  | REACTION.AUDIO_ATTACH
  | REACTION.STICKER_ATTACH
  | REACTION.DOC_ATTACH
  | REACTION.MARKET_ATTACH
  | REACTION.PAYMENT
  | REACTION.PRE_FILTER
  | REACTION.REPOST
  | REACTION.LIKE_ADD
  | REACTION.LIKE_REMOVE
  | REACTION.MARKET_ORDER_NEW
  | REACTION.MARKET_ORDER_EDIT;

export const REACTION_TITLE = {
  [REACTION.FIRST_MESSAGE]: 'Реакция на первое сообщение',
  [REACTION.GROUP_JOIN]: 'Реакция на вступление в группу',
  [REACTION.GROUP_LEAVE]: 'Реакция на выход из группы',
  [REACTION.DEFAULT_ANSWER]: 'Ответ по умолчанию',
  [REACTION.STICKER_ATTACH]: 'Реакция на репост',
  [REACTION.SWEAR_WORDS]: 'Реакция на мат',
  [REACTION.PHOTO_ATTACH]: 'Реакция на фотографию',
  [REACTION.VIDEO_ATTACH]: 'Реакция на видео',
  [REACTION.AUDIO_ATTACH]: 'Реакция на аудио',
  [REACTION.STICKER_ATTACH]: 'Реакция на стикер',
  [REACTION.DOC_ATTACH]: 'Реакция на документ',
  [REACTION.MARKET_ATTACH]: 'Реакция на товар',
  [REACTION.PAYMENT]: 'Реакция на оплату',
  [REACTION.PRE_FILTER]: 'Фильтр предобработки',
  [REACTION.ADMIN_REPLY]: 'Реакция на ответ админа',
  [REACTION.REPOST]: 'Реакция на репост',
  [REACTION.LIKE_ADD]: 'Реакция на лайк',
  [REACTION.LIKE_REMOVE]: 'Реакция на удаление лайка',
  [REACTION.MARKET_ORDER_NEW]: 'Реакция на заказ в товарах ВК',
  [REACTION.MARKET_ORDER_EDIT]: 'Реакция на изменение заказа в товарах ВК',
  [REACTION.LEAD_FORMS_NEW]: 'Реакция на «Формы заявок»',
};

export const REACTION_ICON = {
  [REACTION.FIRST_MESSAGE]: 'message',
  [REACTION.GROUP_JOIN]: 'user-add',
  [REACTION.GROUP_LEAVE]: 'user-delete',
  [REACTION.DEFAULT_ANSWER]: 'question',
  [REACTION.SWEAR_WORDS]: 'alert',
  [REACTION.PHOTO_ATTACH]: 'picture',
  [REACTION.VIDEO_ATTACH]: 'video-camera',
  [REACTION.AUDIO_ATTACH]: 'sound',
  [REACTION.STICKER_ATTACH]: 'smile',
  [REACTION.DOC_ATTACH]: 'file-text',
  [REACTION.MARKET_ATTACH]: 'shopping-cart',
  [REACTION.PAYMENT]: 'credit-card',
  [REACTION.PRE_FILTER]: 'filter',
  [REACTION.ADMIN_REPLY]: 'team',
  [REACTION.REPOST]: 'share-alt',
  [REACTION.LIKE_ADD]: 'like',
  [REACTION.LIKE_REMOVE]: 'dislike',
  [REACTION.MARKET_ORDER_NEW]: 'shop',
  [REACTION.MARKET_ORDER_EDIT]: 'shop',
  [REACTION.LEAD_FORMS_NEW]: 'form',
};

export const NEW_REACTIONS = [REACTION.LEAD_FORMS_NEW];
