import { action, observable, toJS } from 'mobx';

class OpenLinkParams {
  @observable url: string;

  constructor(params: { url: string } = { url: '' }) {
    this.url = params.url;
  }

  @action.bound
  changeUrl(value: string): void {
    this.url = value;
  }

  validate = (): string[] => {
    const errors = [];

    if (this.url.length === 0) {
      errors.push('Не заполнено поле ссылки');
    }

    return errors;
  };

  toJson = () => {
    return toJS({
      url: this.url,
    });
  };
}

export default OpenLinkParams;
