// @flow
import React from 'react';
import { observer } from 'mobx-react';
import { Divider } from 'antd';

import type { WithStoreProps } from 'models/RootStore';
import { withStore } from 'models/RootStore';
import ModalNewBlock from 'components/Chain/ModalNewBlock';
import Transition from 'components/Chain/Transition';

import { Title, ButtonLinkWrapper } from '../styles';

type Props = WithStoreProps & {
  title: string,
  value: string,
  onChange: (value: string) => void
};

@withStore
@observer
class EditSelector extends React.Component<Props> {
  state = {
    showEditModal: false
  };

  onSelect = blockId => {
    // undefined -> null
    this.props.onChange(blockId || null);
  };

  toggleEditModal = () =>
    this.setState(prevState => ({
      showEditModal: !prevState.showEditModal
    }));

  render() {
    const { title, value } = this.props;
    const { showEditModal } = this.state;

    return (
      <div>
        <Divider>
          <Title>{title}</Title>
        </Divider>
        <Transition
          onSelect={this.onSelect}
          hideBlockLink
          onlyBlocks
          blockId={value}
          showCurrent={false}
        />
        {value && (
          <ButtonLinkWrapper onClick={this.toggleEditModal}>
            Редактировать блок
          </ButtonLinkWrapper>
        )}
        <ModalNewBlock
          isOpened={showEditModal}
          onClickCancel={this.toggleEditModal}
          blockId={value}
        />
      </div>
    );
  }
}

export default EditSelector;
