enum HTTP_REQUEST_METHOD {
  GET = 'GET',
  POST = 'POST',
  HEAD = 'HEAD',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

enum HTTP_REQUEST_BODY_MODE {
  JSON = 'json',
  FORM = 'form',
  RAW = 'raw',
  NONE = 'none',
}

const HttpRequestMethodOptions = Object.values(HTTP_REQUEST_METHOD).map(
  (v) => ({
    value: v,
    label: v,
  })
);

const HttpRequestBodyModeOptions = [
  {
    value: HTTP_REQUEST_BODY_MODE.FORM,
    label: 'Форма',
  },
  {
    value: HTTP_REQUEST_BODY_MODE.JSON,
    label: 'JSON',
  },
  {
    value: HTTP_REQUEST_BODY_MODE.RAW,
    label: 'Текст',
  },
  {
    value: HTTP_REQUEST_BODY_MODE.NONE,
    label: 'Без тела',
  },
];

export {
  HTTP_REQUEST_METHOD,
  HTTP_REQUEST_BODY_MODE,
  HttpRequestMethodOptions,
  HttpRequestBodyModeOptions,
};
