import update from 'immutability-helper';
import commands from '../actions';
import type { SingleConditionType } from 'types';
import { createDefaultCondition } from 'types';

update.extend('$auto', function(value, object) {
  return object ? update(object, value) : update({}, value);
});

export const initialState = {};

const actionsMap = {
  [commands.SAVE_COMMAND_ENTITIES]: (state, { commands }) => {
    return update(state, {
      $merge: commands
    });
  },

  [commands.DELETE_COMMAND]: (state, { _id }) => {
    if (state[_id]) {
      return update(state, {
        [_id]: {
          isDeleting: { $set: true }
        }
      });
    }
    return state;
  },

  [commands.DELETE_COMMAND_ERROR]: (state, { _id }) => {
    if (state[_id]) {
      return update(state, {
        [_id]: {
          isDeleting: { $set: false }
        }
      });
    }
    return state;
  },

  [commands.DROP_COMMAND]: (state, { _id }) => {
    if (state[_id]) {
      return update(state, {
        $apply: commands => {
          delete commands[_id];
          return commands;
        }
      });
    }
    return state;
  },

  // [commands.GET_CHAIN]: (state, { payload: _id }) =>
  //   update(state, {
  //     [_id]: {
  //       $auto: {
  //         $set: {
  //           isLoading: true
  //         }
  //       }
  //     }
  //   }),
};

export default (state = initialState, action = {}) => {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
};
