import React from 'react';
import memoizeOne from 'memoize-one';

import { STEP_TITLE } from 'types';
import BlockModel from 'models/Block';
import StepsGroupModel from 'models/Block/StepsGroupModel';
import StepModel from 'models/Step/StepModel';
import {
  BlockPreviewModel,
  StepPreviewModel,
  StepsGroupPreviewModel
} from 'models/Block/BlockPreviewModel';

import StepTransitionPreview from './StepTransitionPreview';
import { CreateBlockLabel } from './styles';

const createTreeOption = (value, label, { name, isCurrent = false } = {}) => ({
  value,
  label: isCurrent ? <b>{label}</b> : label,
  key: value,
  name: name || label
});

export const CREATE_BLOCK_ACTION = 'create';

export const CURRENT_BLOCK_NAME = 'Текущий блок';

export const createBlockOption = {
  value: CREATE_BLOCK_ACTION,
  key: CREATE_BLOCK_ACTION,
  name: '+ Новый блок',
  label: <CreateBlockLabel>+ Новый блок</CreateBlockLabel>
};

export const getDynamicSelectorOptions = (
  options,
  currentBlock,
  onlyBlocks,
  showCurrent
) => {
  const [newCurrentBlockOption] = getSelectTree([currentBlock], {
    onlyBlocks,
    currentBlock,
    showCurrent
  });
  return options.map(option => {
    return option.value === newCurrentBlockOption.value
      ? newCurrentBlockOption
      : option;
  });
};

export const getSelectorOptions = ({
  blocksList,
  currentBlock,
  onlyBlocks,
  showCurrent,
  canCreate
}) => {
  const blocksForOptions = blocksList.filter(b => b.id !== currentBlock?.id);

  if (currentBlock) {
    blocksForOptions.unshift(currentBlock);
  }

  const options = getSelectTree(blocksForOptions, {
    onlyBlocks,
    currentBlock,
    showCurrent
  });

  if (canCreate) {
    options.unshift(createBlockOption);
  }

  return options;
};

export const memoizedSelectorOptions = memoizeOne(getSelectorOptions);

export const getSelectTree = (
  blocks: Array<BlockModel>,
  { onlyBlocks = false, currentBlock = null, showCurrent = false }
) => {
  const options = blocks.map((block: BlockModel | BlockPreviewModel) => {
    const blockOption = createTreeOption(
      block.id,
      currentBlock?.id === block.id && showCurrent
        ? CURRENT_BLOCK_NAME
        : block.name,
      {
        isCurrent: currentBlock?.id === block.id
      }
    );

    if (!onlyBlocks) {
      blockOption.children = block.stepsGroups.map(
        (sg: StepsGroupModel | StepsGroupPreviewModel, index) => {
          const sgOption = createTreeOption(
            sg.id,
            sg.name || `Группа шагов ${index + 1}`
          );

          sgOption.children = sg.steps.map(
            (s: StepModel | StepPreviewModel, i) =>
              createTreeOption(
                s._id,
                <StepTransitionPreview
                  index={i}
                  kind={s.kind}
                  preview={s.preview}
                />,
                {
                  name: STEP_TITLE[s.kind]
                }
              )
          );
          return sgOption;
        }
      );
    }

    return blockOption;
  });

  if (currentBlock) {
    const currentOptionsIndex = options.findIndex(
      opt => opt.value === currentBlock.id
    );

    if (currentOptionsIndex > -1) {
      const currentOptions = options.splice(currentOptionsIndex, 1);
      if (currentOptions.length > 0) {
        options.unshift(currentOptions[0]);
      }
      return options;
    }
  }
  return options;
};

export const filter = (inputValue, path) =>
  path.some(
    option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  );

export const sortFiltered = (a, b, inputValue, names) => {
  const callback = elem => elem.name.indexOf(inputValue) > -1;

  return a.findIndex(callback) - b.findIndex(callback);
};

const highlightKeyword = (str, keyword, prefixCls) => {
  return str.split(keyword).map((node, index) =>
    index === 0
      ? node
      : [
          <span key="separator" className={`${prefixCls}-menu-item-keyword`}>
            {keyword}
          </span>,
          node
        ]
  );
};

export const renderFiltered = (inputValue, path, prefixCls) => {
  return path.map((option, index) => {
    const label = option.name.toLowerCase();
    const keyword = inputValue.toLowerCase();
    const node =
      label.indexOf(keyword) > -1
        ? highlightKeyword(label, keyword, prefixCls)
        : label;
    return index === 0 ? node : [' / ', node];
  });
};
