import update from 'immutability-helper';
import type { JobStatus, JobType } from 'types/jobs';

import type {
  JobFinishAction,
  JobSetInfoAction,
  JobStartAction,
  JobStartErrorAction
} from './actions';
import actions from './actions';

type JobInfoType = {
  status: JobStatus,
  progress: number,
  isStarting: boolean,
  startError: boolean
};

type JobStateType = {
  [key: JobType]: JobInfoType
};

export const initialState: JobStateType = {};

const actionsMap = {
  [actions.JOB_START]: (state, action: JobStartAction) =>
    update(state, {
      [action.payload.jobName]: {
        $auto: {
          isStarting: { $set: true },
          startError: { $set: false }
        }
      }
    }),
  [actions.JOB_START_ERROR]: (state, action: JobStartErrorAction) =>
    update(state, {
      [action.payload.jobName]: {
        $auto: {
          isStarting: { $set: false },
          startError: { $set: true }
        }
      }
    }),
  [actions.JOB_FINISHED]: (state, action: JobFinishAction) =>
    update(state, {
      [action.payload.jobName]: {
        $auto: {
          isStarting: { $set: false },
          status: { $set: action.payload.status },
          progress: { $set: 0 }
        }
      }
    }),
  [actions.JOB_SET_INFO]: (state, action: JobSetInfoAction) =>
    update(state, {
      [action.payload.jobName]: {
        $auto: {
          isStarting: { $set: false },
          status: { $set: action.payload.status },
          progress: { $set: action.payload.progress }
        }
      }
    })
};

export default (state = initialState, action = {}) => {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
};
