import connect from '@vkontakte/vk-bridge';

export default async function getUserAccessToken(appId, scope = []) {
  try {
    const { access_token } = await connect.send('VKWebAppGetAuthToken', {
      app_id: Number(appId),
      scope: scope.join(',')
    });
    return access_token;
  } catch (error) {
    return null;
  }
}
