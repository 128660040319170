import connect from '@vkontakte/vk-bridge';

import { BASIC_SCOPE, constructScope } from '../config/permissions';

type Options = {
  groupId?: number;
  appId?: number;
  scope?: string[];
};

const sleep = (t) => new Promise((resolve) => setTimeout(resolve, t));

const initVk = async (
  options: Options = {},
  callbackErrorAllowMessages: () => void
): Promise<boolean> => {
  const {
    groupId = Number(window.GROUP_ID),
    appId = Number(window.APP_ID),
    scope = BASIC_SCOPE,
  } = options;

  try {
    const timeoutAllowMessages = setTimeout(callbackErrorAllowMessages, 5000);
    await connect.send('VKWebAppAllowMessagesFromGroup', {
      group_id: groupId,
      key: 'dBuBKe1kFcdemzBs',
    });
    clearTimeout(timeoutAllowMessages);
    const result = await connect.send('VKWebAppGetCommunityToken', {
      app_id: appId,
      group_id: groupId,
      scope: constructScope(scope),
    });
    window.ACCESS_TOKEN = result.access_token;
    if (window.ACCESS_TOKEN.includes('0000000000000000')) {
      // Фикс бага вк
      await sleep(1500);
      return initVk(options, callbackErrorAllowMessages);
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default initVk;
