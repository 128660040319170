import { arrayMove } from 'react-sortable-hoc';
import { action, computed, observable, toJS } from 'mobx';

import { ATTACHMENT_TYPE } from 'types/attachments';
import generateId from 'utils/generateId';
import { KeyboardButtonModel } from 'models/common/KeyboardModel';

import CarouselElementActionModel, {
  CarouselElementActionModelData,
  CarouselElementActionModelType,
} from './CarouselElementActionModel';

const MAX_COUNT_BUTTONS = 3;

type CarouselElementModelData = {
  title: string;
  description: string;
  action: CarouselElementActionModelData;
  photo_id?: CarouselElementPhoto;
  buttons?: any[];
};

type CarouselElementPhoto = {
  id: number;
  owner_id: number;
  type: ATTACHMENT_TYPE;
  title: string;
  is_link: boolean;
  access_key: string;
  preview: string;
  duration: number;
};

class CarouselElementModel {
  id = generateId();

  action = new CarouselElementActionModel();

  @observable errors = [];

  @observable buttons: KeyboardButtonModel[] = [];

  @observable title = '';

  @observable description = '';

  @observable photo: CarouselElementPhoto = null;

  @computed
  get enableAddButtons(): boolean {
    return this.buttons.length < MAX_COUNT_BUTTONS;
  }

  constructor(data?: CarouselElementModelData) {
    if (data) {
      this.action = new CarouselElementActionModel(data.action);
      this.title = data.title;
      this.description = data.description;
      this.photo = data.photo_id;
      this.buttons =
        data.buttons?.map((button) => KeyboardButtonModel.fromJson(button)) ||
        [];
    }
  }

  @action.bound
  addButton(button: KeyboardButtonModel): void {
    this.buttons.push(button);
  }

  @action.bound
  removeButton(pos: number): void {
    this.buttons.splice(pos, 1);
  }

  @action.bound
  moveButton(oldPosition: number, newPosition: number): void {
    this.buttons = arrayMove(this.buttons, oldPosition, newPosition);
  }

  @action.bound
  changePhoto(value: CarouselElementPhoto): void {
    this.photo = value;
  }

  @action.bound
  changeTitle(value: string): void {
    this.title = value;
  }

  @action.bound
  changeDescription(value: string): void {
    this.description = value;
  }

  @action.bound
  validate(actionType: CarouselElementActionModelType): boolean {
    this.errors = [];

    if (this.title.length === 0) {
      this.errors.push('Заголовок не может быть пустым.');
    }

    if (this.description.length === 0) {
      this.errors.push('Описание не может быть пустым.');
    }

    if (!this.photo) {
      this.errors.push('Не установлена фотография.');
    }

    if (!this.action.validate(actionType)) {
      this.errors.push('Ошибка в действии элемента.');
    }

    if (this.buttons.length === 0) {
      this.errors.push('Должна быть минимум одна кнопка.');
    }

    this.buttons.forEach((button) => {
      const validateButton = button.validate();

      if (typeof validateButton === 'string') {
        this.errors.push(validateButton);
      }
    });

    return this.errors.length === 0;
  }

  toJson = (actionType: CarouselElementActionModelType) => {
    return toJS({
      title: this.title,
      description: this.description,
      photo_id: this.photo,
      buttons: this.buttons.map((button) => button.toJson()),
      action: this.action.toJson(actionType),
    });
  };
}

export { CarouselElementModelData };

export default CarouselElementModel;
