import '@babel/polyfill';
import 'blob-polyfill';
import 'formdata-polyfill';
import './loadingStyles.scss';
import './site/layout.scss';
import * as Sentry from '@sentry/browser';
// window.Raven = Raven;
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  Route,
  Router as CommonRouter,
  MemoryRouter,
  Switch,
} from 'react-router-dom';
import connect from '@vkontakte/vk-bridge';

import history from 'utils/history';
import { checkMobile } from 'utils/markup';
import isIOS from 'utils/isIOS';
import rootStore, { RootStoreContext } from 'models/RootStore';
import create from 'store/createStore';
import findGetParameter from 'utils/findGetParameter';

import packageJson from '../package.json';

import inIframe from './utils/isInIframe';
import GoogleRedirect from './site/components/GoogleRedirect/GoogleRedirect';
import NewSplashScreen from './site/NewSplashScreen';
import { parseHash } from './types/hashParams';

// if (process.env.NODE_ENV !== 'production') {
//   const { whyDidYouUpdate } = require('why-did-you-update');
//   whyDidYouUpdate(React);
// }
window.PLATFORM = findGetParameter('vk_platform') || 'desktop_web';
window.IS_IFRAME = inIframe() || window.PLATFORM !== 'desktop_web';

window.IS_PRODUCTION = process.env.NODE_ENV === 'production';

if (window.IS_IFRAME) {
  connect.send('VKWebAppInit', {});

  if (connect.supports('VKWebAppResizeWindow')) {
    connect.send('VKWebAppResizeWindow', { width: 1000, height: 580 });
  }
}

const getLoginParams = () => window.location.search.replace('mob=true&', '');

const setParams = async () => {
  const devIds = [
    // these ids will be able to open admin panel in any group
    '-1', // SA
    '11643247', // @alekseyl
    '13062691', // @chernobrovkin_sergey
    '61227517', // @chupismi
    '120279256', // @fedorbilichenko
    '292380475', // @combitoo
    '212724331', // @i.schepovskikh
    '13071645', // @aredancing
    '354117', // @ramana
    '131636396', // @artbakulev
    '240326252', // @over_now20
    '29934617', // @sirArtemis91
  ];

  window.USER_ID = findGetParameter('vk_user_id');
  window.IS_DEV = devIds.includes(window.USER_ID);
  window.IS_ADMIN = findGetParameter('vk_viewer_group_role') === 'admin';
  window.GROUP_ID = findGetParameter('vk_group_id');
  window.APP_ID = findGetParameter('vk_app_id');
  window.LOGIN_URL = window.location.href;
  window.IS_VK_MOB = Boolean(findGetParameter('mob')) || false;
  window.LOGIN_PARAMS = getLoginParams();
  window.isMobile = checkMobile();
  window.isIOS = isIOS(window.PLATFORM);
  window.SIGN = findGetParameter('sign');
  window.VIEWER_GROUP_ROLE = findGetParameter('vk_viewer_group_role');
  window.is_odr = findGetParameter('odr_enabled') === '1';
  window.HASH_PARAMS = parseHash();

  if (window.IS_PRODUCTION) {
    Sentry.configureScope((scope) => {
      scope.setTag('user_id', window.USER_ID);
      scope.setTag('group_id', window.GROUP_ID);
    });
  }
};

const startApp = async () => {
  await setParams();
  const store = create(window.IS_PRODUCTION);

  if (window.IS_PRODUCTION) {
    Sentry.init({
      dsn: 'https://a45c48303ef64d62990e0fcd47019984@sentry.pulse.kts.studio/34',
      release: packageJson.version,
    });
  }
  const loader = document.getElementById('preload');
  loader.parentNode.removeChild(loader);

  const Router = window.is_odr ? MemoryRouter : CommonRouter;

  const View = (
    <Provider store={store}>
      <Router history={history}>
        <RootStoreContext.Provider value={rootStore}>
          <Switch>
            <Route path="/google_auth_complete/" component={GoogleRedirect} />
            <Route path="/" component={NewSplashScreen} />
          </Switch>
        </RootStoreContext.Provider>
      </Router>
    </Provider>
  );

  if (window.isMobile) {
    document.body.classList.add('is-mobile');
  }

  if (window.isIOS) {
    document.body.classList.add('iOS');
  }

  ReactDOM.render(View, document.getElementById('app'));
};

window.onload = startApp;
