// @flow
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { message } from 'antd';

import { vkClientApi } from 'store/api';
import generateId from 'utils/generateId';
import apiUrls from 'config/api';
import replaceTextToLink from 'utils/replaceTextToLink';
import BasicItemModel from 'models/Landing/Elements/BasicItemModel';
import { openChatWithDelay } from 'components/landing/utils';

import {
  Title,
  Description,
  ButtonWrapper,
  Wrapper,
  Image,
  Container
} from '../styles';

type Props = {
  item: BasicItemModel,
  isEditMode?: boolean
};

const BasicElement = (props: Props) => {
  const {
    item: { imageUrl, title, description },
    item,
    isEditMode
  } = props;

  const onClick = useCallback(async () => {
    if (window.IS_IFRAME) {
      const success = await vkClientApi('VKWebAppAllowMessagesFromGroup', {
        group_id: Number(window.GROUP_ID),
        key: generateId()
      });

      if (!success) {
        return;
      }
    }

    const result = await item.transition();
    if (result) {
      message.success('Сценарий запущен');
    }

    if (item.getButton().openChat) {
      openChatWithDelay();
    }
  }, [item]);

  // если кнопка Подписаться одна, то поставить ее по центру
  const isSingleButton = !title && !description && !imageUrl;

  return (
    <Container>
      {imageUrl && (
        <Image src={`${apiUrls.filesGet}${imageUrl}`} alt="Изображение" />
      )}
      {!isSingleButton && (
        <Wrapper>
          <Title>{replaceTextToLink(title)}</Title>
          <Description>{replaceTextToLink(description)}</Description>
        </Wrapper>
      )}
      <ButtonWrapper type="primary" onClick={isEditMode ? () => {} : onClick}>
        {item.getButton().text}
      </ButtonWrapper>
    </Container>
  );
};

BasicElement.defaultProps = {
  isEditMode: false
};

export default observer(BasicElement);
