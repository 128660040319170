import { observable, action, computed, toJS } from 'mobx';

import { TransitionParamsModel } from './TransitionParams';

export const PARSE_STEP_FILTER = {
  NUMBER: 'number',
  WORD: 'word',
  STRING: 'string',
  PHONE: 'phone',
  EMAIL: 'email',
  REGEX: 'regex',
  MENTION: 'mention'
};

export const DECIMAL_SEPARATOR = {
  DOT: 'dot',
  COMMA: 'comma',
  BOTH: 'both'
};

export const PARSE_STEP_FILTER_NAME = {
  [PARSE_STEP_FILTER.NUMBER]: 'Число',
  [PARSE_STEP_FILTER.WORD]: 'Слово',
  [PARSE_STEP_FILTER.STRING]: 'Строка',
  [PARSE_STEP_FILTER.PHONE]: 'Телефон',
  [PARSE_STEP_FILTER.EMAIL]: 'Email',
  [PARSE_STEP_FILTER.REGEX]: 'Регулярное выражение',
  [PARSE_STEP_FILTER.MENTION]: 'Упоминание (@mention)'
};

export const PARSE_STEP_DEFAULT_GROUP = '<текст>';
export const PARSE_STEP_DEFAULT_GROUP_OLD = '<сообщение>';

export const PARSE_STEP_FILTER_OPTIONS = Object.values(PARSE_STEP_FILTER).map(
  f => ({
    value: f,
    label: PARSE_STEP_FILTER_NAME[f]
  })
);

class ParseStepGroupFilterModel {
  @observable kind: $Values<typeof PARSE_STEP_FILTER> =
    PARSE_STEP_FILTER.NUMBER;

  @observable integer: boolean = false;

  @observable decimal_sep: $Values<typeof DECIMAL_SEPARATOR> =
    DECIMAL_SEPARATOR.BOTH;

  @observable min: number = null;

  @observable max: number = null;

  @observable regex: string = '';

  constructor(
    kind = PARSE_STEP_FILTER.NUMBER,
    decimal_sep = DECIMAL_SEPARATOR.BOTH,
    integer = false,
    min = null,
    max = null,
    regex = ''
  ) {
    this.kind = kind;
    this.decimal_sep = decimal_sep;
    this.integer = integer;
    this.min = min;
    this.max = max;
    this.regex = regex;
  }

  @computed
  get needStrMinMax() {
    return (
      this.kind === PARSE_STEP_FILTER.STRING ||
      this.kind === PARSE_STEP_FILTER.WORD
    );
  }

  @action.bound
  setKind(kind) {
    this.kind = kind;
  }

  @action.bound
  setInteger() {
    this.integer = !this.integer;
  }

  @action.bound
  setDecimalSep(value) {
    this.decimal_sep = value;
  }

  @action.bound
  setMin(min) {
    this.min = min;
  }

  @action.bound
  setMax(max) {
    this.max = max;
  }

  @action.bound
  setRegex(regex) {
    this.regex = regex;
  }

  toJson() {
    return toJS({
      kind: this.kind,
      integer: this.integer,
      decimal_sep: this.decimal_sep,
      min: this.min,
      max: this.max,
      regex: this.regex
    });
  }

  static createDefault(): ParseStepGroupFilterModel {
    return new ParseStepGroupFilterModel(
      PARSE_STEP_FILTER.NUMBER,
      DECIMAL_SEPARATOR.BOTH,
      false,
      null,
      null,
      ''
    );
  }

  static fromJson(data): ParseStepGroupFilterModel {
    return new ParseStepGroupFilterModel(
      data.kind,
      data.decimal_sep || DECIMAL_SEPARATOR.DOT, // legacy compatible default
      data.integer,
      data.min,
      data.max,
      data.regex
    );
  }
}

export class ParseStepGroupModel {
  @observable target: string = null;

  @observable filter: ParseStepGroupFilterModel = null;

  constructor(target, filter) {
    this.target = target;
    this.filter = filter;
  }

  @action.bound
  setTarget(target) {
    this.target = target;
  }

  validate = groupName => {
    const errors = [];

    if (!this.target) {
      errors.push(`Не выбрана переменная для группы ${groupName}`);
    }

    if (this.filter.kind === PARSE_STEP_FILTER.REGEX) {
      try {
        // eslint-disable-next-line no-new
        new RegExp(this.filter.regex);
      } catch (e) {
        errors.push(`Ошибка в регулярном выражении для группы ${groupName}`);
      }
    }

    return errors;
  };

  toJson() {
    return toJS({
      target: this.target,
      filter: this.filter.toJson()
    });
  }

  static createDefault(): ParseStepGroupModel {
    return new ParseStepGroupModel(
      null,
      ParseStepGroupFilterModel.createDefault()
    );
  }

  static fromJson(data): ParseStepGroupModel {
    return new ParseStepGroupModel(
      data.target,
      ParseStepGroupFilterModel.fromJson(data.filter)
    );
  }
}

export default class ParseParamsModel {
  @observable source: '%сообщение%' | string = '';

  @observable expr: string = '';

  @observable groups: {
    [key: string]: ParseStepGroupModel
  } = {};

  @observable
  errorTransition: TransitionParamsModel = new TransitionParamsModel();

  @observable
  errorTransitionEnabled: boolean = false;

  constructor(source, expr, groups, errorTransition, errorTransitionEnabled) {
    this.source = source;
    this.expr = expr;
    this.groups = groups;
    this.errorTransition = errorTransition;
    this.errorTransitionEnabled = errorTransitionEnabled;
  }

  @computed
  get groupsNames() {
    return Object.keys(this.groups);
  }

  @action
  setSource = source => {
    this.source = source;
  };

  @action
  setExpr = expr => {
    this.expr = expr;

    const groupsInPattern = expr.match(/<[a-z_а-я][a-z0-9_а-я]*>/gim) || [];

    // Удаляем текущие группы не из паттерна
    const existingGroupsNotInPattern = this.groupsNames.filter(
      g => !groupsInPattern.includes(g)
    );
    existingGroupsNotInPattern.forEach(g => {
      delete this.groups[g];
    });

    // Добавляем новые группы из паттерна
    const newGroupsFromPattern = groupsInPattern.filter(
      g => !this.groupsNames.includes(g)
    );
    newGroupsFromPattern.forEach(g => {
      this.groups[g] = ParseStepGroupModel.createDefault();
    });
  };

  @action
  updateErrorTransition = this.errorTransition.update;

  @action.bound
  toggleErrorTransition() {
    this.errorTransitionEnabled = !this.errorTransitionEnabled;
  }

  validate = () => {
    let errors = [];

    if (!this.expr) {
      errors.push('Не введен шаблон сообщения');
    }

    if (this.errorTransitionEnabled) {
      errors = [...errors, ...this.errorTransition.validate()];
    }

    this.groupsNames.forEach(groupName => {
      errors = [...errors, ...this.groups[groupName].validate(groupName)];
    });

    return errors;
  };

  toJson() {
    return toJS({
      source: this.source,
      expr: this.expr,
      error_transition: {
        ...this.errorTransition.toJson(),
        enabled: this.errorTransitionEnabled
      },
      groups: this.groupsNames.reduce(
        (acc, groupName) => ({
          ...acc,
          [groupName]: this.groups[groupName].toJson()
        }),
        {}
      )
    });
  }

  static createDefault(): ParseParamsModel {
    return new ParseParamsModel(
      '%сообщение%',
      PARSE_STEP_DEFAULT_GROUP,
      {
        [PARSE_STEP_DEFAULT_GROUP]: ParseStepGroupModel.createDefault()
      },
      TransitionParamsModel.createDefault(),
      false
    );
  }

  static fromJson(params): ParseParamsModel {
    const groups = Object.keys(params.groups).reduce(
      (acc, groupKey) => ({
        ...acc,
        [groupKey]: ParseStepGroupModel.fromJson(params.groups[groupKey])
      }),
      {}
    );
    return new ParseParamsModel(
      params.source,
      params.expr,
      groups,
      TransitionParamsModel.fromJson(params.error_transition),
      params.error_transition?.enabled
    );
  }
}
