import { observable, action, computed, toJS } from 'mobx';

import {
  VARS,
  VAR_SEX_MALE,
  VAR_MARKET_ORDER_STATUS,
  VARS_EVENT_TYPE_VALUE,
} from 'types/vars';
import { COMPARE, CONCAT_OPTIONS, CONDITION_KEY, EXPR } from 'types/condition';
import { getConditionOptionsByKey } from 'types/matches';
import generateId from 'utils/generateId';

import ExecSqParamsModel from './Step/ExecSqParamsModel';

export class SQConditionModel {
  @observable sqParams: ExecSqParamsModel;

  kind = EXPR;

  constructor(sqParams) {
    this.sqParams = sqParams;
  }

  toJson() {
    return toJS({
      kind: this.kind,
      params: this.sqParams.toJson(),
    });
  }

  static createDefault(): SQConditionModel {
    return new SQConditionModel(
      ExecSqParamsModel.createDefault(
        "%сообщение% == 'ключ' or %сообщение% == 'команда'"
      )
    );
  }

  static fromJson(data): SQConditionModel {
    return new SQConditionModel(ExecSqParamsModel.fromJson(data.params));
  }
}

export class ComparingModel {
  id = generateId();

  condition: SingleConditionModel = null;

  @observable key: string;

  @observable value: string;

  @observable comparing: COMPARE;

  constructor(
    key: string,
    value: string,
    comparing: COMPARE,
    condition: SingleConditionModel
  ) {
    this.key = key;
    this.value = value;
    this.comparing = comparing;
    this.condition = condition;
  }

  @action
  change({ key, value, comparing }) {
    if (key !== undefined) {
      this.key = key;
    }
    if (value !== undefined) {
      this.value = value;
    }
    if (comparing !== undefined) {
      this.comparing = comparing;
    }
  }

  @action
  validate = () => {
    if (!this.key) {
      return 'Условие не должно быть пустым';
    }
    return '';
  };

  @computed
  get options() {
    return getConditionOptionsByKey(this.key);
  }

  toJson() {
    const data = {
      kind: 'var',
      params: {
        key: this.key,
        value: {
          [this.comparing]: this.value,
        },
      },
    };

    return toJS(data);
  }

  static createDefault(
    type: CONDITION_KEY,
    condition: SingleConditionModel
  ): ComparingModel {
    let comparing = null;

    if (type === CONDITION_KEY.VARS) {
      comparing = new ComparingModel(
        VARS.USER_ID,
        null,
        COMPARE.LIST_IN,
        condition
      );
    } else if (type === CONDITION_KEY.MESSAGE) {
      comparing = new ComparingModel(VARS.MESSAGE, '', COMPARE.LIKE, condition);
    }

    return comparing;
  }

  static createVarDefaultDelivery(
    condition: SingleConditionModel
  ): ComparingModel {
    return new ComparingModel(VARS.SEX, VAR_SEX_MALE, COMPARE.EXACT, condition);
  }

  static createVarDefaultRepost(
    condition: SingleConditionModel
  ): ComparingModel {
    return new ComparingModel(VARS.POST_ID, '', COMPARE.EXACT, condition);
  }

  static createVarDefaultMarketModule(
    condition: SingleConditionModel
  ): ComparingModel {
    return new ComparingModel(
      VARS.ORDER_STATUS,
      VAR_MARKET_ORDER_STATUS.NEW,
      COMPARE.EXACT,
      condition
    );
  }

  static createVarDefaultEventType(
    condition: SingleConditionModel
  ): ComparingModel {
    return new ComparingModel(
      VARS.EVENT_TYPE,
      VARS_EVENT_TYPE_VALUE.MESSAGE_EVENT,
      COMPARE.EXACT,
      condition
    );
  }

  static fromJson(
    data,
    condition: SingleConditionModel
  ): ComparingModel | SQConditionModel {
    if (data.kind === EXPR) {
      return SQConditionModel.fromJson(data);
    }

    const comparing = Object.keys(data.params.value)[0];

    return new ComparingModel(
      data.params.key,
      data.params.value[comparing],
      comparing,
      condition
    );
  }
}

// export class SingleConditionModel {
//   constructor() {
//     console.log('hi')
//   }
// }

export class SingleConditionModel {
  type: CONDITION_KEY = null;

  @observable conditions: Array<ComparingModel> = [];

  @observable concatOption: CONCAT_OPTIONS.OR | CONCAT_OPTIONS.AND =
    CONCAT_OPTIONS.OR;

  constructor(type: CONDITION_KEY) {
    this.type = type;
  }

  validate = () => {
    let messageError = '';

    this.conditions.forEach((condition: ComparingModel) => {
      const validateMessage = condition.validate();
      if (validateMessage) {
        messageError = validateMessage;
        return validateMessage;
      }
    });

    return messageError;
  };

  @action
  addCondition = () => {
    this.conditions.push(ComparingModel.createDefault(this.type, this));
  };

  @action
  addDefaultVarConditionDelivery = () => {
    this.conditions.push(ComparingModel.createVarDefaultDelivery(this));
  };

  @action
  addDefaultVarConditionEventType = () => {
    this.conditions.push(ComparingModel.createVarDefaultEventType(this));
  };

  @action
  addDefaultRepostCondition = () => {
    this.conditions.push(ComparingModel.createVarDefaultRepost(this));
  };

  @action
  addDefaultMarketModuleCondition = () => {
    this.conditions.push(ComparingModel.createVarDefaultMarketModule(this));
  };

  @action
  deleteComparing = (index) => {
    this.conditions.splice(index, 1);
  };

  @action
  changeConcatOption = () => {
    if (this.concatOption === CONCAT_OPTIONS.OR) {
      this.concatOption = CONCAT_OPTIONS.AND;
    } else {
      this.concatOption = CONCAT_OPTIONS.OR;
    }
  };

  @action
  clear = () => {
    this.conditions = [];
    this.concatOption = CONCAT_OPTIONS.OR;
  };

  toJson() {
    let jsonData = {};

    if (this.concatOption) {
      jsonData[this.concatOption] = [];
    }

    (this.conditions || []).forEach((comparing: ComparingModel) => {
      const data = comparing.toJson();

      if (this.conditions.length > 1) {
        jsonData[this.concatOption].push(data);
      } else {
        jsonData = data;
      }
    });

    return toJS(jsonData);
  }

  static fromJson(condition, type: CONDITION_KEY): SingleConditionModel {
    const result = new SingleConditionModel(type);

    if (!condition || Object.keys(condition).length === 0) {
      return result;
    }

    const topLevelKey = Object.keys(condition)[0];

    if ([CONCAT_OPTIONS.OR, CONCAT_OPTIONS.AND].includes(topLevelKey)) {
      // Это объединение
      result.concatOption = topLevelKey;

      result.conditions = condition[topLevelKey].map((singleCondition) =>
        ComparingModel.fromJson(singleCondition, result)
      );
    } else {
      result.conditions = [ComparingModel.fromJson(condition, result)];
    }

    return result;
  }
}

export class ConditionModel {
  @observable
  messageCondition: SingleConditionModel = new SingleConditionModel(
    CONDITION_KEY.MESSAGE
  );

  @observable varCondition: SingleConditionModel = new SingleConditionModel(
    CONDITION_KEY.VARS
  );

  @observable sqCondition: SQConditionModel = null;

  @observable errors: Array<string> = [];

  constructor(
    messageCondition: SingleConditionModel = new SingleConditionModel(
      CONDITION_KEY.MESSAGE
    ),
    varCondition: SingleConditionModel = new SingleConditionModel(
      CONDITION_KEY.VARS
    ),
    sqCondition: SQConditionModel = null
  ) {
    this.messageCondition = messageCondition;
    this.varCondition = varCondition;
    this.sqCondition = sqCondition;
  }

  @computed
  get title() {
    return this.messageCondition?.conditions?.[0]?.value;
  }

  @computed
  get isEmpty() {
    return (
      this.messageCondition.conditions.length === 0 &&
      this.varCondition.conditions.length === 0
    );
  }

  @computed
  get hasAll() {
    return (
      this.messageCondition.conditions.length > 0 &&
      this.varCondition.conditions.length > 0
    );
  }

  @action
  setErrors = (errors) => {
    if (this.errors === [] && errors === []) {
      return;
    }

    this.errors = errors;
  };

  @action
  addCondition = (type: CONDITION_KEY) => {
    if (type === CONDITION_KEY.VARS) {
      this.varCondition.addCondition();
    }
    if (type === CONDITION_KEY.MESSAGE) {
      this.messageCondition.addCondition();
    }
    if (type === CONDITION_KEY.SQ) {
      this.sqCondition = SQConditionModel.createDefault();
    }
  };

  @action
  addMessageCondition = () => {
    this.addCondition(CONDITION_KEY.MESSAGE);
  };

  @action
  addVarCondition = () => {
    this.addCondition(CONDITION_KEY.VARS);
  };

  @action.bound
  addSQCondition() {
    this.addCondition(CONDITION_KEY.SQ);
  }

  @action.bound
  dropSQCondition() {
    this.sqCondition = null;
  }

  @action
  addDefaultVarConditionDelivery = () => {
    this.varCondition.addDefaultVarConditionDelivery();
  };

  @action
  clearAll = () => {
    this.messageCondition.clear();
    this.varCondition.clear();
  };

  validate = () => {
    const errors = [];

    // if (this.isEmpty) {
    //   errors.push('Должно быть указано хотя бы одно условие');
    // }

    const varValidate = this.varCondition.validate();
    if (varValidate) {
      errors.push(varValidate);
    }

    this.setErrors(errors);

    return errors.length === 0;
  };

  @action
  addDefaultVarConditionEventType = () => {
    this.varCondition.addDefaultVarConditionEventType();
  };

  toJson() {
    return toJS({
      message_condition: this.messageCondition.toJson(),
      vars_condition: this.varCondition.toJson(),
      sq_condition: this.sqCondition ? this.sqCondition.toJson() : null,
    });
  }

  static createWithDefault(): ConditionModel {
    const condition = new ConditionModel();
    condition.addMessageCondition();

    return condition;
  }

  static createDefaultWithEventType(): ConditionModel {
    const condition = new ConditionModel();
    condition.addDefaultVarConditionEventType();

    return condition;
  }

  // создание условий на переменные по умолчанию для рассылок
  static createWithDefaultVarDelivery(): ConditionModel {
    const condition = new ConditionModel();
    condition.addDefaultVarConditionDelivery();

    return condition;
  }

  static createEmpty(): ConditionModel {
    return new ConditionModel();
  }

  static fromJson(condition): ConditionModel {
    return new ConditionModel(
      SingleConditionModel.fromJson(
        condition.message_condition,
        CONDITION_KEY.MESSAGE
      ),
      SingleConditionModel.fromJson(
        condition.vars_condition,
        CONDITION_KEY.VARS
      ),
      condition.sq_condition && condition.sq_condition !== {}
        ? SQConditionModel.fromJson(condition.sq_condition)
        : null
    );
  }
}
