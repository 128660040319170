import { observable, action, toJS } from 'mobx';

import { KeyboardButtonModel, KeyboardModel } from './KeyboardModel';
import { GeoFieldModel } from './GeoFieldModel';
import TemplateModel from './TemplateModel';

// модель сообщений с параметрами
class MessageParamsModel<T extends TemplateModel = TemplateModel> {
  @observable dontParseLinks: boolean;

  @observable keyboard: KeyboardModel;

  @observable template: T = null;

  @observable hidePrevKeyboard = false;

  @observable geo: GeoFieldModel;

  constructor({
    keyboard = null,
    dontParseLinks = false,
    hidePrevKeyboard = false,
    geo = null,
  }: {
    geo?: GeoFieldModel;
    keyboard?: KeyboardModel;
    hidePrevKeyboard?: boolean;
    dontParseLinks?: boolean;
  }) {
    this.keyboard = keyboard;
    this.dontParseLinks = dontParseLinks;
    this.hidePrevKeyboard = hidePrevKeyboard;
    this.geo = geo;
  }

  @action
  handleAddKeyboard = (): void => {
    this.keyboard = KeyboardModel.createDefault();
  };

  @action
  toggleKeyboard = (): void => {
    this.keyboard = this.keyboard ? null : KeyboardModel.createDefault();
    console.log(this.keyboard);
  };

  @action
  disableGeo = (): void => {
    this.geo = null;
  };

  @action
  addGeo = (geo: GeoFieldModel): void => {
    this.geo = geo;
  };

  @action
  toggleDontParseLinks = (): void => {
    this.dontParseLinks = !this.dontParseLinks;
  };

  @action
  toggleHidePrevKeyboard = (): void => {
    this.hidePrevKeyboard = !this.hidePrevKeyboard;

    if (!this.hidePrevKeyboard && this.keyboard) {
      if (this.keyboard.buttons?.length === 0) {
        this.keyboard = null;
      }
    }
  };

  validate(): string[] {
    const errors = [];

    if (this.keyboard) {
      const keyboardError = this.keyboard.validate();
      if (keyboardError) {
        errors.push(keyboardError);
      }
    }

    if (this.keyboard?.buttons && this.keyboard?.buttons?.length > 0) {
      if (this.template) {
        errors.push('Сообщение не может одновременно содержать и клавиатуру с кнопками, и карусель,' +
            ' что-то одно придётся удалить');
      }

      this.keyboard.buttons.forEach((row) => {
        row.forEach((btn: KeyboardButtonModel) => {
          const validateBtn = btn.validate();
          if (validateBtn !== true) {
            errors.push(validateBtn);
          }
        });
      });
    }

    return errors;
  }

  toJson() {
    const data = {
      dont_parse_links: this.dontParseLinks,
      keyboard: null,
      template: null,
      geo: this.geo ? this.geo.toJson() : null,
    };

    if (this.keyboard) {
      data.keyboard = this.keyboard.toJson();
    } else if (this.hidePrevKeyboard) {
      data.keyboard = KeyboardModel.createDefault().toJson();
    }

    if (this.template) {
      data.template = this.template.toJson();
    }

    return toJS(data);
  }

  static createDefault(): MessageParamsModel {
    return new MessageParamsModel({
      keyboard: KeyboardModel.createDefault(),
    });
  }

  static fromJson(params) {
    const data = {
      keyboard: params.keyboard
        ? KeyboardModel.fromJson(params.keyboard)
        : null,
      dontParseLinks: params.dont_parse_links,
      hidePrevKeyboard: Boolean(params.keyboard),
      geo: params.geo ? GeoFieldModel.fromJson(params.geo) : null,
      template: params?.template
        ? TemplateModel.createModelByType(params.template)
        : null,
    };

    if (data.keyboard && data.keyboard.buttons?.length === 0) {
      data.keyboard = null;
    }

    return data;
  }
}

export { MessageParamsModel };
