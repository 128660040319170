import { action, observable, toJS } from 'mobx';

import apiUrls from 'config/api';
import api from 'store/api';
import type { SubscribeItemType } from 'types/moduleLanding';
import {
  LANDING_ITEM_KIND,
  ITEM_STATUS,
  LANDING_ITEM_BUTTON
} from 'types/moduleLanding';

import ItemModel, { LandingButtonModel } from './ItemModel';

class BasicItemModel extends ItemModel {
  @action.bound
  setDescription(value) {
    super.setDescription(value);
  }

  @action.bound
  setTitle(value) {
    super.setTitle(value);
  }

  async transition(): Promise<boolean> {
    const { response } = await api(
      `${apiUrls.landingTransition}${window.LOGIN_PARAMS}`,
      'POST',
      {
        block_id: this.blockId
      },
      {
        errorConfig: {
          isShowError: true,
          defaultMessage: 'Ошибка! Не удалось запустить сценарий'
        }
      }
    );
    return Boolean(response);
  }

  getButton(): LandingButtonModel {
    return this.buttons[LANDING_ITEM_BUTTON.basic.click];
  }

  static createDefault(): BasicItemModel {
    return new BasicItemModel({
      kind: LANDING_ITEM_KIND.BASIC,
      title: 'Заголовок',
      description: 'Описание',
      imageUrl: null,
      blockId: null,
      buttons: {
        [LANDING_ITEM_BUTTON.basic.click]: LandingButtonModel.createDefault(
          'Начать',
          true
        )
      }
    });
  }

  static fromJson(params): BasicItemModel {
    return new BasicItemModel({
      ...super.fromJson(params)
    });
  }
}

export default BasicItemModel;
