import { observable, action, toJS } from 'mobx';

export default class CallAdminParamsModel {
  @observable admins: Array<string> = [];
  @observable text: string = '';
  @observable forwardOriginal: boolean = false;

  constructor(admins, text, forwardOriginal) {
    this.admins = admins;
    this.text = text;
    this.forwardOriginal = forwardOriginal;
  }

  validate = () => {
    if (!this.text || !this.text.trim()) {
      return ['Не заполнено поле сообщения'];
    }
    return [];
  };

  @action
  setAdmins = admins => {
    this.admins = admins;
  };

  @action
  setText = text => {
    this.text = text;
  };

  @action
  toggleForwardOriginal = () => {
    this.forwardOriginal = !this.forwardOriginal;
  };

  toJson() {
    return toJS({
      admins: this.admins.slice(),
      text: this.text,
      forward_original: this.forwardOriginal
    });
  }

  static createDefault(): CallAdminParamsModel {
    return new CallAdminParamsModel(
      [],
      'Пользователь %имя% %фамилия% %вк_польз% прислал Вам сообщение.\n' +
        'Ссылка на диалог: %чат%\n' +
        '#SmartBot',
      true
    );
  }

  static fromJson(params): CallAdminParamsModel {
    return new CallAdminParamsModel(
      params.admins,
      params.text,
      params.forward_original
    );
  }
}
