import React from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { globalSpacingStyle } from 'styles/constants';

const Preview = styled.div`
  border: 1px solid #e4e4e4;
  position: fixed;
  padding: ${globalSpacingStyle.m};
  background: white;
  border-radius: 5px;
  transform: translate(${props => props.x + 10}px, ${props => props.y + 10}px);
  pointer-events: none;
  top: 0;
  left: 0;
  transition: 0.05s linear;
  z-index: 100000;
`;

export default class extends React.Component {
  state = {
    x: 0,
    y: 0
  };

  componentDidMount() {
    document.addEventListener('mousemove', this.onMouseMove);
  }

  onMouseMove = e => {
    const newX = Math.floor(e.clientX);
    const newY = Math.floor(e.clientY);

    const { x, y } = this.state;

    if (newX !== x || newY !== y) {
      this.setState({
        x: newX,
        y: newY
      });
    }
  };

  render() {
    return ReactDOM.createPortal(
      <Preview {...this.state}>{this.props.children}</Preview>,
      document.getElementById('cursor-info-placeholder')
    );
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.onMouseMove);
  }
}
