export enum VARS {
  NAME = '%имя%',
  SURNAME = '%фамилия%',
  MESSAGE = '%сообщение%',
  SEX = '%пол%',
  POST_ID = '%id_поста%',
  TIME = '%время%',
  HOUR = '%час%',
  MINUTE = '%минута%',
  CITY = '%город%',
  DATE = '%дата%',
  USER_ID = '%id_польз%',
  DAY = '%день%',
  DAY_OF_WEEK = '%день_недели%',
  MONTH = '%месяц%',
  YEAR = '%год%',
  PAYMENT_AMOUNT = '%payment.amount%',
  PAYMENT_TYPE = '%payment.type%',
  MESSAGES_RECEIVED = '%messages_received%',
  ADMIN_ID = '%admin_id%',
  TIMESTAMP = '%timestamp%',
  REF = '%ref%',
  REF_SOURCE = '%ref_source%',
  ATTACH_TYPE = '%attachment_type%',
  ATTACH_ID = '%attachment_id%',
  LIKED_TYPE = '%liked_type%',
  LIKED_ID = '%liked_id%',
  REALM = '%realm%',
  // Модуль реакции на заказ товара ВК
  ORDER_ID = '%order_id%',
  ORDER_STATUS = '%order_status%',
  ORDER_PRICE = '%order_price%',
  EVENT_TYPE = '%event_type%',
  LEAD_ID = '%lead.id%',
  LEAD_FORM_ID = '%lead.form_id%',
  LEAD_FORM_NAME = '%lead.form_name%',
  LEAD_ANSWER = '%lead.answers%',
}

export const VARS_TITLES = {
  [VARS.MESSAGE]: 'Сообщение подписчика',
  [VARS.SEX]: 'Пол',
  [VARS.PAYMENT_AMOUNT]: 'Сумма оплаты, ₽',
  [VARS.PAYMENT_TYPE]: 'Тип оплаты: vkpay, yam',
  [VARS.USER_ID]: 'ID пользователя',
  [VARS.DATE]: 'Дата',
  [VARS.TIME]: 'Местное время',
  [VARS.HOUR]: 'Текущий час',
  [VARS.MINUTE]: 'Текущая минута',
  [VARS.POST_ID]: 'ID поста на стене',
  [VARS.CITY]: 'Город подписчика',
  [VARS.DAY]: 'День месяца (число)',
  [VARS.DAY_OF_WEEK]: 'День недели',
  [VARS.MONTH]: 'Текущий месяц',
  [VARS.YEAR]: 'Текущий год',
  [VARS.TIMESTAMP]: 'Абсолютное время, сек',
  [VARS.MESSAGES_RECEIVED]: 'Принятых сообщений',
  [VARS.REF]: 'Источник перехода ref',
  [VARS.REF_SOURCE]: 'Источник перехода ref_source',
  [VARS.ATTACH_TYPE]: 'Тип вложения',
  [VARS.ATTACH_ID]: 'ID вложения',
  [VARS.LIKED_ID]: 'ID лайкнутого объекта',
  [VARS.LIKED_TYPE]: 'Тип лайкнутого объекта',
  [VARS.REALM]: 'Область контекста сообщения',
  [VARS.ADMIN_ID]: 'ID админа',
  [VARS.ORDER_ID]: 'Номер заказа товара ВК',
  [VARS.ORDER_STATUS]: 'Статус заказа товара ВК',
  [VARS.ORDER_PRICE]: 'Стоимость заказа товара ВК',
  [VARS.EVENT_TYPE]: 'События в сообществах',
  [VARS.LEAD_ID]: 'ID заявки',
  [VARS.LEAD_FORM_ID]: 'ID формы заявки',
  [VARS.LEAD_FORM_NAME]: 'Название формы',
  [VARS.LEAD_ANSWER]: 'Ответы формы',
};

export enum VARS_EVENT_TYPE_VALUE {
  MESSAGE_EVENT = 'message_event',
}

export const VARS_EVENT_TYPE_VALUE_TITLE = {
  [VARS_EVENT_TYPE_VALUE.MESSAGE_EVENT]:
    'Нажатие на Callback-кнопку (message_event)',
};

export const VARS_EVENT_TYPE_OPTIONS: Array<{
  label: string;
  value: VARS_EVENT_TYPE_VALUE;
}> = Object.keys(VARS_EVENT_TYPE_VALUE).map((k) => ({
  label: VARS_EVENT_TYPE_VALUE_TITLE[VARS_EVENT_TYPE_VALUE[k]],
  value: VARS_EVENT_TYPE_VALUE[k],
}));

export const VAR_SEX_FEMALE = 'женский';
export const VAR_SEX_MALE = 'мужской';

export const VAR_SEX_OPTIONS = [
  { label: VAR_SEX_FEMALE, value: VAR_SEX_FEMALE },
  { label: VAR_SEX_MALE, value: VAR_SEX_MALE },
];

export enum VAR_ATTACH_TYPE {
  IMG = 'Фотография',
  VIDEO = 'Видеозапись',
  AUDIO = 'Аудиозапись',
  DOC = 'Документ',
}

export enum VAR_ATTACH_TYPE_VALUE {
  IMG = 'photo',
  VIDEO = 'video',
  AUDIO = 'audio',
  DOC = 'doc',
}

export const VAR_ATTACH_TYPE_OPTIONS = [
  { label: VAR_ATTACH_TYPE.IMG, value: VAR_ATTACH_TYPE_VALUE.IMG },
  { label: VAR_ATTACH_TYPE.VIDEO, value: VAR_ATTACH_TYPE_VALUE.VIDEO },
  { label: VAR_ATTACH_TYPE.AUDIO, value: VAR_ATTACH_TYPE_VALUE.AUDIO },
  { label: VAR_ATTACH_TYPE.DOC, value: VAR_ATTACH_TYPE_VALUE.DOC },
];

export enum VAR_LIKED_TYPE_VALUE {
  POST = 'post',
  COMMENT = 'comment',
  PHOTO = 'photo',
  VIDEO = 'video',
  NOTE = 'note',
  TOPIC_COMMENT = 'topic_comment',
  PHOTO_COMMENT = 'photo_comment',
  VIDEO_COMMENT = 'video_comment',
  MARKET = 'market',
  MARKET_COMMENT = 'market_comment',
}

export const VAR_LIKED_TYPE: {
  [key in keyof typeof VAR_LIKED_TYPE_VALUE]: string;
} = {
  POST: 'Пост',
  COMMENT: 'Комментарий',
  PHOTO: 'Фотография',
  VIDEO: 'Видеозапись',
  NOTE: 'Заметка',
  TOPIC_COMMENT: 'Комментарий в обсуждении',
  PHOTO_COMMENT: 'Комментарий к фотографии',
  VIDEO_COMMENT: 'Комментарий к видео',
  MARKET: 'Товар',
  MARKET_COMMENT: 'Комментарий к товару',
};

export const VAR_LIKED_TYPE_OPTIONS: Array<{
  label: string;
  value: string;
}> = Object.keys(VAR_LIKED_TYPE).map((k) => ({
  label: VAR_LIKED_TYPE[k],
  value: VAR_LIKED_TYPE_VALUE[k],
}));

type DayWeekType =
  | 'понедельник'
  | 'вторник'
  | 'среда'
  | 'четверг'
  | 'пятница'
  | 'суббота'
  | 'воскресенье';

export const VAR_DAY_OF_WEEK_OPTIONS = [
  'понедельник',
  'вторник',
  'среда',
  'четверг',
  'пятница',
  'суббота',
  'воскресенье',
].map((v: DayWeekType) => ({ label: v, value: v }));

export const VAR_PAYMENT_TYPE_OPTIONS: Array<{
  label: 'ЮMoney' | 'VK Pay';
  value: 'yam' | 'vkpay';
}> = [
  { label: 'ЮMoney', value: 'yam' },
  { label: 'VK Pay', value: 'vkpay' },
];

export const VAR_MARKET_ORDER_STATUS = {
  NEW: 0,
  AGREED: 1,
  COLLECT: 2,
  DELIVERY: 3,
  DONE: 4,
  CANCELED: 5,
  RETURNED: 6,
};

export const VAR_MARKET_ORDER_STATUS_TITLE: {
  [key in keyof typeof VAR_MARKET_ORDER_STATUS]: string;
} = {
  NEW: 'Новый',
  AGREED: 'Согласуется',
  COLLECT: 'Собирается',
  DELIVERY: 'Доставляется',
  DONE: 'Выполнен',
  CANCELED: 'Отменен',
  RETURNED: 'Возвращен',
};

export const VAR_MARKET_ORDER_STATUS_OPTIONS: Array<{
  label: string;
  value: number;
}> = Object.keys(VAR_MARKET_ORDER_STATUS).map((k) => ({
  label: VAR_MARKET_ORDER_STATUS_TITLE[k],
  value: VAR_MARKET_ORDER_STATUS[k],
}));

export enum VAR_REALM {
  GROUP = 'group',
  CHATS = 'chats',
  WALL = 'wall',
}

export const VAR_REALM_TITLE: { [key in keyof typeof VAR_REALM]: string } = {
  GROUP: 'Сообщения сообщества',
  CHATS: 'Беседа',
  WALL: 'Стена сообщества',
};

export const VAR_REALM_OPTIONS: Array<{
  label: string;
  value: VAR_REALM;
}> = Object.keys(VAR_REALM).map((k) => ({
  label: VAR_REALM_TITLE[k],
  value: VAR_REALM[k],
}));

export enum VAR_TYPE {
  str = 'str',
  num = 'num',
  list = 'list',
  dict = 'dict',
}

export const VAR_TYPE_LABEL: { [key in keyof typeof VAR_TYPE]: string } = {
  str: 'Строка',
  num: 'Число',
  list: 'Массив',
  dict: 'Словарь',
};

export enum VAR_LEVEL {
  GLOBAL = 'bot',
  LOCAL = 'chat',
}
