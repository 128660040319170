import { observable } from 'mobx';

import type { STEP } from '../../types';

export default class EmptyParamsModel {
  @observable kind: STEP = null;

  @observable text = '';

  constructor(kind, text) {
    this.kind = kind;
    this.text = text;
  }

  // eslint-disable-next-line class-methods-use-this
  toJson() {
    return {};
  }
}
