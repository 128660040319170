import { observable, action, toJS, computed } from 'mobx';

export const MAX_VAR_CHANGES = 16;

export class ChangeSingleVarParamsModel {
  @observable key: string = '';

  @observable value: string = '';

  constructor(key, value) {
    this.key = key;
    this.value = value;
  }

  @action.bound
  setKey(key: string) {
    this.key = key;
  }

  @action
  setValue = value => {
    this.value = value;
  };

  validate = () => {
    const errors = [];
    if (!this.key) {
      errors.push('Не выбрана переменная');
    }
    return errors;
  };

  toJson() {
    return toJS({
      key: this.key,
      value: this.value
    });
  }

  static createDefault(): ChangeSingleVarParamsModel {
    return new ChangeSingleVarParamsModel('', '');
  }

  static fromJson(params): ChangeVarParamsModel {
    return new ChangeSingleVarParamsModel(params.key, params.value);
  }
}

export default class ChangeVarParamsModel {
  @observable ops: ChangeSingleVarParamsModel[] = [];

  constructor(ops = []) {
    this.ops = ops;
  }

  @computed
  get canAddOperation() {
    return this.ops.length < MAX_VAR_CHANGES;
  }

  @action.bound
  addOperation() {
    this.ops.push(ChangeSingleVarParamsModel.createDefault());
  }

  @action.bound
  deleteOperation(index: number) {
    this.ops = this.ops.filter((s, i) => i !== index);
  }

  validate = () => {
    const errors = [];
    this.ops.forEach(op => {
      errors.push(...op.validate());
    });
    return errors;
  };

  toJson() {
    return toJS({
      ops: this.ops.map(op => op.toJson())
    });
  }

  static createDefault(): ChangeVarParamsModel {
    return new ChangeVarParamsModel([
      ChangeSingleVarParamsModel.createDefault()
    ]);
  }

  static fromJson(params): ChangeVarParamsModel {
    return new ChangeVarParamsModel(
      (params.ops || []).map(ChangeSingleVarParamsModel.fromJson)
    );
  }
}
