export const GET_LIST = 'GET_VARS_LIST';
export const GET_LIST_ERROR = 'GET_VARS_LIST_ERROR';
export const GET_LIST_SUCCESS = 'GET_VARS_LIST_SUCCESS';

export const SAVE_ENTITIES = 'SAVE_VARS_ENTITIES';
export const SAVE_SPECIAL_VARS = 'SAVE_SPECIAL_VARS';

export const DELETE_VAR = 'DELETE_VAR';
export const DROP_VAR = 'DROP_VAR';
export const DELETE_VAR_ERROR = 'DELETE_VAR_ERROR';

export const DROP_ALL_VARS = 'DROP_ALL_VARS';

export const getVars = (offset, limit = 20) => ({
    type: GET_LIST,
    offset, limit
});

export const dropVars = () => ({
    type: DROP_ALL_VARS,
});

export const saveEntities = vars => ({
    type: SAVE_ENTITIES,
    vars
});

export const saveSpecialVars = vars => ({
    type: SAVE_SPECIAL_VARS,
    vars
});

export const deleteVar = (_id) => ({
    type: DELETE_VAR,
    _id
});

export const dropVar = _id => ({
    type: DROP_VAR,
    _id
});

export default {
    getVars,
    dropVars,
    saveEntities,
    saveSpecialVars,
    deleteVar,
    dropVar,

    GET_LIST, GET_LIST_ERROR, GET_LIST_SUCCESS,
    SAVE_ENTITIES, SAVE_SPECIAL_VARS,
    DROP_VAR, DELETE_VAR, DELETE_VAR_ERROR,
    DROP_ALL_VARS
}