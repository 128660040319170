// @flow
import React from 'react';
import { observer } from 'mobx-react';

import BlockModel from 'models/Block';
import RootStore, { withStore } from 'models/RootStore';
import type { StoreProps } from 'models/RootStore';
import { BlockPreviewModel } from 'models/Block/BlockPreviewModel';
import { TRANSITION_ERROR } from 'models/Step/TransitionParams';

import { Selector, Error } from './styles';
import {
  CREATE_BLOCK_ACTION,
  filter,
  renderFiltered,
  sortFiltered,
  getSelectTree,
  getSelectorOptions,
  getDynamicSelectorOptions
} from './utils';

type Props = StoreProps & {
  onSelect: (blockId: string, stepsGroupId?: string, stepId?: string) => void,
  currentBlock?: BlockModel,
  onlyBlocks?: boolean,
  onCreate?: () => void,
  blockId?: string,
  stepsGroupId?: string,
  stepId?: string,
  canCreate?: boolean,
  showCurrent?: boolean,
  store: RootStore
};

type State = {
  isPopupVisible: boolean
};

@withStore
@observer
class BlockSelector extends React.Component<Props, State> {
  state = {
    isPopupVisible: false
  };

  // Кешируем созданные опции для селектора
  loadedOptions = [];

  static defaultProps = {
    onCreate: () => {},
    onlyBlocks: false,
    currentBlock: null,
    blockId: null,
    stepsGroupId: null,
    stepId: null,
    canCreate: true,
    showCurrent: true
  };

  get options() {
    const {
      canCreate,
      currentBlock,
      onlyBlocks,
      showCurrent,
      store,
      blockId
    } = this.props;

    const { isPopupVisible } = this.state;

    let selectedBlock:
      | BlockModel
      | BlockPreviewModel
      | null = store.blockStore.getBlock(blockId);

    if (!selectedBlock && currentBlock?.id === blockId) {
      selectedBlock = currentBlock;
    }

    if (!blockId && !isPopupVisible) {
      // Если блок удален или это закрытый пустой селектор
      return [];
    }

    if (!isPopupVisible && selectedBlock) {
      return getSelectTree([selectedBlock], {
        onlyBlocks,
        currentBlock,
        showCurrent
      });
    }

    const { blocksList } = store.blockStore;
    if (this.loadedOptions.length > 0) {
      // обновляем опции текущего блока
      if (currentBlock) {
        this.loadedOptions = getDynamicSelectorOptions(
          this.loadedOptions,
          currentBlock,
          onlyBlocks,
          showCurrent
        );
      }
      // собираем уникальные имена и id в коллекции
      const loadedOptionsNames = new Set(this.loadedOptions.map(o => o.name));
      const loadedOptionsIds = new Set(this.loadedOptions.map(o => o.value));

      // проверяем, что каждый элемент из списка блоков в сторе содержится
      // в этих коллекциях с уникальной парой (id, name),
      // либо у текущего блока изменено name
      let optionsContainsAllBlocks = blocksList.every(
        b =>
          (loadedOptionsNames.has(b.name) && loadedOptionsIds.has(b.id)) ||
          b.id === currentBlock?.id
      );

      // если в списке блоков не надо отображать текущий блок как "Текущий блок"
      if (currentBlock && !showCurrent) {
        // проверяем не изменилось ли name
        optionsContainsAllBlocks =
          optionsContainsAllBlocks &&
          loadedOptionsNames.has(currentBlock.name) &&
          loadedOptionsIds.has(currentBlock.id);
      }

      // если ничего не поменялось, возвращаем опции из кэша
      if (optionsContainsAllBlocks) {
        return this.loadedOptions;
      }
    }

    this.loadedOptions = getSelectorOptions({
      blocksList: store.blockStore.blocksList,
      canCreate,
      currentBlock,
      onlyBlocks,
      showCurrent
    });

    return this.loadedOptions;
  }

  get selectedPath() {
    const { blockId, stepsGroupId, stepId } = this.props;

    if (!blockId) {
      return [];
    }
    return this.checkPath([blockId, stepsGroupId, stepId], {
      block: [],
      stepsGroup: [blockId],
      step: [blockId, stepsGroupId]
    });
  }

  checkPath(successValue, errorValue) {
    const { blockId, stepsGroupId, stepId, currentBlock, store } = this.props;

    // проверяем не удалили ли блок
    const selectedBlockData =
      store.blockStore.blockPreviews.get(blockId) ||
      blockId === currentBlock?.id;

    // если блок был удален
    if (!selectedBlockData) {
      return errorValue.block;
    }
    const selectedBlock =
      blockId === currentBlock?.id ? currentBlock : selectedBlockData;

    if (stepsGroupId) {
      const selectedStepGroupData = selectedBlock.searchStepsGroup(
        stepsGroupId
      );
      if (!selectedStepGroupData) {
        return errorValue.stepsGroup;
      }
      if (stepId && !selectedStepGroupData.searchStep(stepId)) {
        return errorValue.step;
      }
    }
    return successValue;
  }

  get error() {
    const { blockId } = this.props;

    if (!blockId) {
      return false;
    }
    return this.checkPath(null, {
      block: TRANSITION_ERROR.BLOCK_ERROR_MESSAGE,
      stepsGroup: TRANSITION_ERROR.STEPS_GROUP_ERROR_MESSAGE,
      step: TRANSITION_ERROR.STEP_ERROR_MESSAGE
    });
  }

  handleVisibleChange = visible => {
    this.setState({ isPopupVisible: visible });
  };

  onChange = ([blockId, stepsGroupId, stepId]) => {
    if (blockId === CREATE_BLOCK_ACTION) {
      return this.props.onCreate();
    }
    this.props.onSelect(blockId, stepsGroupId, stepId);
  };

  renderSelected = (labels, selectedOptions) => {
    return selectedOptions
      .map(option => option.name || option.title || option.label)
      .join(' / ');
  };

  render() {
    return (
      <>
        <Selector
          allowClear
          showSearch={{
            filter,
            sort: sortFiltered,
            render: renderFiltered
          }}
          changeOnSelect
          onPopupVisibleChange={this.handleVisibleChange}
          expandTrigger={window.isMobile ? 'click' : 'hover'}
          value={this.selectedPath}
          notFoundContent="Не найдено"
          options={this.options}
          onChange={this.onChange}
          displayRender={this.renderSelected}
          placeholder="Выберите блок для перехода"
        />
        {this.error && <Error>{this.error}</Error>}
      </>
    );
  }
}

export default BlockSelector;
