import React, { DOMAttributes } from 'react';
import styled from 'styled-components';

import Help from 'components/Help';

const Link = styled.a`
  position: relative;
  cursor: pointer;
  text-align: right;
  display: inline-block;

  &:hover {
    text-decoration: underline;

    .button-link__help {
      display: block;
    }
  }
`;

type Props = DOMAttributes<HTMLAnchorElement> & {
  help?: React.ReactNode;
  helpToRight?: boolean;
};

const ButtonLink: React.FC<Props> = (props) => {
  const { help, helpToRight, children, ...otherProps } = props;

  return (
    <Link {...otherProps}>
      {children}
      {help && (
        <Help className="button-link__help" toRight={helpToRight}>
          {help}
        </Help>
      )}
    </Link>
  );
};

ButtonLink.defaultProps = {
  helpToRight: true,
};

export type { Props as ButtonLinkProps };
export default ButtonLink;
