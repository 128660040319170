import { observable, action, toJS } from 'mobx';

export default class SwitchContextParamsModel {
  @observable userSpec: string = '';

  @observable switchTo: boolean = false;

  constructor({ userSpec, switchTo }) {
    this.userSpec = userSpec;
    this.switchTo = switchTo;
  }

  @action.bound
  setUserSpec(value) {
    this.userSpec = value;
  }

  @action.bound
  setSwitchTo(value) {
    this.switchTo = value;
  }

  validate = () => {
    const errors = [];
    if ((!this.userSpec || !this.userSpec.trim()) && !this.switchTo) {
      errors.push('Не заполнено поле переключение контекста.');
    }

    return errors;
  };

  toJson() {
    return toJS({
      user_spec: this.userSpec,
      switch_to_pm: this.switchTo
    });
  }

  static createDefault(): SwitchContextParamsModel {
    return new SwitchContextParamsModel({
      userSpec: '',
      switchTo: false
    });
  }

  static fromJson(params): SwitchContextParamsModel {
    return new SwitchContextParamsModel({
      userSpec: params.user_spec,
      switchTo: params.switch_to_pm
    });
  }
}
