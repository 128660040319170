import update from 'immutability-helper';

import actions from './actions';

export const initialState = {
  isLoading: false,
  lists: []
};

const actionsMap = {
  [actions.SAVE_LISTS]: (state, { payload: { lists } }) =>
    update(state, {
      lists: { $set: lists },
      isLoading: { $set: false }
    }),
  [actions.CREATE_LIST]: (state, { payload: { list } }) =>
    update(state, {
      lists: { $set: [...state.lists, list] }
    }),
  [actions.REMOVE_LIST]: (state, { payload: { listId } }) =>
    update(state, {
      lists: { $set: state.lists.filter(l => l._id !== listId) }
    }),
  [actions.RENAME_LIST]: (state, { payload: { listId, name } }) =>
    update(state, {
      lists: {
        $apply: items => {
          const itemsCopy = [...items];
          (itemsCopy.find(l => l._id === listId) || {}).name = name;
          return itemsCopy;
        }
      }
    })
};

export default (state = initialState, action = {}) => {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
};
