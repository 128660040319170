import { Button } from 'antd';
import styled from 'styled-components';

import { globalSpacingStyle, color, mediaSizes } from 'styles/constants';

export const Container = styled.div`
  display: flex;
  position: relative;
  margin-top: ${globalSpacingStyle.l};
  justify-content: space-between;
  align-items: center;

  .is-mobile & {
    flex-direction: column;
  }
`;

export const Image = styled.img`
  display: block;
  position: relative;
  width: 100px;
  margin-right: ${globalSpacingStyle.m};

  .is-mobile & {
    margin-right: 0;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: ${mediaSizes.maxWidthLanding};
  padding: 10px;

  .is-mobile & {
    padding: 10px 0;
  }
`;

export const PriceWrapper = styled.div`
  position: relative;

  .is-mobile & {
    width: 100%;
    text-align: center;
  }
`;

export const Title = styled.div`
  position: relative;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 700;
  white-space: pre-wrap;
  margin-bottom: ${globalSpacingStyle.s};
  color: ${color.primary};

  .is-mobile & {
    text-align: center;
  }
`;

export const Description = styled.div`
  position: relative;
  font-size: 12px;
  line-height: 1.5;
  white-space: pre-wrap;
`;

export const ButtonWrapper = styled(Button)`
  display: block;
  margin: 0 auto;
  min-width: 120px;
  align-self: center;

  .is-mobile & {
    width: 100%;
  }
`;

export const Price = styled.div`
  position: relative;
  color: ${color.primary};
  font-size: 14px;
  line-height: 16px;
`;

export const PriceNumber = styled.div`
  position: relative;
  color: ${color.primary};
  font-size: 18px;
  line-height: 20px;
  margin-bottom: ${globalSpacingStyle.m};
`;
