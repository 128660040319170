// @flow
import { action, observable, toJS } from 'mobx';

export class GeoFieldModel {
  @observable lat: string;
  @observable long: string;
  @observable url: string;

  constructor(lat, long, url) {
    this.lat = lat;
    this.long = long;
    this.url = url;
  }

  @action
  setLat(lat) {
    this.lat = lat;
  }

  @action
  setLong(long) {
    this.long = long;
  }

  @action
  setUrl(url) {
    this.url = url;
  }

  toJson() {
    return toJS({
      lat: this.lat,
      long: this.long,
      url: this.url
    });
  }

  static fromJson(json): GeoFieldModel {
    if (!json) {
      return null;
    }
    return new GeoFieldModel(json.lat, json.long, json.url);
  }
}
