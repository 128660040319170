import React, { ReactElement } from 'react';
import { Button, Spin } from 'antd';

import Modal from 'components/Modal';

import { Input, Wrapper, Error } from './styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (name: string) => void;
  isLoading?: boolean;
  defaultBlockName?: string;
  placeholder: string;
  description?: ReactElement;
  error?: string;
};

class CreateModal extends React.Component<Props, { title: string }> {
  static defaultProps = {
    isLoading: false,
    defaultBlockName: '',
    description: '',
    error: '',
  };

  state = {
    title: this.props.defaultBlockName || '',
  };

  onChangeTitle = ({ target: { value }}) => {
    this.setState({
      title: value,
    });
  };

  create = () => {
    this.props.onCreate(this.state.title);
  };

  onKeyPress = async (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      await this.create();
      return false;
    }
    return true;
  };

  render() {
    const { title } = this.state;
    const {
      isOpen,
      onClose,
      isLoading,
      placeholder,
      description,
      error,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        footer={[
          <Button type="primary" onClick={this.create} disabled={!title}>
            Создать
          </Button>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Wrapper>
            {description}
            <Input
              onKeyPress={this.onKeyPress}
              placeholder={placeholder}
              value={title}
              onChange={this.onChangeTitle}
              autoFocus
            />
            <Error>{error}</Error>
          </Wrapper>
        </Spin>
      </Modal>
    );
  }
}

export default CreateModal;
