import React from 'react';
import { SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { landingView } from 'config/urls';

import { ShowPreview } from './styles';

type Props = {
  id: string | number,
  onSave: () => void
};

const EditButtons = ({ id, onSave }: Props) => {
  return <>
    <ShowPreview to={landingView(id)}>
      <Button type="primary" icon={<SearchOutlined />}>
        {!window.isMobile && 'Посмотреть превью'}
      </Button>
    </ShowPreview>
    <Button icon={<SaveOutlined />} type="primary" onClick={onSave}>
      {!window.isMobile && 'Сохранить'}
    </Button>
  </>;
};

export default EditButtons;
