import { observable, action, toJS } from 'mobx';

import types from 'types/timeTypes';

export class PlanEventIntervalParams {
  @observable interval: number = 0;

  @observable unit: string = types.minutes.value;

  constructor(interval, unit) {
    this.interval = interval;
    this.unit = unit;
  }

  @action.bound
  setInterval(interval) {
    if (interval > 0) {
      this.interval = interval;
    }
  }

  @action.bound
  setUnit(unit) {
    this.unit = unit;
  }

  validate = () => {
    const errors = [];
    if (this.unit === types.minutes.value && this.interval < 1) {
      errors.push('Минимальное время интервала 1 минута');
    }

    if (this.unit === types.days.value && this.interval > 400) {
      errors.push('Максимальное время интервала 400 дней');
    }
    return errors;
  };

  toJson() {
    return toJS({
      interval: this.interval,
      unit: this.unit
    });
  }

  static createDefault(): PlanEventIntervalParams {
    return new PlanEventIntervalParams(5, types.minutes.value);
  }

  static fromJson(params): PlanEventIntervalParams {
    return new PlanEventIntervalParams(params.interval, params.unit);
  }
}
