// @flow
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleRedirect = () => {
  const location = useLocation();

  useEffect(() => {
    const params = location.search;
    if (window.opener) {
      // отправляем параментры в родителя (вк-приложение)
      window.opener.postMessage(params);
    }
  });
  return <div>Закройте это окно, чтобы продолжить работу с smartbot</div>;
};

export default React.memo(GoogleRedirect);
