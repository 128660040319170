import type { ConditionType } from './condition';
import {
  convertConditionsToServerFormat,
  createEmptyConditions,
  normalizeCondition
} from './condition';
import generateId from 'utils/generateId';
import { REACTION_MODE } from './reactions';
import type { REACTION_TYPES } from './reactions';

export const CHAIN_TYPE = {
  FAQ: 'faq',
  BLOCKCHAIN: 'blockchain',
  REACTION: 'reaction'
};

export type ChainKindType = $Values<typeof CHAIN_TYPE>;

export const REALM = {
  GROUP: 'group',
  CHATS: 'chats',
  WALL: 'wall'
};

export const CONVERT_TYPE = {
  CHAIN: 'chain',
  COMPLEX: 'complex'
};

export type convertType = $Values<typeof CONVERT_TYPE>;

export type RealmType = $Values<typeof REALM>;

export type ChainExitConditionType = {
  by_text: {
    enabled: boolean,
    match: 'exact',
    value: string
  },
  by_time: {
    enabled: boolean,
    value: number,
    unit: string
  }
};

export type ChainType = {
  _id: string,
  adminsOnly: boolean,
  botId: string,
  dateCreated: Date,
  enabled: boolean,
  exitCondition: ChainExitConditionType,
  kind: CHAIN_TYPE.FAQ | CHAIN_TYPE.BLOCKCHAIN,
  name: string,
  startBlockId: string,
  realms: Array<REALM.CHATS | REALM.GROUP | REALM.WALL>,
  condition: ConditionType,
  mode?: REACTION_MODE.COMPLEX | REACTION_MODE.SIMPLE,
  reaction?: REACTION_TYPES
};

export function normalizeChain(chainData): ChainType {
  const {
    admins_only: adminsOnly,
    bot_id: botId,
    date_created: dateCreated,
    enabled,
    exit_condition: exitCondition,
    kind,
    name,
    realms,
    start_block_id: startBlockId,
    _id,
    mode,
    reaction
  } = chainData;

  const data = {
    _id,
    adminsOnly,
    dateCreated: new Date(dateCreated),
    botId,
    enabled,
    exitCondition,
    kind,
    name,
    realms,
    startBlockId,
    condition: normalizeCondition(chainData)
  };

  if (mode) {
    data.mode = mode;
  }

  if (reaction) {
    data.reaction = reaction;
  }

  return data;
}

export function normalizeChainsList(
  chains
): {
  chains: { [_id: string]: ChainType },
  chainsIds: Array<string>
} {
  return {
    chainsIds: chains.map(({ _id }) => _id),
    chains: chains.reduce((entities, chain) => {
      entities[chain._id] = normalizeChain(chain);
      return entities;
    }, {})
  };
}

export function createEmptyChain(kind, startBlockId, options = {}): ChainType {
  const id = generateId();
  return {
    _id: id,
    adminsOnly: false,
    enabled: true,
    exitCondition: {
      by_text: {
        enabled: false,
        match: 'exact',
        value: ''
      },
      by_time: {
        enabled: false,
        value: 5,
        unit: 'minutes'
      }
    },
    kind: kind || CHAIN_TYPE.FAQ,
    name: id,
    startBlockId: startBlockId,
    realms: [REALM.GROUP, REALM.CHATS],
    condition: createEmptyConditions(true),
    ...options
  };
}

export function convertChainToServerFormat(chain: ChainType) {
  const data = {
    _id: chain._id,
    admins_only: chain.adminsOnly,
    enabled: chain.enabled,
    exit_condition: chain.exitCondition,
    kind: chain.kind,
    name: chain.name,
    start_block_id: chain.startBlockId,
    realms: chain.realms,
    ...convertConditionsToServerFormat(chain.condition)
  };

  if (chain.mode) {
    data.mode = chain.mode;
  }

  if (chain.reaction) {
    data.reaction = chain.reaction;
  }

  return data;
}
