/* eslint-disable no-bitwise */
function generateId(
  a?: string // placeholder
): string {
  if (a) {
    const aNumber = Number(a);

    return (
      aNumber ^
      ((Math.random() * // in which case
        16) >> // a random number from
        (aNumber / 4))
    ) // 8 to 11
      .toString(16); // in hexadecimal
  }
  return ([1e10].toString() + 1e10 + 1e1)
    .replace(
      // replacing
      /[01]/g, // zeroes and ones with
      generateId // random hex digits
    )
    .toLowerCase();
}

export default generateId;
