// @flow
import React from 'react';
import { observer } from 'mobx-react';
import { Divider, Input } from 'antd';

import { Title, Error, NumberInput } from '../styles';

type Props = {
  title: string,
  value: string,
  onChange: (value: string) => void,
  isTextArea?: boolean,
  type?: string,
  errorMessage?: string
};

@observer
class EditInput extends React.Component<Props> {
  onChange = e => {
    if (this.props.type === 'number') {
      this.props.onChange(e);
    } else {
      this.props.onChange(e.target.value);
    }
  };

  render() {
    const {
      title,
      value,
      isTextArea,
      type,
      errorMessage,
      ...rest
    } = this.props;
    return (
      <div>
        <Divider>
          <Title>{title}</Title>
        </Divider>
        <>
          {isTextArea && (
            <Input.TextArea
              {...rest}
              autosize
              value={value}
              onChange={this.onChange}
            />
          )}
          {!isTextArea && (
            <>
              {type === 'number' ? (
                <NumberInput
                  {...rest}
                  type={type}
                  value={value}
                  onChange={this.onChange}
                />
              ) : (
                <Input
                  {...rest}
                  type={type}
                  value={value}
                  onChange={this.onChange}
                />
              )}
            </>
          )}
        </>
        {errorMessage && <Error>{errorMessage}</Error>}
      </div>
    );
  }
}

EditInput.defaultProps = {
  isTextArea: true,
  type: 'text',
  errorMessage: null
};

export default EditInput;
