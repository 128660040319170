import { observable, action, toJS } from 'mobx';

import { TransitionParamsModel } from 'models/Step/TransitionParams';
import { PlanEventIntervalParams } from 'models/Step/PlanEventIntervalParams';
import { PlanEventUntilParams } from 'models/Step/PlanEventUntilParams';
import planEventTypes from 'types/planEventTypes';

export default class PlanEventParamsModel {
  @observable name: string;

  // название события
  @observable mode: string = planEventTypes.interval.key;

  // тип: ко времени/через какое-то время
  @observable interval: PlanEventIntervalParams;

  // через какое-то время
  @observable until: PlanEventUntilParams;

  // ко времени
  @observable transition: TransitionParamsModel; // переход на блок

  constructor({
    name,
    mode = planEventTypes.interval.key,
    interval = PlanEventIntervalParams.createDefault(),
    until = PlanEventUntilParams.createDefault(),
    transition = TransitionParamsModel.createDefault()
  }) {
    this.name = name;
    this.interval = interval;
    this.until = until;
    this.transition = transition;
    this.mode = mode;
  }

  @action.bound
  setName(name) {
    this.name = name;
  }

  @action.bound
  changeMode(mode) {
    this.mode = mode;
  }

  validate = () => {
    // валидация перехода
    let errors = this.transition.validate();
    if (this.mode === planEventTypes.interval.key) {
      errors = [...errors, ...this.interval.validate()];
    } else {
      errors = [...errors, ...this.until.validate()];
    }

    return errors;
  };

  toJson() {
    const data = {
      name: this.name,
      transition: this.transition.toJson(),
      delay: {
        mode: this.mode
      }
    };

    if (this.mode === planEventTypes.interval.key) {
      data.delay = {
        ...data.delay,
        ...this.interval.toJson()
      };
    } else {
      data.delay = {
        ...this.until.toJson(),
        ...data.delay
      };
    }

    return toJS(data);
  }

  static createDefault(): PlanEventParamsModel {
    return new PlanEventParamsModel({
      mode: planEventTypes.interval.key,
      interval: PlanEventIntervalParams.createDefault(),
      until: PlanEventUntilParams.createDefault(),
      transition: TransitionParamsModel.createDefault()
    });
  }

  static fromJson(params): PlanEventParamsModel {
    const data = {
      name: params.name,
      mode: params.delay.mode,
      transition: TransitionParamsModel.fromJson(params.transition)
    };

    if (data.mode === planEventTypes.interval.key) {
      data.interval = PlanEventIntervalParams.fromJson(params.delay);
      data.until = PlanEventUntilParams.createDefault();
    } else {
      data.interval = PlanEventIntervalParams.createDefault();
      data.until = PlanEventUntilParams.fromJson(params.delay);
    }
    return new PlanEventParamsModel(data);
  }
}
