export const JOB = {
  NAME: {
    FETCH_GROUP_USERS: 'fetch_group_users',
    HANDLE_UNANSWERED: 'handle_unanswered'
  },

  STATUS: {
    STARTED: 'started',
    IN_PROGRESS: 'in_progress',
    FINISHED: 'finished',
    FAILED: 'failed',
    CANCELED: 'canceled'
  }
};
JOB.TITLES = {
  [JOB.NAME.FETCH_GROUP_USERS]: 'Обкачка аудитории',
  [JOB.NAME.HANDLE_UNANSWERED]: 'Обработка непрочитанных сообщений'
};

export type JobType = $Values<typeof JOB.NAME>;
export type JobStatus = $Values<typeof JOB.STATUS>;

export const HANDLE_UNANSWERED_PARAMS = {
  FILTER: {
    UNANSWERED: 'unanswered',
    UNREAD: 'unread'
  },
  ACTION: {
    MARK_READ: 'mark_read',
    HANDLE: 'handle'
  }
};

export type HandleUnansweredJobParams = {
  filter: $Values<typeof HANDLE_UNANSWERED_PARAMS.FILTER>,
  from_date: string,
  to_date: string,
  action: $Values<typeof HANDLE_UNANSWERED_PARAMS.ACTION>
};
