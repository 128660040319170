import styled from 'styled-components';
import { Cascader } from 'antd';
import { color } from 'styles/constants';

export const Selector = styled(Cascader)`
  width: 100%;
`;

export const CreateBlockLabel = styled.span`
  font-weight: bold;
`;

export const Error = styled.div`
  color: ${color.error};
  margin-top: 10px;
`;
