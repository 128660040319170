// @flow
import React from 'react';

import apiUrls from 'config/api';
import replaceTextToLink from 'utils/replaceTextToLink';

import defaultImg from './img/defaultImage.svg';
import { Title, Description, Wrapper, Image } from './styles';

type Props = {
  imageUrl: string,
  title: string,
  description: string,
};

const ImageElement = (props: Props) => {
  const { imageUrl, title, description } = props;
  const image = imageUrl ? `${apiUrls.filesGet}${imageUrl}` : defaultImg;
  return (
    <>
      <Image image={image} />
      <Wrapper>
        <Title>{replaceTextToLink(title)}</Title>
        <Description>{replaceTextToLink(description)}</Description>
      </Wrapper>
    </>
  );
};

export default ImageElement;
