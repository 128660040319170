import { action, observable, toJS } from 'mobx';

import { MODULE_ERROR } from '../types';

const COUNT_RECEIVER = 10;
const COUNT_SECRET = 24;

class YandexMoneyModuleParamsModel {
  @observable receiver: string = null;

  @observable notificationSecret: string = null;

  constructor({ receiver, notificationSecret }) {
    this.receiver = receiver;
    this.notificationSecret = notificationSecret;
  }

  @action.bound
  setReceiver(value) {
    this.receiver = value;
  }

  @action.bound
  setSecret(value) {
    this.notificationSecret = value;
  }

  validate() {
    if ((this.receiver || '').trim === '') {
      return MODULE_ERROR.YANDEX_MONEY_DEFAULT;
    }
    if (this.receiver.length <= COUNT_RECEIVER) {
      return MODULE_ERROR.YANDEX_MONEY;
    }
    if ((this.receiver || '').trim === '') {
      return MODULE_ERROR.YANDEX_MONEY_SECRET;
    }
    if (this.notificationSecret.length <= 0) {
      return MODULE_ERROR.YANDEX_MONEY_SECRET_COUNT;
    }
    return false;
  }

  toJson() {
    return toJS({
      receiver: this.receiver,
      notification_secret: this.notificationSecret
    });
  }

  static createDefault() {
    return new YandexMoneyModuleParamsModel({
      receiver: null,
      notificationSecret: null
    });
  }

  static fromJson(params): YandexMoneyModuleParamsModel {
    return new YandexMoneyModuleParamsModel({
      receiver: params.receiver,
      notificationSecret: params.notification_secret
    });
  }
}

export default YandexMoneyModuleParamsModel;
