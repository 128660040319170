import { observable, action, toJS } from 'mobx';

import {
  isCorrectGoogleDocsUrl,
  isCorrectGoogleDocsRange,
  googleDocsRangeRe
} from 'utils/validation';

import { validateSheetRange, validateSheetUrl } from './utils';

export const GoogleSheetsGetMode = {
  cell: 'cell',
  range: 'range'
};

export type GoogleSheetsGetModeType = $Values<typeof GoogleSheetsGetMode>;

// Считать из:
export const GoogleSheetsGetModeOptions = [
  {
    label: 'ячейки',
    value: GoogleSheetsGetMode.cell
  },
  {
    label: 'диапазона ячеек',
    value: GoogleSheetsGetMode.range
  }
];

export default class GoogleSheetsGetParamsModel {
  @observable mode: GoogleSheetsGetModeType;

  @observable sheetUrl = '';

  @observable list = '';

  @observable range = '';

  @observable target = '';

  constructor({ mode, sheetUrl, list, range, target }) {
    this.mode = mode;
    this.sheetUrl = sheetUrl;
    this.list = list;
    this.range = range;
    this.target = target;
  }

  @action.bound
  setUrl(sheetUrl) {
    this.sheetUrl = sheetUrl;
  }

  @action.bound
  setList(list) {
    this.list = list;
  }

  @action.bound
  setRange(range) {
    this.range = range;
  }

  @action.bound
  setMode(mode: GoogleSheetsGetModeType) {
    this.mode = mode;
  }

  @action.bound
  setTarget(target) {
    this.target = target;
  }

  validate() {
    const errors = [];

    errors.push(...validateSheetUrl(this.sheetUrl));
    errors.push(...validateSheetRange(this.range));

    if (!this.target) {
      errors.push('Выберите переменную для сохранения данных');
    }

    return errors;
  }

  toJson() {
    return toJS({
      spreadsheet_url: this.sheetUrl,
      target: this.target,
      mode: this.mode,
      range: this.range,
      list: this.list
    });
  }

  static createDefault(): GoogleSheetsGetParamsModel {
    return new GoogleSheetsGetParamsModel({
      mode: GoogleSheetsGetMode.cell,
      sheetUrl: '',
      list: '',
      range: 'A1',
      target: ''
    });
  }

  static fromJson(params): GoogleSheetsGetParamsModel {
    const data = {
      target: params.target,
      mode: params.mode,
      list: params.list,
      range: params.range,
      sheetUrl: params.spreadsheet_url
    };

    return new GoogleSheetsGetParamsModel(data);
  }
}
