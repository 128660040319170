// @flow
import * as React from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react';
import { Drawer } from 'antd';

import { Title } from './styles';

type Props = {
  title: string,
  children: React.Node,
  onClickClose: () => void,
  isOpened: boolean
};

@observer
class EditBar extends React.PureComponent<Props> {
  render() {
    const { title, children, onClickClose, isOpened } = this.props;
    return ReactDOM.createPortal(
      <Drawer
        title={<Title>{title}</Title>}
        placement="right"
        closable={false}
        onClose={onClickClose}
        visible={isOpened}
        getContainer={false}
        style={{ position: 'absolute' }}
        width="300px"
      >
        {children}
      </Drawer>,
      document.body
    );
  }
}

export default EditBar;
