import apiUrls from 'config/api';
import api from 'store/api';

export const SAVE_LISTS = 'lists/save';
export const CREATE_LIST = 'lists/create';
export const CREATE_LIST_ERROR = 'lists/create/error';
export const RENAME_LIST = 'lists/rename';
export const REMOVE_LIST = 'lists/remove';

export const getLists = () => async (dispatch) => {
  const { response } = await api(apiUrls.listsGet, 'GET', {
    // type: 'users'
  });
  if (response) {
    dispatch({
      type: SAVE_LISTS,
      payload: {
        lists: response.lists,
      },
    });
  }
};

export const createList = (name) => async (dispatch) => {
  const { response } = await api(
    apiUrls.listsCreate,
    'POST',
    {
      type: 'users',
      name,
    },
    {
      errorConfig: {
        isShowError: true,
        defaultMessage: 'Не удалось создать список',
      },
    }
  );

  if (response) {
    return dispatch({
      type: CREATE_LIST,
      payload: {
        list: response,
      },
    });
  }
  return dispatch({
    type: CREATE_LIST_ERROR,
  });
};

export const renameList = (listId, name) => async (dispatch) => {
  const { response } = await api(apiUrls.listsRename, 'POST', {
    list_id: listId,
    name,
  });
  if (response) {
    return dispatch({
      type: RENAME_LIST,
      payload: {
        listId,
        name,
      },
    });
  }
};

export const removeList = (listId) => async (dispatch) => {
  const { response } = await api(apiUrls.listsDelete, 'POST', {
    list_id: listId,
  });
  if (response) {
    return dispatch({
      type: REMOVE_LIST,
      payload: {
        listId,
      },
    });
  }
};

export const uploadUsersToList = async (
  listId,
  file,
  errorMessage = 'Не удалось сохранить подписчиков. Проверьте формат файла'
) => {
  const result = await api(
    apiUrls.usersUploadCSV,
    'POST',
    {
      list_id: listId,
      file,
    },
    {
      errorConfig: {
        isShowError: true,
        defaultMessage: errorMessage,
      },
    },
    true
  );

  return result;
};

export default {
  getLists,
  createList,
  renameList,
  removeList,
  uploadUsersToList,
  SAVE_LISTS,
  CREATE_LIST,
  CREATE_LIST_ERROR,
  RENAME_LIST,
  REMOVE_LIST,
};
