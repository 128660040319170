// @flow
import React from 'react';
import { observer } from 'mobx-react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Divider, Upload, Button } from 'antd';

import apiUrls from 'config/api';
import Spinner from 'components/Spinner';

import { Title } from '../styles';

import { ButtonDelete, Wrapper } from './styles';
import beforeUpload from './utils/beforeUpload';

type Props = {
  title: string,
  value: string,
  onChange: (value: string) => void,
  onRemove: () => void,
  placeholder?: string
};

@observer
class EditImage extends React.Component<Props> {
  state = {
    loading: false
  };

  onChange = async info => {
    this.setState({ loading: true });
    await this.props.onChange(info.file);
    this.setState({ loading: false });
  };

  render() {
    const { title, value, onRemove, placeholder } = this.props;
    const { loading } = this.state;
    const uploadButton = (
      <div>
        {loading ? <Spinner /> : <PlusOutlined />}
        <div className="ant-upload-text">Загрузить изображение</div>
      </div>
    );

    return (
      <div>
        <Divider>
          <Title>{title}</Title>
        </Divider>
        <Wrapper>
          <Upload
            accept=".png,.jpeg,.jpg,.svg"
            listType="picture-card"
            showUploadList={false}
            beforeUpload={beforeUpload}
            customRequest={this.onChange}
          >
            {value && !loading ? (
              <img
                src={`${apiUrls.filesGet}${value}`}
                alt="background"
                style={{ width: '100%' }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
          <ButtonDelete>
            <Button shape="round" icon={<DeleteOutlined />} onClick={onRemove} />
          </ButtonDelete>
        </Wrapper>
        <div>{placeholder}</div>
      </div>
    );
  }
}

EditImage.defaultProps = {
  placeholder:
    'Загрузите изображение. Максимальный размер файла - 10 Мб.' +
    ' Используйте следующие форматы изображений: .jpeg, .png, .svg.'
};

export default EditImage;
