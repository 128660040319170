export const root = '/';
export const addCommand = '/command';
export const editCommand = id => `${addCommand}/${id}`;
export const help = '/help';
export const settings = '/settings';
export const payments = '/payments';
export const vars = '/vars';
export const stats = '/stats';
export const users = '/users';
export const chains = '/chains';
export const chain = id => (id ? `/chain/${id}` : '/chain');
export const block = id => `/block/${id}`;
export const delivery = '/delivery/list';
export const createDelivery = id => (id ? `/delivery/${id}` : '/delivery');
export const reactions = '/reactions';
export const modules = '/modules';
export const module = kind => `/module/${kind}`;
export const reaction = (name, id) =>
  id ? `/reactions/${name}/${id}` : `/reactions/${name}`;
export const landings = '/landings';
export const landing = id => `/landings/${id}`;
export const landingView = id => `/landings/${id}/preview`;

export default {
  root,
  chain,
  chains,
  payments,
  addCommand,
  editCommand,
  help,
  settings,
  delivery,
  createDelivery,
  users,
  block,
  vars,
  reactions,
  reaction,
  modules,
  module,
  landings,
  landing,
  landingView
};
