import { observable, action, toJS } from 'mobx';
import moment from 'moment';

moment.locale('ru');

export class PlanEventUntilParams {
  @observable until: string = moment();

  constructor(until) {
    this.until = until;
  }

  @action.bound
  setUntil(until) {
    this.until = until;
  }

  validate = () => {
    const errors = [];
    if (this.until < moment()) {
      errors.push('Установите дату больше текущей');
    }
    return errors;
  };

  toJson() {
    return toJS({
      until: this.until
    });
  }

  static createDefault(): PlanEventUntilParams {
    return new PlanEventUntilParams(moment());
  }

  static fromJson(params): PlanEventUntilParams {
    return new PlanEventUntilParams(moment.utc(params.until).local());
  }
}
