export default class ExitParamsModel {
  toJson() {
    return {};
  }

  static createDefault(): ExitParamsModel {
    return new ExitParamsModel();
  }

  static fromJson(): ExitParamsModel {
    return new ExitParamsModel();
  }
}
