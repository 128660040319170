import { observable, action, toJS } from 'mobx';

// модель сообщений с аттачментом и текстом

export class MessageModel {
  @observable text: string = '';

  @observable attachments = [];

  constructor(text = '', attachments = []) {
    this.text = text;
    this.attachments = attachments;
  }

  @action
  handleChange({ text = null, attachments = null }) {
    if (text !== null && text !== undefined) {
      this.text = text;
    }

    if (attachments !== null && attachments !== undefined) {
      this.attachments = attachments;
    }
  }

  @action
  handleChangeAttachments = attachments => {
    this.attachments = attachments;
    return this.attachments;
  };

  @action
  handleChangeText = text => {
    this.text = text;
    return this.text;
  };

  @action
  validate = () => {
    if (
      (!this.text || this.text.trim() === '') &&
      (!this.attachments || this.attachments.length === 0)
    ) {
      return 'Не заполнено поле сообщения';
    }
    return '';
  };

  toJson() {
    return toJS({
      text: this.text,
      attachments: this.attachments.slice()
    });
  }

  static createDefault(): MessageModel {
    return new MessageModel('');
  }

  static fromJson(data): MessageModel {
    return new MessageModel(data.text, data.attachments);
  }
}
