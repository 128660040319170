// @flow
import React from 'react';
import styled from 'styled-components';
import { Icon } from '@ant-design/compatible';

import { STEP_ICON, STEP_TITLE } from 'types/step';
import CursorInfo from 'components/CursorInfo';
import type { STEP } from 'types/step';

const Wrapper = styled.div`
  position: relative;
`;

const Preview = styled.div`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`;

const StepPreviewIcon = styled(Icon)`
  margin-right: 4px;
`;

type Props = {
  index: number,
  kind: STEP,
  preview: string,
};

class StepTransitionPreview extends React.Component<Props> {
  state = {
    showInfo: false,
  };

  node = React.createRef();

  componentDidMount() {
    // Удаляем браузерный тултип(
    try {
      this.node.current.parentNode.removeAttribute('title');
    } catch (e) {}
  }

  get wrapperProps() {
    if (this.props.preview) {
      return {
        onMouseEnter: this.togglePreview,
        onMouseLeave: this.togglePreview,
      };
    }
    return {};
  }

  togglePreview = () => {
    this.setState((state) => ({
      showInfo: !state.showInfo,
    }));
  };

  render() {
    const { kind, index, preview } = this.props;

    const { showInfo } = this.state;
    let previewText = `Шаг №${index + 1}. ${STEP_TITLE[kind]}`;
    if (window.isMobile) {
      previewText = `${index + 1}. ${STEP_TITLE[kind]}`;
    }

    return (
      <Wrapper ref={this.node} {...this.wrapperProps}>
        <StepPreviewIcon type={STEP_ICON[kind]} /> {previewText}
        {showInfo && preview && (
          <CursorInfo>
            <Preview>{preview}</Preview>
          </CursorInfo>
        )}
      </Wrapper>
    );
  }
}

export default StepTransitionPreview;
