import React, { ComponentType, useContext } from 'react';
import { observable } from 'mobx';

import LandingStore from 'models/Landing/LandingModel';

import BlockStore from './BlockStore';
import ModuleStore from './ModuleStore';
import PlanEventStore from './PlanEventStore';
import UserStore from './UserStore';

class RootStore {
  @observable blockStore: BlockStore = null;

  @observable moduleStore: ModuleStore = null;

  @observable planEventStore: PlanEventStore = null;

  @observable landingStore: LandingStore | null = null;

  @observable userStore: UserStore | null = null;

  constructor() {
    this.blockStore = new BlockStore(this);
    this.moduleStore = new ModuleStore(this);
    this.planEventStore = new PlanEventStore(this);
    this.landingStore = new LandingStore({});
    this.userStore = new UserStore(this);
  }
}

export const RootStoreContext = React.createContext<RootStore>(null);

export type WithStoreProps = {
  store?: RootStore;
};

export const useRootStore = (): RootStore => useContext(RootStoreContext);

export function withStore<P>(
  Component: ComponentType<P & WithStoreProps>
): ComponentType<P> {
  return (props: P) => {
    return (
      <RootStoreContext.Consumer>
        {(store) => <Component {...props} store={store} />}
      </RootStoreContext.Consumer>
    );
  };
}

export type StoreProps = {
  store: RootStore;
};

export { RootStore };

export default new RootStore();
