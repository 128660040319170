import React, { useContext } from 'react';
import { action, computed, observable, toJS } from 'mobx';

import api from 'store/api';
import apiUrls from 'config/api';
import type {
  AutoProlongPrice,
  ModuleBalance,
  MODULES_STEPS,
  ModuleType,
} from 'types/modules';
import { MODULE_KIND } from 'types/modules';

import { MODULE_ERROR } from './types';
import getStateModelByKind from './ModuleState/utils/getStateModelByKind';
import getParamsModelByKind from './ModuleParams/utils/getParamsModelByKind';
import type { ModelStateModel } from './ModuleState';
import type { ModuleParamsModel } from './ModuleParams';
import { getWidgetPermissions } from './permissions';

class ModuleModel {
  rootStore = null;

  id: string;

  @observable enabled: boolean = false;

  @observable autoProlong: boolean = false;

  @observable balance: ModuleBalance = null;

  kind: MODULE_KIND = null;

  name: string = '';

  @observable installed: boolean = false;

  autoProlongPrice: AutoProlongPrice = null;

  prices: { [items: number]: number } = {};

  steps: Array<MODULES_STEPS> = [];

  description: string = '';

  @observable params: ModuleParamsModel = {};

  @observable state: ModelStateModel = null;

  @computed
  get isFree(): boolean {
    return !this.prices || Object.keys(this.prices).length === 0;
  }

  @computed
  get ignoreEnableSwitch() {
    return [MODULE_KIND.LIMITS].includes(this.kind);
  }

  @action
  setRootStore(rootStore) {
    this.rootStore = rootStore;
  }

  @action.bound
  async toggleModule() {
    if (!this.enabled && MODULE_KIND.GOOGLE_SHEETS === this.kind) {
      if (!this.state?.token) {
        return {
          result: false,
          error: MODULE_ERROR.TOKEN_ERROR,
        };
      }
    }

    if (
      !this.enabled &&
      [MODULE_KIND.YANDEX_MONEY, MODULE_KIND.WIDGET].indexOf(this.kind) > -1
    ) {
      const error = this.params.validate();
      if (error) {
        return {
          result: false,
          error: typeof error === 'boolean' ? MODULE_ERROR.DEFAULT : error,
        };
      }
    }

    this.changeEnabled(!this.enabled);
    const result = await this.upsertModule();

    if (!result.result) {
      this.changeEnabled(!this.enabled);
    }

    return result;
  }

  @action.bound
  async save() {
    const error = this.params.validate();
    if (error) {
      return {
        result: false,
        error,
      };
    }
    return this.upsertModule(false);
  }

  validatePermissions = async () => {
    if (this.kind === MODULE_KIND.WIDGET) {
      if (!(await getWidgetPermissions(this.rootStore.userStore))) {
        return {
          result: false,
          error: MODULE_ERROR.WIDGET_PERMISSIONS,
        };
      }
    }

    return {
      result: true,
    };
  };

  async upsertModule(canChangeInstall = true) {
    const permissionsState = await this.validatePermissions();

    if (!permissionsState.result) {
      return permissionsState;
    }

    const moduleData: {
      auto_prolong: boolean;
      enabled: boolean;
      kind: any;
      state?: any;
      params?: any;
    } = {
      auto_prolong: this.autoProlong,
      enabled: this.enabled,
      kind: this.kind,
    };

    if (this.state) {
      moduleData.state = this.state.toJson();
    }

    if (this.params) {
      moduleData.params = this.params.toJson
        ? this.params.toJson()
        : toJS(this.params);
    }

    const { response } = await api(apiUrls.moduleUpsert, 'POST', moduleData);

    if (response) {
      if (!this.installed && canChangeInstall) {
        this.installed = true;
      }
      return { result: true };
    }

    return {
      result: false,
      error: MODULE_ERROR.DEFAULT,
    };
  }

  @action.bound
  async selectPlan(amount) {
    if (!this.installed) {
      const response = await this.upsertModule();
      if (!response.result) {
        return response;
      }
    }

    const { response, errorData = {} } = await api(
      apiUrls.moduleProlong,
      'POST',
      {
        amount,
        kind: this.kind,
      }
    );

    if (response) {
      this.balance = response.balance;
      return { result: true };
    }
    if (errorData.reason === MODULE_ERROR.LOW_BALANCE) {
      return {
        result: false,
        error: MODULE_ERROR.LOW_BALANCE,
      };
    }
    return {
      result: false,
      error: MODULE_ERROR.DEFAULT,
    };
  }

  @action.bound
  async toggleAutoPayment() {
    this.autoProlong = !this.autoProlong;

    const resultUpsert = await this.upsertModule();
    const { result } = resultUpsert;

    if (!result) {
      this.autoProlong = !this.autoProlong;
    }

    return resultUpsert;
  }

  @action.bound
  changeEnabled(value: boolean) {
    this.enabled = value;
  }

  static async load(kind): Promise<ModuleModel> {
    const { response, error } = await api(apiUrls.moduleGet, 'GET', {
      kind,
    });

    if (response) {
      return ModuleModel.fromJson(response);
    }

    return null;
  }

  static fromJson(json: ModuleType): ModuleModel {
    const model = new ModuleModel();

    model.kind = json.kind;
    model.name = json.name;
    model.enabled = json.enabled;
    model.installed = json.installed;
    model.balance = json.balance;
    model.autoProlong = json.auto_prolong;
    model.autoProlongPrice = json.auto_prolong_price;
    model.prices = json.prices;
    model.steps = json.steps;
    model.description = json.description;

    const ParamsModel = getParamsModelByKind[json.kind];
    model.params = ParamsModel ? ParamsModel.fromJson(json.params) : {};

    const StateModel = getStateModelByKind[json.kind];
    model.state = StateModel ? StateModel.fromJson(json.state) : null;

    return model;
  }
}

const ModuleModelContext = React.createContext(null);

const useModuleModelContext = (): ModuleModel => useContext(ModuleModelContext);

export { ModuleModelContext, useModuleModelContext };

export default ModuleModel;
