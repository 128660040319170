import styled from 'styled-components';

import { globalSpacingStyle } from 'styles/constants';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .is-mobile & {
    display: block;
  }
`;

export const BlockSelectorWrapper = styled.div<{ full?: boolean }>`
  display: inline-block;
  vertical-align: middle;
  width: ${(props) => (props.full ? '100%' : '80%')};

  .is-mobile & {
    width: 100%;
  }
`;

export const EditBlockLinkWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 20%;

  .is-mobile & {
    text-align: left;
    width: 100%;
    display: block;
    margin: ${globalSpacingStyle.m} 0;
  }
`;
