import { observable, action, computed, toJS } from 'mobx';

import { VARS } from 'types';
import apiUrls from 'config/api';
import apiProxy from 'store/api';

import type { WidgetVKPreview } from './WidgetVKPreview';
import { WIDGET_COLUMN_ALIGN } from './WidgetVKPreview';

export const WIDGET_KIND = {
  TOP: 'top'
};

const VK_URL_PATTERN = /https:\/\/vk\.(com|me).*/;

export const WIDGET_MIN_ROWS_COUNT = 1;
export const WIDGET_MAX_ROWS_COUNT = 10;
const MAX_STR_LEN = 100;
const MAX_COLUMNS = 6;

const isEmpty = text => !text || !text.trim();

export class WidgetColumn {
  @observable title: string = 'Колонка';

  align: $Values<typeof WIDGET_COLUMN_ALIGN> = WIDGET_COLUMN_ALIGN.LEFT;

  @observable content: string = '';

  constructor({ title, align, content }) {
    this.title = title;
    this.align = align;
    this.content = content;
  }

  @action.bound
  setTitle(title: string): void {
    this.title = title.slice(0, MAX_STR_LEN);
  }

  @action.bound
  setContent(content: string): void {
    this.content = content.slice(0, MAX_STR_LEN);
  }

  validate(): string {
    if (isEmpty(this.title) || isEmpty(this.content)) {
      return 'Не заполнен заголовок или значение в колонке';
    }
    return '';
  }

  toJson() {
    return toJS({
      content: this.content,
      title: this.title,
      align: this.align
    });
  }

  static createDefault(): WidgetColumn {
    return new WidgetColumn({
      title: 'Название',
      align: WIDGET_COLUMN_ALIGN.CENTER,
      content: 'Переменная или текст'
    });
  }
}

export class WidgetFooter {
  @observable text: string = '';

  @observable url: string = '';

  constructor({ text, url }) {
    this.text = text;
    this.url = url;
  }

  @action.bound
  setText(text: string): void {
    this.text = text;
  }

  @action.bound
  setUrl(url: string): void {
    this.url = url;
  }

  validate(): string {
    if (isEmpty(this.text)) {
      return ['Не заполнен текст внизу виджета'];
    }

    if (!VK_URL_PATTERN.test(this.url)) {
      return [
        `Ссылка "${
          this.url
        }" должна быть в формате https://vk.me или https://vk.com`
      ];
    }

    return [];
  }

  toJson() {
    return toJS({
      text: this.text,
      url: this.url
    });
  }

  static createDefault(): WidgetFooter {
    return new WidgetFooter({
      text: 'Текст внизу виджета',
      url: 'Ссылка по клику'
    });
  }
}

export class WidgetModuleParams {
  @observable editMode = true;

  kind: $Values<typeof WIDGET_KIND> = WIDGET_KIND.TOP;

  @observable key: string;

  @observable chatId: number | null;

  @observable count: number;

  @observable title: string;

  titleUrl: string | null;

  @observable titleCounter: string | null;

  @observable columns: WidgetColumn[];

  showUrls: boolean;

  showIcons: boolean;

  @observable footer: WidgetFooter;

  isDefault: boolean = false;

  @observable errors = [];

  @observable isLoadingPreview = false;

  @observable isPreviewShown = false;

  preview: WidgetVKPreview = null;

  constructor({
    kind,
    key,
    chatId,
    count,
    title,
    titleUrl,
    titleCounter,
    columns,
    showUrls,
    showIcons,
    footer
  }) {
    this.kind = kind;
    this.key = key;
    this.chatId = chatId;
    this.count = count;
    this.title = title;
    this.titleUrl = titleUrl;
    this.titleCounter = titleCounter;
    this.columns = columns;
    this.showUrls = showUrls;
    this.showIcons = showIcons;
    this.footer = footer;
  }

  @computed
  get canAddColumn(): boolean {
    return this.columns.length < MAX_COLUMNS;
  }

  @action.bound
  setTitle(title: string): void {
    this.title = title.slice(0, MAX_STR_LEN);
  }

  @action.bound
  setTitleCounter(titleCounter: string): void {
    this.titleCounter = titleCounter;
  }

  @action.bound
  setKey(key: string): void {
    this.key = key;
    if (this.isDefault) {
      this.columns[1].setContent(key);
    }
  }

  @action.bound
  setCount(count: number): void {
    this.count =
      count >= WIDGET_MIN_ROWS_COUNT && count <= WIDGET_MAX_ROWS_COUNT
        ? count
        : this.count;
  }

  @action.bound
  setChatId(chatId: number): void {
    this.chatId = chatId;
  }

  @action.bound
  addColumn(): void {
    if (this.columns.length < MAX_COLUMNS) {
      this.columns.push(WidgetColumn.createDefault());
    }
  }

  @action.bound
  deleteColumn(index: number): void {
    this.columns.splice(index, 1);
  }

  @action.bound
  addFooter(): void {
    this.footer = WidgetFooter.createDefault();
  }

  @action.bound
  deleteFooter(): void {
    this.footer = null;
  }

  @action.bound
  showPreview(): void {
    this.editMode = false;
  }

  @action.bound
  closePreview(): void {
    this.editMode = true;
  }

  @action.bound
  validate(): boolean {
    const errors = [];

    if (!this.title || !this.title.trim()) {
      errors.push('Заголовок виджета не заполнен');
    }
    if (
      this.titleCounter &&
      !(
        this.titleCounter.indexOf('{{') > -1 ||
        this.titleCounter.indexOf('%') > -1 ||
        !Number.isNaN(Number(this.titleCounter))
      )
    ) {
      errors.push(
        'В числе заголовка виджета должно быть число, переменная или SQ-выражение'
      );
    }
    if (!this.key || !this.key.trim()) {
      errors.push('Переменная для сортировки не выбрана');
    }
    if (
      this.count < WIDGET_MIN_ROWS_COUNT ||
      this.count > WIDGET_MAX_ROWS_COUNT
    ) {
      errors.push(
        `Можно вывести от ${WIDGET_MIN_ROWS_COUNT} до ${WIDGET_MAX_ROWS_COUNT} строк`
      );
    }
    if (this.columns.length < 1) {
      errors.push('Нужно добавить хотя бы 1 колонку');
    }
    this.columns.forEach((col, i) => {
      const error = col.validate();
      if (error) {
        errors.push(`Колонка ${i}: ${error}`);
      }
    });

    if (this.footer) {
      errors.push(...this.footer.validate());
    }

    this.errors = errors;

    return Boolean(errors.length);
  }

  @action.bound
  async loadPreview(): Promise<{
    result: boolean,
    error?: string
  }> {
    if (this.validate()) {
      return;
    }

    this.isLoadingPreview = true;

    const { response, errorData } = await apiProxy(
      apiUrls.widgetsPreview,
      'POST',
      {
        params: this.toJson()
      }
    );

    this.isLoadingPreview = false;

    if (response) {
      this.preview = response;
      this.showPreview();
    }

    return {
      result: Boolean(response),
      error: errorData?.reason
    };
  }

  toJson() {
    return toJS({
      kind: this.kind,
      key: this.key,
      chat_id: this.chatId === null ? this.chatId : parseInt(this.chatId, 10),
      count: this.count,
      title: this.title,
      title_url: this.titleUrl,
      title_counter: this.titleCounter,
      columns: this.columns.map(c => c.toJson()),
      show_urls: this.showUrls,
      show_icons: this.showIcons,
      footer: this.footer ? this.footer.toJson() : null
    });
  }

  static createDefault(): WidgetModuleParams {
    const params = new WidgetModuleParams({
      kind: WIDGET_KIND.TOP,
      key: '',
      chatId: null,
      count: 5,
      title: 'Топ подписчиков',
      titleUrl: null,
      titleCounter: null,
      showIcons: true,
      showUrls: true,
      footer: null,
      columns: [
        new WidgetColumn({
          title: 'Подписчик',
          align: WIDGET_COLUMN_ALIGN.LEFT,
          content: `%top_pos%) ${VARS.NAME} ${VARS.SURNAME}`
        }),
        new WidgetColumn({
          title: 'Баллы',
          align: WIDGET_COLUMN_ALIGN.CENTER,
          content: 'Переменная с баллами'
        })
      ]
    });

    params.isDefault = true;

    return params;
  }

  static fromJson(json): WidgetModuleParams {
    if (!json || Object.keys(json).length === 0) {
      return WidgetModuleParams.createDefault();
    }

    const {
      kind,
      key,
      chat_id,
      count,
      title,
      title_url,
      title_counter,
      show_urls,
      show_icons,
      columns,
      footer
    } = json;

    return new WidgetModuleParams({
      kind,
      key,
      chatId: chat_id,
      count,
      title,
      titleUrl: title_url,
      titleCounter: title_counter,
      showUrls: show_urls,
      showIcons: show_icons,
      footer: footer ? new WidgetFooter(footer) : null,
      columns: columns?.map(col => new WidgetColumn(col))
    });
  }
}
