import { message } from 'antd';

export default file => {
  const isTrueType =
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/svg';
  if (!isTrueType) {
    message.error('Неверный формат изображения');
  }
  const isLt10M = file.size < 10 * 1024 * 1024;
  if (!isLt10M) {
    message.error('Изображение должно быть не более 10 Мб');
  }
  return isTrueType && isLt10M;
};
