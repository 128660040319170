import styled from 'styled-components';
import { Layout } from 'antd';
import { MessageOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { globalSpacingStyle, layoutSize, media, color } from 'styles/constants';

const paddingRight = '110px';

export const Wrapper = styled(Layout.Header)`
  border-bottom: 1px solid ${color.borderGray};
  display: flex;
  align-items: center;
  justify-content: space-between;

  .is-mobile & {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 3;

    padding: 0 ${paddingRight} 0 0;
  }

  .iOS & {
    height: auto !important;
    padding: ${layoutSize.iphoneXbar}px ${paddingRight} 0 0;

    ${media.iphoneHeight`
        padding: ${layoutSize.statusbar}px ${paddingRight} 0 0;
    `}
  }
`;

export const MessageIcon = styled(MessageOutlined)`
  font-size: 16px;
  line-height: 100%;
  vertical-align: middle;
`;

export const DocsIcon = styled(QuestionCircleOutlined)`
  font-size: 16px;
  line-height: 100%;
  vertical-align: middle;
`;

export const ExternalLink = styled.a`
  font-size: 12px;
  display: inline-block;
  float: right;
  margin-left: 9px;
`;

export const ToRight = styled.div`
  float: right;
  font-size: 12px;
  display: inline-block;
  margin-left: ${globalSpacingStyle.l};
`;

export const RightIcons = styled.div`
  display: flex;
  align-items: center;
`;
