/* eslint-disable import/export */
/* eslint-disable @typescript-eslint/ban-types */
function get<O extends object = {}, P1 extends keyof O = keyof O>(
  object: O,
  path1: P1
): O[P1];

function get<
  O extends object = {},
  P1 extends keyof O = keyof O,
  P2 extends keyof O[P1] = keyof O[P1]
>(object: O, path1: P1, path2: P2): O[P1][P2];

function get<
  O extends object = {},
  P1 extends keyof O = keyof O,
  P2 extends keyof O[P1] = keyof O[P1],
  P3 extends keyof O[P1][P2] = keyof O[P1][P2]
>(object: O, path1: P1, path2: P2, path3: P3): O[P1][P2][P3];

function get<O extends object = {}>(object: O, ...path: (keyof O)[]): any {
  return object
    ? path.reduce<any>((acc, value) => acc[value] || acc, object)
    : object;
}

export default get;
