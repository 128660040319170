// @flow
import React from 'react';
import { observer } from 'mobx-react';
import { message } from 'antd';

import ModalPay from 'components/ModalPay';
import apiUrls from 'config/api';
import replaceTextToLink from 'utils/replaceTextToLink';
import ProductItemModel from 'models/Landing/Elements/ProductItemModel';

import {
  Title,
  Description,
  ButtonWrapper,
  Wrapper,
  Image,
  Container,
  PriceWrapper,
  PriceNumber
} from '../styles';

import roundPrice from './utils/roundPrice';

type Props = {
  item: ProductItemModel,
  isEditMode: boolean,
  landingId: string | number
};

const ProductElement = ({ item, landingId, isEditMode }: Props) => {
  const { imageUrl, title, description, price } = item;

  const onClickPay = async () => {
    const result = await item.pay(landingId);
    if (!result.isError) {
      window.open(result.redirectUrl, '_blank');
      ModalPay({ href: result.redirectUrl });
    }
  };

  return (
    <Container>
      {imageUrl && (
        <Image src={`${apiUrls.filesGet}${imageUrl}`} alt="Product" />
      )}
      <Wrapper>
        <Title>{replaceTextToLink(title)}</Title>
        <Description>{replaceTextToLink(description)}</Description>
      </Wrapper>
      <PriceWrapper>
        <PriceNumber>{roundPrice(price)} ₽</PriceNumber>
        <ButtonWrapper
          type="primary"
          onClick={isEditMode ? () => {} : onClickPay}
        >
          {item.getPayButton().text}
        </ButtonWrapper>
      </PriceWrapper>
    </Container>
  );
};

export default observer(ProductElement);
