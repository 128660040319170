export const GET_LIST = 'GET_COMMANDS_LIST';
export const GET_LIST_ERROR = 'GET_COMMANDS_LIST_ERROR';

export const ADD_TO_LIST = 'ADD_COMMANDS_TO_LIST';
export const PREPEND_TO_LIST = 'PREPEND_COMMAND_TO_LIST';
export const SET_LIST = 'SET_COMMANDS_LIST';
export const DROP_LIST = 'DROP_LIST';

export const SAVE_COMMAND_ENTITIES = 'SAVE_COMMAND_ENTITIES';

export const DELETE_COMMAND = 'DELETE_COMMAND';
export const DROP_COMMAND = 'DROP_COMMAND';
export const DELETE_COMMAND_ERROR = 'DELETE_COMMAND_ERROR';

export const getCommands = (offset, limit = 20, search = null) => ({
  type: GET_LIST,
  offset,
  limit,
  search
});

export const dropCommands = () => ({
  type: DROP_LIST
});

export const prependCommandInList = commandId => ({
  type: PREPEND_TO_LIST,
  commandId
});

export const addCommandsToList = (commands, hasMore) => ({
  type: ADD_TO_LIST,
  commands,
  hasMore
});

export const setCommandsList = (commands, hasMore) => ({
  type: SET_LIST,
  commands,
  hasMore
});

export const saveCommandEntities = commands => ({
  type: SAVE_COMMAND_ENTITIES,
  commands
});

export const deleteCommand = _id => ({
  type: DELETE_COMMAND,
  _id
});

export const dropCommand = _id => ({
  type: DROP_COMMAND,
  _id
});

export default {
  getCommands,
  addCommandsToList,
  setCommandsList,
  prependCommandInList,
  saveCommandEntities,
  deleteCommand,
  dropCommand,
  dropCommands,

  GET_LIST,
  GET_LIST_ERROR,

  ADD_TO_LIST,
  SET_LIST,
  DROP_LIST,

  PREPEND_TO_LIST,

  SAVE_COMMAND_ENTITIES,

  DELETE_COMMAND,
  DELETE_COMMAND_ERROR,
  DROP_COMMAND
};
