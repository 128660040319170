import { action } from 'mobx';

import generateId from 'utils/generateId';
import type { STEP } from 'types';

import StepModel from '../Step/StepModel';

import StepsGroupModel from './StepsGroupModel';

export class StepPreviewModel {
  _id: string = generateId();

  kind: STEP = null;

  preview = '';

  constructor({ _id, kind, preview }) {
    this._id = _id;
    this.kind = kind;
    this.preview = preview;
  }

  static fromJson(data): StepPreviewModel {
    return new StepPreviewModel({
      _id: data._id,
      kind: data.kind,
      params: data.params,
      preview: data.preview,
    });
  }
}

export class StepsGroupPreviewModel {
  id: string = generateId();

  steps: Array<StepModel> = [];

  name = '';

  constructor({ id, name, steps }) {
    this.id = id;
    this.name = name;
    this.steps = steps;
  }

  searchStep(id: string) {
    return this.steps.find((step) => step._id === id);
  }

  static fromJson(data): StepsGroupPreviewModel {
    return new StepsGroupPreviewModel({
      id: data._id,
      name: data.name,
      steps: data.steps.map((step) => StepPreviewModel.fromJson(step)),
    });
  }
}

export class BlockPreviewModel {
  id: string = generateId();

  name = '';

  stepsGroups: Array<StepsGroupModel> = [];

  constructor({ id, name, stepsGroups }) {
    this.id = id;
    this.name = name;
    this.stepsGroups = stepsGroups;
  }

  searchStepsGroup(id: string) {
    return this.stepsGroups.find((stepGroup) => stepGroup.id === id);
  }

  static fromJson(data): BlockPreviewModel {
    return new BlockPreviewModel({
      id: data._id,
      name: data.name,
      stepsGroups: data.steps_groups.map((sg) =>
        StepsGroupPreviewModel.fromJson(sg)
      ),
    });
  }
}
