import { observable, action, toJS } from 'mobx';

import { STEP, STEP_MODULE } from 'types/step';
import generateId from 'utils/generateId';

import { SendMessageParamsModel } from './SendMessage';
import SleepParamsModel from './SleepParams';
import { TransitionParamsModel } from './TransitionParams';
import WaitParamsModel from './WaitParams';
import CallAdminParamsModel from './CallAdminParams';
import ChangeVarParamsModel from './ChangeVar';
import EventParamsModel from './EventParams';
import SendEmailParamsModel from './SendEmailParams';
import ListStepParamsModel from './ListStepParams';
import ParseParamsModel from './ParseParams';
import ExitParamsModel from './ExitParams';
import PlanEventParamsModel from './PlanEventParams';
import CancelPlanEventParamsModel from './CancelPlanEventParams';
import EmptyParamsModel from './EmptyParamsModel';
import GoogleSheetsAddParamsModel from './googleSheets/GoogleSheetsAddParams';
import ShowTopParamsModel from './ShowTopParams';
import SwitchContextParamsModel from './SwitchContextParams';
import KickFromChatParamsModel from './KickFromChatParams';
import ExecSqParamsModel from './ExecSqParamsModel';
import MarkAnsweredParams from './MarkAnsweredParams';
import GoogleSheetsGetParamsModel from './googleSheets/GoogleSheetsGetParams';
import GoogleSheetsSetParamsModel from './googleSheets/GoogleSheetsSetParams';
import HttpRequestParams from './HttpRequestParams';
import ShowSnackbarParams from './callbackButtons/ShowSnackbarParams';
import OpenLinkParams from './callbackButtons/OpenLinkParams';
import OpenAppParams from './callbackButtons/OpenAppParams';
import { ParamsModel } from './index';

class StepModel {
  @observable errors: Array<string> = [];

  _id: string = generateId();

  kind: STEP | STEP_MODULE = null;

  @observable params: ParamsModel = null;

  @observable preview = '';

  constructor(_id: string, kind: STEP | STEP_MODULE) {
    this._id = _id;
    this.kind = kind;
  }

  @action
  setErrors = (errors: string[]): void => {
    if (this.errors === [] && errors === []) {
      return;
    }
    this.errors = errors;
  };

  @action
  validate(needValidateLastStep = false): boolean {
    let result = true;

    const errors = [];
    if (this.params.validate) {
      errors.push(...this.params.validate());
    }

    if (needValidateLastStep && this.kind === STEP.WAIT) {
      errors.push('Шаг ждать не может быть последним');
    }

    result = errors.length === 0;
    this.setErrors(errors);

    return result;
  }

  getPreview(): string {
    return this.preview;
  }

  toJson() {
    return toJS({
      _id: this._id,
      kind: this.kind,
      params: this.params.toJson(),
    });
  }

  static createDefault(kind: STEP | STEP_MODULE): StepModel {
    const step = new StepModel(generateId(), kind);

    switch (kind) {
      case STEP.SEND_MESSAGE: {
        step.params = SendMessageParamsModel.createDefault();
        break;
      }
      case STEP.PRE_FILTER_BREAK: {
        step.params = new EmptyParamsModel(
          STEP.PRE_FILTER_BREAK,
          'Бот прекратит обработку текущего сообщения'
        );
        break;
      }
      case STEP.PRE_FILTER_CONTINUE: {
        step.params = new EmptyParamsModel(
          STEP.PRE_FILTER_CONTINUE,
          'Бот выйдет из реакции и начнёт поиск подходящего вопроса или цепочки'
        );
        break;
      }
      case STEP.SLEEP: {
        step.params = SleepParamsModel.createDefault();
        break;
      }
      case STEP.TRANSITION: {
        step.params = TransitionParamsModel.createDefault();
        break;
      }
      case STEP.WAIT: {
        step.params = WaitParamsModel.createDefault();
        break;
      }
      case STEP.CALL_ADMIN: {
        step.params = CallAdminParamsModel.createDefault();
        break;
      }
      case STEP.CHANGE_VAR: {
        step.params = ChangeVarParamsModel.createDefault();
        break;
      }
      case STEP.EVENT: {
        step.params = EventParamsModel.createDefault();
        break;
      }
      case STEP_MODULE.SEND_EMAIL: {
        step.params = SendEmailParamsModel.createDefault();
        break;
      }
      case STEP.ADD_TO_LIST:
      case STEP.REMOVE_FROM_LIST: {
        step.params = ListStepParamsModel.createDefault();
        break;
      }
      case STEP.PARSE: {
        step.params = ParseParamsModel.createDefault();
        break;
      }
      case STEP.SHOW_KEYBOARD: {
        step.kind = STEP.SEND_MESSAGE;
        step.params = SendMessageParamsModel.createDefaultWithKeyboard();
        break;
      }
      case STEP.EXIT_CHAIN: {
        step.params = ExitParamsModel.createDefault();
        break;
      }
      case STEP.PLAN_EVENT: {
        step.params = PlanEventParamsModel.createDefault();
        break;
      }
      case STEP.CANCEL_EVENT: {
        step.params = CancelPlanEventParamsModel.createDefault();
        break;
      }
      case STEP_MODULE.GOOGLE_SHEETS_ADD: {
        step.params = GoogleSheetsAddParamsModel.createDefault();
        break;
      }
      case STEP_MODULE.GOOGLE_SHEETS_GET: {
        step.params = GoogleSheetsGetParamsModel.createDefault();
        break;
      }
      case STEP_MODULE.GOOGLE_SHEETS_SET: {
        step.params = GoogleSheetsSetParamsModel.createDefault();
        break;
      }
      case STEP_MODULE.SWITCH_CONTEXT: {
        step.params = SwitchContextParamsModel.createDefault();
        break;
      }
      case STEP_MODULE.SHOW_TOP: {
        step.params = ShowTopParamsModel.createDefault();
        break;
      }
      case STEP_MODULE.KICK_FROM_CHAT: {
        step.params = KickFromChatParamsModel.createDefault();
        break;
      }
      case STEP.EXEC_SQ: {
        step.params = ExecSqParamsModel.createDefault();
        break;
      }
      case STEP.MARK_ANSWERED: {
        step.params = MarkAnsweredParams.createDefault();
        break;
      }
      case STEP_MODULE.HTTP_REQUEST: {
        step.params = HttpRequestParams.createDefault();
        break;
      }
      case STEP.EVENT_SHOW_SNACKBAR: {
        step.params = new ShowSnackbarParams();
        break;
      }
      case STEP.EVENT_OPEN_LINK: {
        step.params = new OpenLinkParams();
        break;
      }
      case STEP.EVENT_OPEN_APP: {
        step.params = new OpenAppParams();
        break;
      }
      default: {
        step.params = null;
      }
    }

    return step;
  }

  static fromJson(data) {
    const step = new StepModel(data._id, data.kind);

    step.preview = data.preview;

    if (!data.params) {
      step.params = null;
      return step;
    }

    switch (data.kind) {
      case STEP.SEND_MESSAGE: {
        step.params = SendMessageParamsModel.fromJson(data.params);
        break;
      }
      case STEP.PRE_FILTER_BREAK: {
        step.params = new EmptyParamsModel(
          STEP.PRE_FILTER_BREAK,
          'Бот прекратит обработку текущего сообщения'
        );
        break;
      }
      case STEP.PRE_FILTER_CONTINUE: {
        step.params = new EmptyParamsModel(
          STEP.PRE_FILTER_CONTINUE,
          'Бот выйдет из реакции и начнёт поиск подходящего вопроса или цепочки'
        );
        break;
      }
      case STEP.SLEEP: {
        step.params = SleepParamsModel.fromJson(data.params);
        break;
      }
      case STEP.TRANSITION: {
        step.params = TransitionParamsModel.fromJson(data.params);
        break;
      }
      case STEP.WAIT: {
        step.params = WaitParamsModel.fromJson(data.params);
        break;
      }
      case STEP.CALL_ADMIN: {
        step.params = CallAdminParamsModel.fromJson(data.params);
        break;
      }
      case STEP.CHANGE_VAR: {
        step.params = ChangeVarParamsModel.fromJson(data.params);
        break;
      }
      case STEP.EVENT: {
        step.params = EventParamsModel.fromJson(data.params);
        break;
      }
      case STEP_MODULE.SEND_EMAIL: {
        step.params = SendEmailParamsModel.fromJson(data.params);
        break;
      }
      case STEP.ADD_TO_LIST:
      case STEP.REMOVE_FROM_LIST: {
        step.params = ListStepParamsModel.fromJson(data.params);
        break;
      }
      case STEP.PARSE: {
        step.params = ParseParamsModel.fromJson(data.params);
        break;
      }
      case STEP.SHOW_KEYBOARD: {
        step.kind = STEP.SEND_MESSAGE;
        step.params = SendMessageParamsModel.fromJson(data.params);
        break;
      }
      case STEP.EXIT_CHAIN: {
        step.params = ExitParamsModel.fromJson();
        break;
      }
      case STEP.PLAN_EVENT: {
        step.params = PlanEventParamsModel.fromJson(data.params);
        break;
      }
      case STEP.CANCEL_EVENT: {
        step.params = CancelPlanEventParamsModel.fromJson(data.params);
        break;
      }
      case STEP_MODULE.GOOGLE_SHEETS_ADD: {
        step.params = GoogleSheetsAddParamsModel.fromJson(data.params);
        break;
      }
      case STEP_MODULE.GOOGLE_SHEETS_GET: {
        step.params = GoogleSheetsGetParamsModel.fromJson(data.params);
        break;
      }
      case STEP_MODULE.GOOGLE_SHEETS_SET: {
        step.params = GoogleSheetsSetParamsModel.fromJson(data.params);
        break;
      }
      case STEP_MODULE.SHOW_TOP: {
        step.params = ShowTopParamsModel.fromJson(data.params);
        break;
      }
      case STEP_MODULE.SWITCH_CONTEXT: {
        step.params = SwitchContextParamsModel.fromJson(data.params);
        break;
      }
      case STEP_MODULE.KICK_FROM_CHAT: {
        step.params = KickFromChatParamsModel.fromJson(data.params);
        break;
      }
      case STEP.EXEC_SQ: {
        step.params = ExecSqParamsModel.fromJson(data.params);
        break;
      }
      case STEP.MARK_ANSWERED: {
        step.params = MarkAnsweredParams.fromJson();
        break;
      }
      case STEP_MODULE.HTTP_REQUEST: {
        step.params = HttpRequestParams.fromJson(data.params);
        break;
      }
      case STEP.EVENT_SHOW_SNACKBAR: {
        step.params = new ShowSnackbarParams(data.params);
        break;
      }
      case STEP.EVENT_OPEN_LINK: {
        step.params = new OpenLinkParams(data.params);
        break;
      }
      case STEP.EVENT_OPEN_APP: {
        step.params = new OpenAppParams(data.params);
        break;
      }
      default: {
        step.params = data.params;
      }
    }

    return step;
  }
}

export default StepModel;
