import { call, put, takeLatest } from 'redux-saga/effects';
import api from 'store/api';
import { normalizeChainsList } from 'types/chain';
import apiUrls from 'config/api';
import commands from './actions';

function* getCommandsList({ offset, limit, search }) {
    const url = search && search !== '' ? apiUrls.chainsSearch : apiUrls.chainsList;

    if (offset === 0) {
        yield put({ type: commands.DROP_LIST });
    }

    const { response, error } = yield call(api, url, 'GET', {
        offset,
        limit,
        kind: 'faq',
        text: search
    });

    if (error) {
        yield put({ type: commands.GET_LIST_ERROR });
        return;
    }

    const { chainsIds, chains } = normalizeChainsList(response.results);

    const hasMore = response.results.length >= limit;

    yield put(commands.saveCommandEntities(chains));
    if (offset === 0) {
        yield put(commands.setCommandsList(chainsIds, hasMore));
    } else {
        yield put(commands.addCommandsToList(chainsIds, hasMore));
    }
}

function* deleteCommand({ _id }) {
    const { response, error } = yield call(api, apiUrls.chainsDelete, 'POST', { _id });
    if (error) {
        yield put({ type: commands.DELETE_COMMAND_ERROR });
        return;
    }
    yield put(commands.dropCommand(_id));
}

export default [
    takeLatest(commands.GET_LIST, getCommandsList),
    takeLatest(commands.DELETE_COMMAND, deleteCommand),
];
