import update from 'immutability-helper';

import settings from './actions';

export const initialState = {
    isLoading: false,
    isError: false,
    settings: null
};

const actionsMap = {
    [settings.SAVE_SETTINGS]: (state, { settings }) => update(state, {
        settings: { $set: settings },
        isLoading: { $set: false },
        isError: { $set: false }
    }),

    [settings.GET_SETTINGS]: (state, action) => update(state, {
        isLoading: { $set: true },
        isError: { $set: false }
    }),

    [settings.GET_SETTINGS_ERROR]: (state, action) => update(state, {
        isLoading: { $set: false },
        isError: { $set: true }
    })
};

export default (state = initialState, action = {}) => {
    const fn = actionsMap[action.type];
    return fn ? fn(state, action) : state;
}
