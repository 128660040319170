import * as Sentry from '@sentry/browser';
import { action, computed, observable, toJS } from 'mobx';
import { arrayMove } from 'react-sortable-hoc';

import { CHAIN_TYPE } from 'types/chain';
import { color as colorStyles } from 'styles/constants';
import generateId from 'utils/generateId';

import { TransitionParamsModel } from '../Step/TransitionParams';

export const MAX_BUTTONS = 40;
export const MAX_ROWS = 10;
export const MAX_BUTTONS_IN_ROW = 5;
export const MAX_INLINE_BUTTONS = 10;
export const MAX_INLINE_ROWS = 6;
export const MAX_BUTTON_TEXT_LEN = 40;

export enum KEYBOARD_ACTION_TYPE {
  TEXT = 'text',
  OPEN_LINK = 'open_link',
  LOCATION = 'location',
  VK_PAY = 'vkpay',
  // VK_APPS = 'vk_apps', // Не юзается, так как бэк сам переводит кнопку-ссылку в этот тип, если ссылка на приложение
  TRANSITION = 'transition',
  // CALLBACK = 'callback',
}

export const KEYBOARD_BUTTONS_AVAILABLE = {
  [CHAIN_TYPE.FAQ]: Object.values(KEYBOARD_ACTION_TYPE).filter(
    (t) =>
      ![
        KEYBOARD_ACTION_TYPE.TRANSITION,
        KEYBOARD_ACTION_TYPE.LOCATION,
      ].includes(t)
  ),
  [CHAIN_TYPE.BLOCKCHAIN]: Object.values(KEYBOARD_ACTION_TYPE),
  [CHAIN_TYPE.REACTION]: Object.values(KEYBOARD_ACTION_TYPE),
};

export const getAvailableButtonTypes = (blockKind) => {
  return (
    KEYBOARD_BUTTONS_AVAILABLE[blockKind] || Object.values(KEYBOARD_ACTION_TYPE)
  );
};

export const KEYBOARD_ACTION_TYPE_TITLE = {
  [KEYBOARD_ACTION_TYPE.TEXT]: 'Текстовая кнопка',
  [KEYBOARD_ACTION_TYPE.TRANSITION]: 'Кнопка-переход',
  [KEYBOARD_ACTION_TYPE.VK_PAY]: 'Кнопка оплаты VK Pay',
  [KEYBOARD_ACTION_TYPE.OPEN_LINK]: 'Кнопка-ссылка',
  [KEYBOARD_ACTION_TYPE.LOCATION]: 'Карта с координатами',
};

export enum KEYBOARD_BUTTON_COLOR {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  NEGATIVE = 'negative',
  POSITIVE = 'positive',
}

export const KEYBOARD_BUTTON_HEX_COLOR = {
  [KEYBOARD_BUTTON_COLOR.PRIMARY]: '#5181B8',
  [KEYBOARD_BUTTON_COLOR.SECONDARY]: colorStyles.vkLight,
  [KEYBOARD_BUTTON_COLOR.NEGATIVE]: '#E64646',
  [KEYBOARD_BUTTON_COLOR.POSITIVE]: '#4BB34B',
};

export enum KEYBOARD_ACTION_PAYLOAD_KIND {
  TRANSITION = 'transition',
}

export class KeyboardActionPayload {
  app = 'smartbot';

  v = 'v1';

  kind: string;

  @observable params: TransitionParamsModel;

  constructor({ v = 'v1', kind = null, params }) {
    this.v = v;
    this.kind = kind;
    this.params = params;
  }

  toJson() {
    const data = {
      app: this.app,
      v: this.v,
      kind: this.kind,
      params: null,
    };

    if (this.params) {
      data.params = this.params.toJson();
    }

    return toJS(data);
  }

  static fromJson(
    jsonStr,
    type: KEYBOARD_ACTION_TYPE
  ): KeyboardActionPayload | null {
    if (!jsonStr) {
      return null;
    }

    try {
      const json = JSON.parse(jsonStr);

      if (Object.keys(json).length === 0) {
        return null;
      }

      const data = {
        v: json.v,
        kind: json.kind,
        params: null,
      };

      if (json.params) {
        if (data.kind) {
          switch (data.kind) {
            case KEYBOARD_ACTION_PAYLOAD_KIND.TRANSITION: {
              data.params = TransitionParamsModel.fromJson(json.params);
              break;
            }
            default:
              break;
          }
        }
      }

      return new KeyboardActionPayload(data);
    } catch (e) {
      Sentry.captureException(e, { extra: jsonStr });
    }

    return null;
  }
}

export class KeyboardButtonActionModel {
  @observable type: KEYBOARD_ACTION_TYPE = KEYBOARD_ACTION_TYPE.TEXT;

  @observable label = '';

  @observable payload: KeyboardActionPayload;

  /*
  Для open_link
   */
  @observable link: string;

  /*
  Для vk_pay
   */
  @observable hash: string;

  @observable ownerId: number;

  @observable appId: number;

  constructor(data: {
    type: KEYBOARD_ACTION_TYPE;
    label: string;
    payload: any;
    link?: string;
    hash?: string;
    ownerId?: number;
    appId?: number;
  }) {
    const { type, label, payload, link, hash, ownerId, appId } = data;

    this.type = type;
    this.label = label;
    this.payload = payload;
    this.link = link;
    this.hash = hash;
    this.ownerId = ownerId;
    this.appId = appId;
  }

  @action
  handleChangeLabel = (label) => {
    this.label = label;
  };

  @action
  handleChangePayload = (payload: KeyboardActionPayload): void => {
    this.payload = payload;
  };

  toJson() {
    const data: {
      type: KEYBOARD_ACTION_TYPE;
      label?: string;
      payload?: any;
      link?: string;
      hash?: string;
      owner_id?: number;
      app_id?: number;
    } = {
      type: this.type,
    };

    if (this.type !== KEYBOARD_ACTION_TYPE.LOCATION) {
      data.label = this.label;
    }

    if (this.payload) {
      data.payload = JSON.stringify(this.payload.toJson());
    } else {
      data.payload = '{}';
    }

    if (this.link) {
      data.link = this.link;
    }
    if (this.hash) {
      data.hash = this.hash;
    }
    if (this.ownerId) {
      data.owner_id = this.ownerId;
    }
    if (this.appId) {
      data.app_id = this.appId;
    }

    if (KEYBOARD_ACTION_TYPE.TRANSITION === this.type) {
      data.type = KEYBOARD_ACTION_TYPE.TEXT;
    }

    return toJS(data);
  }

  static createDefault(
    type: KEYBOARD_ACTION_TYPE = KEYBOARD_ACTION_TYPE.TEXT
  ): KeyboardButtonActionModel {
    const data = {
      type,
      label: '',
      payload: null,
    };

    return new KeyboardButtonActionModel(data);
  }

  static fromJson(data): KeyboardButtonActionModel {
    const actionData = {
      type: data.type,
      label: data.label,
      payload: data.payload
        ? KeyboardActionPayload.fromJson(data.payload, data.type)
        : null,
      link: data.link,
      hash: data.hash,
      ownerId: data.owner_id,
      appId: data.app_id,
    };

    if (
      actionData.type === KEYBOARD_ACTION_TYPE.TEXT &&
      [KEYBOARD_ACTION_PAYLOAD_KIND.TRANSITION].includes(
        actionData.payload?.kind as KEYBOARD_ACTION_PAYLOAD_KIND
      )
    ) {
      actionData.type = actionData.payload?.kind;
    }

    return new KeyboardButtonActionModel(actionData);
  }
}

export class KeyboardButtonModel {
  id = generateId();

  action: KeyboardButtonActionModel;

  @observable color: KEYBOARD_BUTTON_COLOR;

  constructor({ action: actionData, color }) {
    this.action = actionData;
    this.color = color;
  }

  @action
  handleChangeColor = (color) => {
    this.color = color;
  };

  @action
  handleChangeAction = (actionModel: KeyboardButtonActionModel) => {
    this.action = actionModel;
  };

  validate() {
    if (
      this.action.type === KEYBOARD_ACTION_TYPE.TEXT &&
      this.action.label === ''
    ) {
      return 'Не заполнен текст на кнопке';
    }
    if (
      this.action.type === KEYBOARD_ACTION_TYPE.OPEN_LINK &&
      (this.action.label === '' || this.action.link === '')
    ) {
      return 'Не заполнен текст или ссылка на кнопке';
    }

    return true;
  }

  toJson() {
    const data = {
      action: this.action.toJson(),
      color: this.color,
    };

    if (![KEYBOARD_ACTION_TYPE.TEXT].includes(data.action.type)) {
      delete data.color;
    }

    return toJS(data);
  }

  static createDefault(
    type: KEYBOARD_ACTION_TYPE = KEYBOARD_ACTION_TYPE.TEXT
  ): KeyboardButtonModel {
    return new KeyboardButtonModel({
      action: KeyboardButtonActionModel.createDefault(type),
      color: KEYBOARD_BUTTON_COLOR.PRIMARY,
    });
  }

  static createFromAction(actionJson): KeyboardButtonModel {
    return new KeyboardButtonModel({
      action: new KeyboardButtonActionModel(actionJson),
      color: KEYBOARD_BUTTON_COLOR.PRIMARY,
    });
  }

  static fromJson(data): KeyboardButtonModel {
    return new KeyboardButtonModel({
      action: KeyboardButtonActionModel.fromJson(data.action),
      color: data.color,
    });
  }
}

export class KeyboardModel {
  // TODO пока что работает со старым компонентом, поэтому формат, как там
  @observable oneTime = true;

  /* Ряды! */
  @observable buttons: Array<Array<KeyboardButtonModel>> = [];

  @observable inline = false;

  @observable silent = false;

  @computed
  get totalButtons() {
    return this.buttons.map((row) => row.length).reduce((a, b) => a + b, 0);
  }

  @computed
  get maxButtonsCount() {
    return this.inline ? MAX_INLINE_BUTTONS : MAX_BUTTONS;
  }

  @computed
  get maxRowsCount() {
    return this.inline ? MAX_INLINE_ROWS : MAX_ROWS;
  }

  static get maxButtonsInRow() {
    return MAX_BUTTONS_IN_ROW;
  }

  @computed
  get hasVKPayButton() {
    return Boolean(
      this.buttons.find((row) =>
        Boolean(
          row.find(
            (button) => button.action.type === KEYBOARD_ACTION_TYPE.VK_PAY
          )
        )
      )
    );
  }

  @computed
  get availableButtonTypes() {
    let types = [
      KEYBOARD_ACTION_TYPE.TEXT,
      KEYBOARD_ACTION_TYPE.VK_PAY,
      KEYBOARD_ACTION_TYPE.TRANSITION,
      KEYBOARD_ACTION_TYPE.OPEN_LINK,
      KEYBOARD_ACTION_TYPE.LOCATION,
    ];

    if (this.hasVKPayButton) {
      types = types.filter((t) => t !== KEYBOARD_ACTION_TYPE.VK_PAY);
    }

    return types.map((type) => ({
      value: type,
      label: KEYBOARD_ACTION_TYPE_TITLE[type],
    }));
  }

  constructor({
    oneTime = true,
    buttons = [],
    inline = false,
    silent = false,
  }) {
    this.oneTime = oneTime;
    this.buttons = buttons;
    this.inline = inline;
    this.silent = silent;
  }

  @action.bound
  sortRows(oldPosition, newPosition) {
    this.buttons = arrayMove(this.buttons, oldPosition, newPosition);
  }

  @action
  toggleOneTime = () => {
    this.oneTime = !this.oneTime;
  };

  @action.bound
  toggleSilent() {
    this.silent = !this.silent;
  }

  @action
  toggleInline = () => {
    if (
      this.totalButtons > MAX_INLINE_BUTTONS ||
      this.buttons.length > MAX_INLINE_ROWS
    ) {
      return false;
    }

    this.inline = !this.inline;

    if (!this.inline) {
      this.silent = false;
    }

    return true;
  };

  @action
  handleChangeButtons = (buttons) => {
    this.buttons = buttons;
  };

  @action.bound
  sortButtonsInRow(rowId, oldPosition, newPosition) {
    this.buttons[rowId] = arrayMove(
      this.buttons[rowId],
      oldPosition,
      newPosition
    );
  }

  @action
  addRow = (type: KEYBOARD_ACTION_TYPE = KEYBOARD_ACTION_TYPE.TEXT) => {
    if (this.buttons.length < MAX_ROWS) {
      this.buttons.push([KeyboardButtonModel.createDefault(type)]);
    }
  };

  @action
  addCustomButton = (button: KeyboardButtonModel) => {
    if (this.buttons.length < MAX_ROWS) {
      this.buttons.push([button]);
    }
  };

  @action
  addButtonToRow = (row, button: KeyboardButtonModel = null) => {
    if (row < this.buttons.length) {
      this.buttons[row].push(
        button || KeyboardButtonModel.createDefault(KEYBOARD_ACTION_TYPE.TEXT)
      );
    }
  };

  @action
  removeButton = (rowIndex, posIndex) => {
    if (rowIndex < this.buttons.length) {
      const row = this.buttons[rowIndex];

      if (posIndex < row.length) {
        row.splice(posIndex, 1);

        if (row.length === 0) {
          this.buttons.splice(rowIndex, 1);
        }
      }
    }
  };

  validate = () => {
    if (this.totalButtons > this.maxButtonsCount) {
      return 'Превышено максимальное количество кнопок';
    }
    if (this.buttons.length > this.maxRowsCount) {
      return 'Превышено количество рядов кнопок';
    }

    let error = '';
    this.buttons.forEach((buttons) => {
      if (buttons.length > 2) {
        const isError = buttons.some(
          (button) => button.action.type === KEYBOARD_ACTION_TYPE.OPEN_LINK
        );
        if (isError) {
          error =
            'Ошибка! Если в ряду кнопок есть кнопка-ссылка, то' +
            ' количество кнопок в ряду не должно превышать двух';
        }
      }
    });

    return error;
  };

  toJson() {
    const data = {
      silent: this.silent,
      one_time: this.oneTime,
      inline: this.inline,
      buttons: this.buttons.map((row) => row.map((b) => b.toJson())),
    };

    if (data.inline) {
      delete data.one_time;
    }

    return toJS(data);
  }

  static createDefault(): KeyboardModel {
    return new KeyboardModel({ oneTime: true, buttons: [], inline: false });
  }

  static fromJson(data): KeyboardModel | null {
    if (!data) {
      return null;
    }
    return new KeyboardModel({
      inline: data.inline,
      oneTime: data.one_time,
      silent: data.silent,
      buttons: data.buttons.map((row) =>
        row.map((buttonJson) => KeyboardButtonModel.fromJson(buttonJson))
      ),
    });
  }
}
