// @flow
import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'antd';

import EditBlock from 'site/components/Chains/EditBlock';
import { withStore } from 'models/RootStore';

type Props = {
  isOpened: boolean,
  onClickCancel: () => void,
  blockId: string
};

@withStore
@observer
class ModalNewBlock extends React.Component<Props> {
  render() {
    const { isOpened, onClickCancel, blockId } = this.props;
    return (
      <Modal
        title="Редактирование блока"
        visible={isOpened}
        footer={null}
        width="90%"
        onCancel={onClickCancel}
      >
        <EditBlock blockId={blockId} />
      </Modal>
    );
  }
}

export default ModalNewBlock;
