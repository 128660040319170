import { observable, action, computed } from 'mobx';

import api from 'store/api';
import apiUrls from 'config/api';

export const parseEvents = (eventsToParse) =>
  eventsToParse.reduce((acc, next) => [...acc, next.name], []);

class PlanEventStore {
  rootStore = null;

  @observable isCreatingNew = false;

  @observable isLoading = false;

  @observable planEvents: Set<string> = new Set();

  @computed
  get planEventList() {
    return [...this.planEvents.keys()];
  }

  hasPlanEvent = (name): string | null => this.planEvents.has(name);

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action
  addPlanEvent(event: string) {
    this.planEvents.add(event);
  }

  @action
  async loadPlanEvents() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    const { response } = await api(apiUrls.plannedEventsList, 'GET');

    if (response) {
      const parsedEvents = parseEvents(response.events);
      parsedEvents.forEach((event) => this.planEvents.add(event));
    }
    this.isLoading = false;
  }

  @action
  setLoading = (isLoading) => {
    this.isLoading = isLoading;
  };

  validateCreation = (name) => {
    if (this.hasPlanEvent(name)) {
      return {
        isError: true,
        errorMessage: 'Запланированный переход с таким названием уже существует'
      };
    }
    if (name.length >= 128) {
      return {
        isError: true,
        errorMessage:
          'Имя события не может быть длиной более, чем 128 символов.',
      };
    }
    return {
      isError: false,
    };
  };

  @action
  createNewPlanEvent = async (name) => {
    const validate = this.validateCreation(name);
    if (validate.isError) {
      return validate;
    }

    this.isCreatingNew = true;

    const { response } = await api(
      apiUrls.plannedEventsAdd,
      'POST',
      {
        name,
      },
      {
        errorConfig: {
          isShowError: true,
          defaultMessage:
            'Ошибка при создании перехода. Перезагрузите страницу или попробуйте позже',
        },
      }
    );

    this.isCreatingNew = false;

    if (response) {
      this.planEvents.add(name);
      return {
        isError: false,
        name,
      };
    }

    return {
      isError: true,
    };
  };

  @action
  deletePlanEvent = async (name) => {
    const { response } = await api(apiUrls.plannedEventsRemove, 'POST', {
      name,
    });
    if (response) {
      this.planEvents.delete(name);
      return true;
    }
    return false;
  };
}

export default PlanEventStore;
