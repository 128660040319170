import { action, observable, toJS } from 'mobx';

import { urlRe } from 'utils/validation';

type CarouselElementActionModelType = 'open_link' | 'open_photo';

type CarouselElementActionModelData = {
  type: CarouselElementActionModelType;
  link?: string;
};

class CarouselElementActionModel {
  @observable link: string;

  @observable errorLink: string = null;

  constructor(data?: CarouselElementActionModelData) {
    if (data) {
      this.link = data.link;
    }
  }

  @action.bound
  changeLink(value: string): void {
    this.link = value;

    this.errorLink = null;
  }

  @action.bound
  validate(type: CarouselElementActionModelType): boolean {
    if (type === 'open_link') {
      const isCorrectLink = urlRe.test(this.link);

      if (!isCorrectLink) {
        this.errorLink =
          'Некорректная ссылка. Используйте формат: https://vk.com';
      }
      return isCorrectLink;
    }

    return true;
  }

  toJson = (type: CarouselElementActionModelType) => {
    const data: { link?: string } = {};

    if (type === 'open_link') {
      data.link = this.link;
    }

    return toJS(data);
  };
}

export { CarouselElementActionModelData, CarouselElementActionModelType };

export default CarouselElementActionModel;
