import {
  call,
  put,
  take,
  fork,
  cancel,
  cancelled,
  takeEvery
} from 'redux-saga/effects';
import { message } from 'antd';
import { delay } from 'redux-saga';

import api from 'store/api';
import apiUrls from 'config/api';
import type { JobType } from 'types/jobs';
import { JOB } from 'types/jobs';

import actions from './actions';
import type { JobFetchAction, JobStartAction } from './actions';

function* fetchJobData(jobName: JobType, withMessage = false) {
  const { response } = yield call(api, apiUrls.jobStatus, 'GET', {
    job_name: jobName
  });
  if (response) {
    switch (response.status) {
      case JOB.STATUS.FAILED:
        if (withMessage) {
          message.error(
            `${JOB.TITLES[jobName]} завершилась ошибкой, попробуйте позже`
          );
        }
        yield put(actions.finishJob(jobName, response.status));
        break;
      case JOB.STATUS.FINISHED:
        if (withMessage) {
          message.success(`${JOB.TITLES[jobName]} успешно завершена`);
        }
        yield put(actions.finishJob(jobName, response.status));
        break;
      case JOB.STATUS.STARTED:
        yield put(actions.jobSetInfo(jobName, JOB.STATUS.STARTED, 0));
        break;
      case JOB.STATUS.IN_PROGRESS:
        yield put(
          actions.jobSetInfo(jobName, JOB.STATUS.IN_PROGRESS, response.progress)
        );
        break;
      case JOB.STATUS.CANCELED:
        yield put(actions.jobSetInfo(jobName, JOB.STATUS.CANCELED, 0));
        break;
      default: {
        yield put(actions.jobSetInfo(jobName, null, 0));
      }
    }
  }
  return response;
}

function* getJobStatusTask(jobName: JobType) {
  try {
    while (true) {
      yield call(fetchJobData, jobName, true);
      yield call(delay, 5 * 1000);
    }
  } finally {
    if (yield cancelled()) {
      // eslint-disable-next-line no-unsafe-finally
      return null;
    }
  }
}

function* startBgFetchTask(jobName) {
  const bgTask = yield fork(getJobStatusTask, jobName);
  yield take(
    action =>
      action.type === actions.JOB_FINISHED && action.payload.jobName === jobName
  );
  yield cancel(bgTask);
}

function* startJob(action: JobStartAction) {
  const { jobName, params } = action.payload;

  const { response } = yield call(
    api,
    apiUrls.jobStart,
    'POST',
    {
      job_name: jobName,
      params
    },
    {
      errorConfig: {
        isShowError: true,
        defaultMessage: 'Не удалось начать задачу, попробуйте позже'
      }
    }
  );

  if (response) {
    yield put(actions.jobSetInfo(jobName, JOB.STATUS.STARTED, 0));
    yield call(startBgFetchTask, jobName);
  } else {
    yield put(actions.startJobError(jobName));
  }
}

function* cancelJob(action) {
  const { jobName } = action;
  const { response } = yield call(
    api,
    apiUrls.jobCancel,
    'POST',
    {
      job_name: jobName
    },
    {
      errorConfig: {
        isShowError: true,
        defaultMessage: 'Не удалось отменить задачу, попробуйте позже'
      }
    }
  );

  if (response) {
    yield put(actions.finishJob(jobName, response.status));
  }
}

function* fetchJobInitialData(action: JobFetchAction) {
  const { jobName } = action.payload;

  const response = yield call(fetchJobData, jobName);
  if (
    [JOB.STATUS.STARTED, JOB.STATUS.IN_PROGRESS].indexOf(response?.status) !==
    -1
  ) {
    yield call(startBgFetchTask, jobName);
  }
}

export default [
  takeEvery(actions.JOB_START, startJob),
  takeEvery(actions.JOB_FETCH, fetchJobInitialData),
  takeEvery(actions.JOB_CANCEL, cancelJob)
];
