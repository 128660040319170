import { observable } from 'mobx';

export default class Transition {
  @observable blockId = null;
  @observable stepGroupId = null;
  @observable stepId = null;

  constructor(blockId = null, stepGroupId = null, stepId = null) {
    this.blockId = blockId;
    this.stepGroupId = stepGroupId;
    this.stepId = stepId;
  }

  toJson() {
    return {
      block_id: this.blockId,
      step_group_id: this.stepGroupId,
      step_id: this.stepId
    };
  }

  static fromJson(data): Transition {
    return new Transition(data.block_id, data.step_group_id, data.step_id);
  }
}
