export const SET_USER = 'SET_USER';
export const GET_PAYMENT_INFO = 'GET_PAYMENT_INFO';
export const GET_PAYMENT_INFO_ONCE = 'GET_PAYMENT_INFO_ONCE';
export const STOP_GET_PAYMENT_INFO = 'STOP_GET_PAYMENT_INFO';
export const SET_PAYMENT_INFO = 'SET_PAYMENT_INFO';
export const SET_DELIVERY_FETCHED = 'SET_DELIVERY_FETCHED';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SET_PROGRESS = 'FETCH_USERS_SET_PROGRESS';

export const setUser = userInfo => ({
    type: SET_USER,
    userInfo
});

export const deliveryFetched = () => ({
    type: SET_DELIVERY_FETCHED
});

export const fetchUsers = () => ({
    type: FETCH_USERS
});

export const fetchUsersSetProgress = progress => ({
    type: FETCH_USERS_SET_PROGRESS,
    progress
});

export const setPaymentInfo = paymentInfo => ({
    type: SET_PAYMENT_INFO,
    paymentInfo
});

export const getPaymentInfo = action =>
         action === 'start'
           ? { type: GET_PAYMENT_INFO }
           : { type: STOP_GET_PAYMENT_INFO };

export const getPaymentInfoOnce = action => ({
    type: GET_PAYMENT_INFO_ONCE
});

export default {
    setUser,
    getPaymentInfo,
    setPaymentInfo,
    getPaymentInfoOnce,
    deliveryFetched,
    fetchUsers,
    fetchUsersSetProgress,

    SET_USER,
    GET_PAYMENT_INFO,
    GET_PAYMENT_INFO_ONCE,
    SET_PAYMENT_INFO,
    STOP_GET_PAYMENT_INFO,
    SET_DELIVERY_FETCHED,
    FETCH_USERS,
    FETCH_USERS_SET_PROGRESS
}
