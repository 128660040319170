import {
  css,
  CSSObject,
  FlattenSimpleInterpolation,
  SimpleInterpolation,
} from 'styled-components';

const globalSpacingStyle = {
  l: '20px',
  m: '10px',
  s: '5px',
};

const layoutSize = {
  header: 50,
  contentMargin: 24,
  contentMarginMobile: 10,
  statusbar: 20,
  iphoneXbar: 40,
  bannerSize: 80,
};

const fontSize = {
  normal: '12px',
  small: '10px',
  medium: '14px',
  large: '16px',
};

const color = {
  white: '#fff',
  primary: '#4a76a8',
  borderColor: '#6d91b5',
  borderGray: '#d9d9d9',
  red: 'red',
  error: '#cb0000',
  placeholder: '#7b7b7b',
  placeholderLight: '#BFBFBF',
  vkLight: '#e6ebf1',
  vkText: '#527caf',
  success: '#52c41a',
  vkGray200: '#cccccc',
  vkSteelGray500: '#6f7985',
};

const border = {
  radius: '4px',
};

const mediaSizes = {
  mobileWidth: '480px',
  iphoneHeight: '736px',
  planEventWidth: '850px',
  maxWidthLanding: '700px',
};

type IncludeMediaFunc = (
  first: CSSObject | TemplateStringsArray,
  ...interpolations: SimpleInterpolation[]
) => FlattenSimpleInterpolation;

const includeMedia =
  (param: string): IncludeMediaFunc =>
  (...args) =>
    css`
      @media (${param}) {
        ${css(...args)}
      }
    `;

const mediaHeight = (height: string): IncludeMediaFunc =>
  includeMedia(`max-height: ${height}`);
const mediaWidth = (width: string): IncludeMediaFunc =>
  includeMedia(`max-width: ${width}`);

const media = {
  iphoneHeight: mediaHeight(mediaSizes.iphoneHeight),
  mobile: mediaWidth(mediaSizes.mobileWidth),
};

const transition = (property: string, duration = 0.2): string =>
  `${property} ${duration}s ease-out`;

export {
  globalSpacingStyle,
  layoutSize,
  fontSize,
  color,
  border,
  mediaSizes,
  media,
  includeMedia,
  mediaHeight,
  mediaWidth,
  transition,
};
