import { call, put, takeLatest } from 'redux-saga/effects';
import api from 'store/api';
import apiUrls from 'config/api';
import settings from './actions';

function* getSettings() {
    const { response, error } = yield call(api, apiUrls.getSettings);
    if (error) {
        yield put({ type: settings.GET_SETTINGS_ERROR });
        return;
    }

    if (!response.timezone) {
        try {
            response.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            yield call(api, apiUrls.setSettings, 'POST', response);
        } catch(e) {}
    }
    yield put(settings.saveSettings(response));
}

export default [
    takeLatest(settings.GET_SETTINGS, getSettings),
];
