import React from 'react';
import { observer } from 'mobx-react';

import LandingModel from 'models/Landing/LandingModel';
import ProductItemModel, {
  MIN_YANDEX_PRICE
} from 'models/Landing/Elements/ProductItemModel';

import BaseEdit from '../../editor/BaseEdit';
import EditInput from '../../editor/EditInput';
import EditImage from '../../editor/EditImage';
import EditSelector from '../../editor/EditSelector';

import ProductElement from './ProductElement';

type Props = {
  item: ProductItemModel,
  landing: LandingModel,
  index: number,
  isEditMode?: boolean
};

const EditButtonProduct = ({ item, landing, index, isEditMode }: Props) => {
  const view = (
    <ProductElement
      landingId={landing.id}
      item={item}
      isEditMode={isEditMode}
    />
  );

  if (!isEditMode) {
    return view;
  }

  return (
    <BaseEdit
      index={index}
      landing={landing}
      item={item}
      view={view}
      editorComponent={
        <>
          <EditInput
            title="Заголовок"
            value={item.title}
            onChange={item.setTitle}
            errorMessage={item.errors.get('title')}
          />
          <EditInput
            title="Описание"
            value={item.description}
            onChange={item.setDescription}
            errorMessage={item.errors.get('description')}
          />
          <EditImage
            title="Изображение"
            value={item.imageUrl}
            onChange={value => item.uploadFile(value, landing.id)}
            onRemove={item.removeFile}
          />
          <EditInput
            title="Стоимость"
            value={item.price}
            onChange={item.setPrice}
            type="number"
            isTextArea={false}
            min={MIN_YANDEX_PRICE}
            errorMessage={item.errors.get('price')}
          />
          <EditInput
            onChange={item.getPayButton().setText}
            value={item.getPayButton().text}
            errorMessage={item.getPayButton().error}
            title="Текст на кнопке"
          />
          <EditSelector
            title="Запуск блока"
            value={item.blockId}
            onChange={item.setBlockId}
            placeholder="Выберите блок"
          />
          <div>Бот выполнит указанный блок после успешной оплаты</div>
        </>
      }
    />
  );
};

EditButtonProduct.defaultProps = {
  isEditMode: false
};

export default observer(EditButtonProduct);
