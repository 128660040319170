import * as React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Button, Divider, DropDownProps } from 'antd';

import { LANDING_ITEM } from 'types/moduleLanding';
import type { LandingItemKindType } from 'types/moduleLanding';

type Props = {
  onClickAdd: (type: LandingItemKindType) => void;
  dropdownRef?: DropDownProps['getPopupContainer'];
};

const AddElementBar = (props: Props) => {
  const onClickAdd = ({ key }) => {
    props.onClickAdd(key);
  };

  const menu = (
    <Menu onClick={onClickAdd}>
      {LANDING_ITEM.map((item) => (
        <Menu.Item key={item.value}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  const { dropdownRef } = props;

  return (
    <Divider>
      <Dropdown
        overlay={menu}
        trigger={['click']}
        getPopupContainer={dropdownRef}
      >
        <Button
          onClick={(e) => e.preventDefault()}
          icon={<PlusOutlined />}
          shape="round"
        />
      </Dropdown>
    </Divider>
  );
};

AddElementBar.defaultProps = {
  dropdownRef: () => document.body,
};

export default React.memo(AddElementBar);
