import update from 'immutability-helper';
import { without as _without } from 'lodash';

import commands from '../actions';

export const initialState = {
  isLoading: false,
  isError: false,
  items: [],
  hasMore: true
};

const actionsMap = {
  [commands.GET_LIST]: (state, action) =>
    update(state, {
      isLoading: { $set: true },
      isError: { $set: false }
    }),

  [commands.GET_LIST_ERROR]: (state, action) =>
    update(state, {
      isLoading: { $set: false },
      isError: { $set: true },
      hasMore: { $set: false }
    }),

  [commands.ADD_TO_LIST]: (state, { commands, hasMore }) =>
    update(state, {
      isLoading: { $set: false },
      isError: { $set: false },
      items: { $apply: current => [...current, ...commands] },
      hasMore: { $set: hasMore }
    }),

  [commands.SET_LIST]: (state, { commands, hasMore }) =>
    update(state, {
      isLoading: { $set: false },
      isError: { $set: false },
      items: { $set: commands },
      hasMore: { $set: hasMore }
    }),

  [commands.DROP_LIST]: (state, action) =>
    update(state, {
      items: { $set: [] },
      hasMore: { $set: true }
    }),

  [commands.PREPEND_TO_LIST]: (state, { commandId }) =>
    update(state, {
      items: { $apply: current => [commandId, ...current] }
    }),

  [commands.DROP_COMMAND]: (state, { _id }) =>
    update(state, {
      items: { $apply: current => _without(current, _id) }
    })
};

export default (state = initialState, action = {}) => {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
};
