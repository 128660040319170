import update from 'immutability-helper';

import actions from '../actions';

update.extend('$auto', function(value, object) {
  return object ? update(object, value) : update({}, value);
});

export const initialState = {
  isLoading: false,
  isLoaded: false,
  items: {},
  specials: []
};

const actionsMap = {
  [actions.DROP_ALL_VARS]: (state, action) =>
    update(state, {
      items: {
        $set: {}
      }
    }),

  [actions.SAVE_ENTITIES]: (state, { vars }) =>
    update(state, {
      items: { $merge: vars },
      isLoading: { $set: false }
    }),

  [actions.SAVE_SPECIAL_VARS]: (state, { vars }) =>
    update(state, {
      specials: { $set: vars }
    }),

  [actions.GET_LIST]: (state, action) =>
    update(state, {
      isLoading: { $set: true }
    }),

  [actions.GET_LIST_ERROR]: (state, action) =>
    update(state, {
      isLoading: { $set: false }
    }),

  [actions.GET_LIST_SUCCESS]: (state, action) =>
    update(state, {
      isLoading: { $set: false },
      isLoaded: { $set: true }
    }),

  [actions.DELETE_VAR]: (state, { _id }) =>
    update(state, {
      items: {
        [_id]: {
          isDeleting: { $set: true }
        }
      }
    }),

  [actions.DELETE_VAR_ERROR]: (state, { _id }) =>
    update(state, {
      items: {
        [_id]: {
          isDeleting: { $set: false }
        }
      }
    }),

  [actions.DROP_VAR]: (state, { _id }) =>
    update(state, {
      items: {
        $apply: items => {
          const updatedItems = { ...items };
          delete updatedItems[_id];
          return updatedItems;
        }
      }
    })
};

export default (state = initialState, action = {}) => {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
};
