import update from 'immutability-helper';

import user from './actions';

export const initialState = {
    isLoading: false,
    userInfo: null,
    paymentInfo: null,
    deliveryFetched: false,
    deliveryUsersFetching: false,
    deliveryUsersFetchingProgress: 0
};

const actionsMap = {
    [user.SET_USER]: (state, { userInfo }) => update(state, {
        userInfo: { $set: userInfo },
        isLoading: { $set: false }
    }),

    [user.SET_PAYMENT_INFO]: (state, { paymentInfo }) => update(state, {
        paymentInfo: { $set: paymentInfo },
    }),

    [user.SET_DELIVERY_FETCHED]: (state, action) => update(state, {
        deliveryFetched: { $set: true },
        deliveryUsersFetching: { $set: false }
    }),

    [user.FETCH_USERS]: (state, action) => update(state, {
        deliveryFetched: { $set: false },
        deliveryUsersFetching: { $set: true }
    }),

    [user.FETCH_USERS_SET_PROGRESS]: (state, { progress }) => update(state, {
        deliveryUsersFetchingProgress: { $set: progress }
    }),
};

export default (state = initialState, action = {}) => {
    const fn = actionsMap[action.type];
    return fn ? fn(state, action) : state;
}
