import React, { useCallback, useRef, useState } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  UpOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { observer } from 'mobx-react';

import LandingModel, { ITEM_MOVE } from 'models/Landing/LandingModel';
import type { LandingItemModel } from 'models/Landing/Elements/models';
import type { LandingItemKindType } from 'types/moduleLanding';

import AddElementBar from '../AddElementBar';
import EditBar from '../EditBar';
import {
  AddElementBarWrapper,
  ButtonWrapperImage,
  Container,
  ItemEditButton,
  ButtonWrapper,
} from '../styles';

type Props = {
  view: React.ReactNode;
  editorComponent: React.ReactNode;
  landing: LandingModel;
  item: LandingItemModel;
  index: number;
  canDelete?: boolean;
};

const BaseEdit = ({
  view,
  editorComponent,
  landing,
  item,
  index,
  canDelete,
}: Props) => {
  const container = useRef(null);
  const [isOpened, setIsOpened] = useState(false);

  const toggleEditor = useCallback(() => {
    if (isOpened && item.validate && !item.validate()) {
      return;
    }
    setIsOpened((s) => !s);
  }, [isOpened]);

  const getDropdownRef = useCallback(() => {
    return container.current;
  }, [container.current]);

  const handleDelete = useCallback(() => {
    landing.deleteItem(item.id);
  }, [landing, item]);

  const handleAdd = useCallback(
    (kind: LandingItemKindType) => {
      landing.addItem(kind, index);
    },
    [landing, index]
  );

  const handleMoveUp = useCallback(() => {
    landing.moveItem(item.id, ITEM_MOVE.UP);
  }, [landing, item.id]);

  const handleMoveDown = useCallback(() => {
    landing.moveItem(item.id, ITEM_MOVE.DOWN);
  }, [landing, item.id]);

  return (
    <Container ref={container}>
      <div onDoubleClick={toggleEditor}>{view}</div>
      {canDelete ? (
        <ButtonWrapper>
          {index > 0 && (
            <Tooltip title="Переместить выше">
              <Button
                size="small"
                shape="round"
                icon={<UpOutlined />}
                onClick={handleMoveUp}
              />
            </Tooltip>
          )}
          {index < landing.itemsCount - 1 && (
            <Tooltip title="Переместить ниже">
              <ItemEditButton
                size="small"
                shape="round"
                icon={<DownOutlined />}
                onClick={handleMoveDown}
              />
            </Tooltip>
          )}
          <ItemEditButton
            size="small"
            shape="round"
            icon={<EditOutlined />}
            onClick={toggleEditor}
          />
          <ItemEditButton
            size="small"
            shape="round"
            icon={<DeleteOutlined />}
            onClick={handleDelete}
          />
        </ButtonWrapper>
      ) : (
        <ButtonWrapperImage>
          <Button icon={<EditOutlined />} onClick={toggleEditor}>
            Редактировать
          </Button>
        </ButtonWrapperImage>
      )}
      {isOpened && (
        <EditBar
          title="Настройки"
          onClickClose={toggleEditor}
          isOpened={isOpened}
        >
          {editorComponent}
        </EditBar>
      )}
      <AddElementBarWrapper>
        <AddElementBar onClickAdd={handleAdd} dropdownRef={getDropdownRef} />
      </AddElementBarWrapper>
    </Container>
  );
};

BaseEdit.defaultProps = {
  canDelete: true,
};

export default observer(BaseEdit);
