import { createHashHistory } from 'history';

const history = createHashHistory();
history.listen((location, action) => {
  try {
    window.document.body.scrollTo(0, 0);
  } catch (e) {}
  // window.scrollTo(0, 0);
});
export default history;
