enum MODULE_ERROR {
  DEFAULT = 'default_error',
  LOW_BALANCE = 'low_balance',
  TOKEN_ERROR = 'token_error',
  YANDEX_MONEY_DEFAULT = 'yandex_money_default',
  YANDEX_MONEY = 'yandex_money',
  YANDEX_MONEY_SECRET = 'yandex_money_secret',
  YANDEX_MONEY_SECRET_COUNT = 'yandex_money_secret_count',
  WIDGET_PERMISSIONS = 'widget_permissions',
  WIDGET_NOT_INSTALLED = 'module_not_installed_or_not_paid',
  MARKET_PERMISSIONS = 'market_permissions',
}

const MODULE_ERROR_MESSAGES = {
  [MODULE_ERROR.DEFAULT]: 'Произошла ошибка',
  [MODULE_ERROR.TOKEN_ERROR]:
    'Перед включением модуля необходимо авторизоваться в Google Таблицах',
  [MODULE_ERROR.YANDEX_MONEY_DEFAULT]:
    'Перед включением модуля необходимо заполнить номер Яндекс Кошелька',
  [MODULE_ERROR.YANDEX_MONEY]:
    'Количество цифр в номере кошелька должно быть больше 10',
  [MODULE_ERROR.YANDEX_MONEY_SECRET]: 'Заполните поле секретный ключ',
  [MODULE_ERROR.YANDEX_MONEY_SECRET_COUNT]:
    'Секретный ключ не может быть пустым',
  [MODULE_ERROR.WIDGET_PERMISSIONS]: 'Необходимы права на виджет',
  [MODULE_ERROR.WIDGET_NOT_INSTALLED]: 'Модуль не включен или не оплачен',
  [MODULE_ERROR.MARKET_PERMISSIONS]: 'Необходимы права на товары ВК',
};

type ModuleErrorType = {
  errorType: MODULE_ERROR;
  errorMessage: string;
};

export { MODULE_ERROR, MODULE_ERROR_MESSAGES, ModuleErrorType };
