import React from 'react';

export default str => {
  const result = [];
  str.replace(
    /((?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim,
    (m, link, text) => {
      result.push(
        link ? (
          <a
            href={(link[0] === 'w' ? '//' : '') + link}
            key={result.length}
            target="_blank"
          >
            {link}
          </a>
        ) : (
          text
        )
      );
    }
  );
  return result;
};
