import { action, computed, observable } from 'mobx';
import { arrayMove } from 'react-sortable-hoc';

import TemplateModel, { TempalteModelData } from '../../TemplateModel';

import CarouselElementModel, {
  CarouselElementModelData,
} from './CarouselElementModel';
import { CarouselElementActionModelType } from './CarouselElementActionModel';

interface CarouselTemplateData extends TempalteModelData {
  elements?: CarouselElementModelData[];
  silent: boolean;
}

const MAX_COUNT_ELEMENTS = 10;

class CarouselTemplate extends TemplateModel {
  @observable elements: Map<string, CarouselElementModel> = new Map();

  @observable silent = false;

  @observable actionType: CarouselElementActionModelType = 'open_photo';

  @computed
  get enableAdd(): boolean {
    return this.elements.size < MAX_COUNT_ELEMENTS;
  }

  constructor(data?: CarouselTemplateData) {
    super({ type: 'carousel' });

    if (data?.elements.length) {
      this.actionType = data.elements?.[0]?.action.type || 'open_photo';
      this.silent = Boolean(data?.silent);

      data.elements.forEach((elementData) => {
        const newElement = new CarouselElementModel(elementData);
        this.elements.set(newElement.id, newElement);
      });
    }
  }

  @action.bound
  changeActionType(actionType: CarouselElementActionModelType): void {
    this.actionType = actionType;
  }

  @action.bound
  toggleSilent(): void {
    this.silent = !this.silent;
  }

  @action.bound
  addElement(): void {
    const newElement = new CarouselElementModel();
    this.elements.set(newElement.id, newElement);
  }

  @action.bound
  deleteElement(id: string): void {
    this.elements.delete(id);
  }

  @action.bound
  moveElement(oldPosition: number, newPosition: number): void {
    this.elements = new Map(
      arrayMove(Array.from(this.elements), oldPosition, newPosition)
    );
  }

  validate = () => {
    const errors = [];

    const { elementIndexesWithErorrs, isErrorCountButtons } = Array.from(
      this.elements.values()
    ).reduce<{
      elementIndexesWithErorrs: number[];
      countButtons: number;
      isErrorCountButtons: boolean;
    }>(
      (acc, element, i) => {
        if (!element.validate(this.actionType)) {
          acc.elementIndexesWithErorrs.push(i + 1);
        }

        if (acc.countButtons === null) {
          acc.countButtons = element.buttons.length;
        } else if (
          !acc.isErrorCountButtons &&
          acc.countButtons !== element.buttons.length
        ) {
          acc.isErrorCountButtons = true;
        }

        return acc;
      },
      {
        elementIndexesWithErorrs: [],
        countButtons: null,
        isErrorCountButtons: false,
      }
    );

    if (elementIndexesWithErorrs.length !== 0) {
      errors.push(
        `Ошибка в элементах карусели на позициях: ${elementIndexesWithErorrs.join(
          ', '
        )}`
      );
    }

    if (isErrorCountButtons) {
      errors.push(
        'Во всех элементах карусели должно быть одинаковое количество кнопок'
      );
    }

    return errors;
  };

  toJson = () => {
    const data: { type: string; elements: any[]; silent?: boolean } = {
      type: this.type,
      elements: Array.from(this.elements).map(([, element]) => {
        const dataElement = element.toJson(this.actionType);

        return {
          ...dataElement,
          action: { ...dataElement.action, type: this.actionType },
        };
      }),
    };

    if (this.silent) {
      data.silent = this.silent;
    }

    return data;
  };
}

export default CarouselTemplate;
