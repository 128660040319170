import { call, put, takeLatest } from 'redux-saga/effects';
import { normalize, schema } from 'normalizr';

import api from 'store/api';
import getter from 'utils/getter';
import apiUrls from 'config/api';

import actions from './actions';

const varEntity = new schema.Entity('vars', {}, { idAttribute: '_id' });

function* getVarsList({ offset, limit }) {
  const url = apiUrls.getVars;

  if (offset === 0) {
    yield put(actions.dropVars());
  }

  let res = yield call(api, url, 'GET', { offset, limit });
  if (res.error) {
    yield put({ type: actions.GET_LIST_ERROR });
    return;
  }

  const normalizedVars = normalize(res.response, { results: [varEntity] });
  yield put(
    actions.saveEntities(getter(normalizedVars, 'entities', 'vars') || {})
  );
  yield put({ type: actions.GET_LIST_SUCCESS });

  res = yield call(api, apiUrls.getSpecialVars, 'GET');
  if (res.response) {
    yield put(actions.saveSpecialVars(res.response.special_values));
  }
}

function* deleteVar({ _id }) {
  const { response, error } = yield call(api, apiUrls.deleteVar, 'POST', {
    _id
  });
  if (error) {
    yield put({ type: actions.DELETE_VAR_ERROR });
    return;
  }
  yield put(actions.dropVar(_id));
}

export default [
  takeLatest(actions.GET_LIST, getVarsList),
  takeLatest(actions.DELETE_VAR, deleteVar)
];
