import React from 'react';
import { Modal as ModalAntd, ModalProps } from 'antd';

interface Props extends Omit<ModalProps, 'visible' | 'centered' | 'onCancel'> {
  isOpen: boolean;
  onClose: ModalProps['onCancel'];
}

const Modal: React.FC<Props> = (props) => {
  const { isOpen, onClose, children, ...rest } = props;

  return (
    <ModalAntd centered visible={isOpen} onCancel={onClose} {...rest}>
      {children}
    </ModalAntd>
  );
};

Modal.defaultProps = {
  footer: null,
};

export default Modal;
