import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import LandingModel from 'models/Landing/LandingModel';
import { PageLoadableComponent } from 'components/PageLoader/PageLoader';
import { getHashParam, HASH_PARAMS } from 'types/hashParams';
import { useUserStore } from 'models/UserStore/context';

import App from './AppLoadable';
import NewLanding from './components/SmartbotLanding/NewLanding';
import LandingUserView from './components/LandingEdit/LandingUserView';

const Error = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  text-align: center;
  color: #4a76a8;
  white-space: pre-wrap;

  .is-mobile & {
    font-size: 16px;
    transform: translate(0, -50%);
    left: 0;
  }
`;

const getLandingId = () => {
  const landingId = getHashParam(HASH_PARAMS.landingId);

  // legacy для дефолтного лендинга
  return Number(landingId) === 1 ? undefined : landingId;
};

const SplashScreen: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showSmartbotLanding, setShowSmartbotLanding] = useState(false);
  const [showGroupLanding, setShowGroupLanding] = useState(false);
  const [showBotAdmin, setShowBotAdmin] = useState(false);

  const userStore = useUserStore();
  const { isError, errorMessage } = userStore;

  const handleLoginUser = useCallback(async () => {
    if (window.GROUP_ID) {
      const hasAdminAccess = window.IS_ADMIN || window.IS_DEV;
      // пробуем показать лендинг, созданный в смартботе
      if (getHashParam(HASH_PARAMS.landingId) || !hasAdminAccess) {
        // запрашиваем лендинг
        const landing = LandingModel.createDefault();
        const result = await landing.loadView(getLandingId());

        if (result) {
          setIsLoading(false);
          setShowSmartbotLanding(true);
          return;
        }
      }
      // пробуем показать админку
      if (hasAdminAccess) {
        const initSuccess = await userStore.initFlow();
        if (initSuccess) {
          setIsLoading(false);
          setShowBotAdmin(true);
          return;
        }
      }
    }

    setIsLoading(false);
    setShowGroupLanding(true);
  }, []);

  useEffect(() => {
    handleLoginUser();
  }, []);

  if (isError) {
    return <Error>{errorMessage}</Error>;
  }

  if (isLoading) {
    return <PageLoadableComponent />;
  }

  return (
    <>
      {showGroupLanding && <NewLanding />}
      {showSmartbotLanding && <LandingUserView id={getLandingId()} />}
      {showBotAdmin && <App />}
    </>
  );
};

export default observer(SplashScreen);
