import { action, observable, toJS } from 'mobx';

class ShowSnackbarParams {
  @observable text: string;

  constructor(params: { text: string } = { text: '' }) {
    this.text = params.text;
  }

  @action.bound
  changeText(value: string): void {
    this.text = value;
  }

  toJson = () => {
    return toJS({
      text: this.text
    });
  };

  validate = (): string[] => {
    const errors = [];

    if (this.text.length === 0) {
      errors.push('Не заполнено поле текста уведомления');
    }

    return errors;
  };
}

export default ShowSnackbarParams;
