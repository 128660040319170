import { observable, action, toJS } from 'mobx';

import { VARS } from 'types/vars';

export default class ListStepParamsModel {
  @observable listId: string = '';
  @observable value: string = VARS.USER_ID;

  constructor(listId = '', value = VARS.USER_ID) {
    this.listId = listId;
    this.value = value;
  }

  @action
  setValue = value => {
    this.value = value;
  };

  @action
  setListId = listId => {
    this.listId = listId;
  };

  validate = () => {
    const errors = [];
    if (!this.listId) {
      errors.push('Не выбран список');
    }
    return errors;
  };

  toJson() {
    return toJS({
      list_id: this.listId,
      value: this.value
    });
  }

  static createDefault(): ListStepParamsModel {
    return new ListStepParamsModel('', VARS.USER_ID);
  }

  static fromJson(params): ListStepParamsModel {
    return new ListStepParamsModel(params.list_id, params.value);
  }
}
