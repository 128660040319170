import React from 'react';
import { observer } from 'mobx-react';

import { withStore, WithStoreProps } from 'models/RootStore';
import BlockModel from 'models/Block';

import BlockSelector from './BlockSelector';
import EditBlockLink from './EditBlockLink';
import CreateModal from './CreateBlock/CreateModal';
import { BlockSelectorWrapper, EditBlockLinkWrapper, Wrapper } from './styles';

export type TransitionProps = {
  isCallback?: boolean;
  onSelect: (
    blockId: string,
    stepsGroupId?: string | null,
    stepId?: string | null
  ) => void;
  /* Показывать только блоки */
  onlyBlocks?: boolean;
  currentBlock?: BlockModel;
  blockId?: string;
  stepsGroupId?: string;
  stepId?: string;
  /* Скрывать ссылку на редактирование блока */
  hideBlockLink?: boolean;
  /* Показывать название "текущий блок" или имя текущего блока */
  showCurrent?: boolean;
};

@observer
class Transition extends React.PureComponent<
  TransitionProps & WithStoreProps,
  { showCreateModal: boolean }
> {
  static defaultProps = {
    onlyBlocks: false,
    currentBlock: null,
    blockId: null,
    stepsGroupId: null,
    stepId: null,
    hideBlockLink: false,
    showCurrent: true,
  };

  state = {
    showCreateModal: false,
  };

  toggleCreateModal = () =>
    this.setState((s) => ({
      showCreateModal: !s.showCreateModal,
    }));

  onCreateNewBlock = async (name) => {
    const { store, isCallback, onSelect } = this.props;
    const result = await store.blockStore.createNewBlock(name, isCallback);
    if (result) {
      onSelect(result.id);
      this.toggleCreateModal();
    }
  };

  render() {
    const {
      blockId,
      stepsGroupId,
      stepId,
      currentBlock,
      onlyBlocks,
      store,
      hideBlockLink,
      showCurrent,
      onSelect,
    } = this.props;

    const { showCreateModal } = this.state;

    return (
      <Wrapper>
        <CreateModal
          onCreate={this.onCreateNewBlock}
          isOpen={showCreateModal}
          isLoading={store.blockStore.isCreatingNewBlock}
          onClose={this.toggleCreateModal}
          placeholder="Введите название блока"
          description={
            <>
              Введите <b>название блока.</b>
              <br />
              Вы сможете <b>
                добавить логику в блок позже при редактировании.
              </b>{' '}
              <br />
              Обязательно не забудьте это сделать, иначе блок будет пустым.
            </>
          }
        />
        <BlockSelectorWrapper full={!blockId || hideBlockLink}>
          <BlockSelector
            showCurrent={showCurrent}
            onlyBlocks={onlyBlocks}
            currentBlock={currentBlock}
            blockId={blockId}
            stepsGroupId={stepsGroupId}
            stepId={stepId}
            onSelect={onSelect}
            onCreate={this.toggleCreateModal}
          />
        </BlockSelectorWrapper>
        {blockId && !hideBlockLink && (
          <EditBlockLinkWrapper>
            <EditBlockLink blockId={blockId} />
          </EditBlockLinkWrapper>
        )}
      </Wrapper>
    );
  }
}

export default withStore(Transition);
