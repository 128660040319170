export default class MarkAnsweredParams {
  // eslint-disable-next-line class-methods-use-this
  toJson() {
    return {};
  }

  static createDefault(): MarkAnsweredParams {
    return new MarkAnsweredParams();
  }

  static fromJson(): MarkAnsweredParams {
    return new MarkAnsweredParams();
  }
}
