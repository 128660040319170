import { observable, action, toJS } from 'mobx';

export default class SendEmailParamsModel {
  @observable subject: string = '';
  @observable text: string = '';
  @observable recipients: Array<string> = [];

  constructor(subject, text, recipients) {
    this.subject = subject;
    this.text = text;
    this.recipients = recipients;
  }

  validate = () => {
    const errors = [];
    if (!this.text || !this.text.trim()) {
      errors.push('Не заполнено поле сообщения');
    }
    if (!this.recipients || this.recipients.length === 0) {
      errors.push("Не выбраны email'ы для отправки");
    }
    return errors;
  };

  @action
  setSubject = subject => {
    this.subject = subject;
  };

  @action
  setText = text => {
    this.text = text;
  };

  @action
  setRecipients = recipients => {
    this.recipients = recipients;
  };

  @action
  addRecipient = recipient => {
    this.recipients.push(recipient);
  };

  @action
  removeRecipient = recipient => {
    this.recipients = this.recipients.filter(e => e !== recipient);
  };

  toJson() {
    return toJS({
      subject: this.subject,
      text: this.text,
      recipients: this.recipients.slice()
    });
  }

  static createDefault(): SendEmailParamsModel {
    return new SendEmailParamsModel(
      '[Smartbot] Новое письмо',
      'Пользователь %имя% %фамилия% %вк_польз% прислал Вам сообщение.\n' +
        'Ссылка на диалог: %чат%\n' +
        '#SmartBot',
      []
    );
  }

  static fromJson(params): SendEmailParamsModel {
    return new SendEmailParamsModel(
      params.subject,
      params.text,
      params.recipients
    );
  }
}
