import { observable, action, toJS } from 'mobx';

import CarouselTemplate from 'models/common/TemplateModel/templates/CarouselTemplate';

import { GeoFieldModel } from '../common/GeoFieldModel';
import { KeyboardModel } from '../common/KeyboardModel';
import { MessageModel } from '../common/MessageModel';
import { MessageParamsModel } from '../common/MessageParamsModel';

class SendMessageParamsModel extends MessageParamsModel<CarouselTemplate> {
  @observable messages: MessageModel[];
  @observable editLastMessage: boolean;
  @observable editThisMessage: boolean;

  mode: 'random' | string;

  constructor({
    messages,
    mode = 'random',
    keyboard = null,
    dontParseLinks = false,
    hidePrevKeyboard = false,
    geo = null,
    template = null,
    editLastMessage = false,
    editThisMessage = false,
  }: {
    messages: MessageModel[];
    mode?: 'random' | string;
    keyboard?: KeyboardModel;
    dontParseLinks?: boolean;
    hidePrevKeyboard?: boolean;
    geo?: GeoFieldModel;
    template?: CarouselTemplate;
    editLastMessage?: boolean;
    editThisMessage?: boolean;
  }) {
    super({
      keyboard,
      dontParseLinks,
      hidePrevKeyboard,
      geo,
    });
    this.messages = messages;
    this.mode = mode;
    this.template = template;
    this.editLastMessage = editLastMessage;
    this.editThisMessage = editThisMessage;
  }

  @action.bound
  toggleCarouselTemplate(): void {
    this.template = this.template
      ? null
      : new CarouselTemplate({
          type: 'carousel',
          elements: [
            {
              title: '',
              description: '',
              action: { type: 'open_photo', link: '' },
            },
          ],
        });
  }

  @action
  toggleEditLastMessage = () => {
    this.editLastMessage = !this.editLastMessage;
    if (!this.editLastMessage) {
      this.editThisMessage = false;
    }
  };

  @action
  toggleEditThisMessage = () => {
    this.editThisMessage = !this.editThisMessage;
    if (this.editThisMessage) {
      this.editLastMessage = true;
    }
  };

  @action
  addAlternativeMessage = () => {
    this.messages.push(MessageModel.createDefault());
  };

  @action
  deleteAlternativeAnswer = (index) => {
    this.messages.splice(index, 1);
  };

  validate() {
    const errors = [];

    this.messages.forEach((msg: MessageModel) => {
      const messageError = msg.validate();
      if (messageError) {
        errors.push(messageError);
      }
    });

    const templateErrors = this.template?.validate() || [];

    const msgErrors = super.validate();

    return [...errors, ...msgErrors, ...templateErrors];
  }

  toJson() {
    const data = {
      mode: this.mode,
      messages: this.messages.map((m) => m.toJson()),
      edit_last_message: this.editLastMessage,
      edit_this_message: this.editThisMessage,
      ...super.toJson(),
    };

    return toJS(data);
  }

  static createDefault(): SendMessageParamsModel {
    return new SendMessageParamsModel({
      messages: [MessageModel.createDefault()],
    });
  }

  static createDefaultWithKeyboard(): SendMessageParamsModel {
    return new SendMessageParamsModel({
      messages: [MessageModel.createDefault()],
      keyboard: KeyboardModel.createDefault(),
    });
  }

  static fromJson(params): SendMessageParamsModel {
    const { template, ...otherData } = MessageParamsModel.fromJson(params);

    return new SendMessageParamsModel({
      messages: params.messages.map((msg) => MessageModel.fromJson(msg)),
      mode: params.mode,
      ...otherData,
      template: template as CarouselTemplate,
      editLastMessage: params.edit_last_message,
      editThisMessage: params.edit_this_message,
    });
  }
}

export { SendMessageParamsModel };
