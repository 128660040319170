import ProductItemModel from 'models/Landing/Elements/ProductItemModel';
import SubscribeItemModel from 'models/Landing/Elements/SubscribeItemModel';
import BasicItemModel from 'models/Landing/Elements/BasicItemModel';
import { LANDING_ITEM_KIND } from 'types/moduleLanding';

export default (kind: string): SubscribeItemModel | ProductItemModel => {
  switch (kind) {
    case LANDING_ITEM_KIND.SUBSCRIPTION:
      return SubscribeItemModel.createDefault();
    case LANDING_ITEM_KIND.PRODUCT:
      return ProductItemModel.createDefault();
    case LANDING_ITEM_KIND.BASIC:
      return BasicItemModel.createDefault();
    default:
      return BasicItemModel.createDefault();
  }
};
