export const VK_SCOPE = {
  MESSAGES: 'messages',
  MANAGE: 'manage',
  DOCS: 'docs',
  WALL: 'wall',
  PHOTOS: 'photos',
  APP_WIDGET: 'app_widget',
  MARKET: 'market'
};

export const constructScope = (...permissions) => permissions.join(',');

export const BASIC_SCOPE = [
  VK_SCOPE.MESSAGES,
  VK_SCOPE.MANAGE,
  VK_SCOPE.PHOTOS,
  VK_SCOPE.DOCS,
  VK_SCOPE.WALL
];

export const validateScope = (
  required: string[],
  scope: string[] = window.scope
) => {
  const currentSet = new Set(scope);

  return required.every(rs => currentSet.has(rs));
};
