import React from 'react';
import { Link } from 'react-router-dom';

import { ModelStateModelFromJsonData } from 'models/Module/ModuleState';
import { ModuleParamsModel } from 'models/Module/ModuleParams';

import urls from '../config/urls';
import Beta from '../components/Icons/BetaIcon';

type ModuleBalance = {
  kind: 'items' | 'date' | string;
  value: number;
};

type AutoProlongPrice = {
  price: number;
  amount: number;
};

enum MODULES_STEPS {
  EMAIL = 'send_email',
}

enum MODULE_KIND {
  EMAIL = 'email',
  REF_SOURCE = 'ref_source',
  GAMIFICATION = 'gamification',
  GOOGLE_SHEETS = 'google_sheets',
  LANDINGS = 'landings',
  YANDEX_MONEY = 'yam',
  HTTP_API = 'http_requests',
  WIDGET = 'widget',
  MARKET = 'market',
  LIMITS = 'limits',
}

const REF_URL =
  'https://vk.com/dev/bizmessages_doc?f=2.6.%20%CF%E5%F0%E5%E4%E0%F7%E0%20%EF%F0%EE%E8%E7%E2%EE%EB%FC%ED%EE%E3%EE%20%EF%E0%F0%E0%EC%E5%F2%F0%E0%20%F1%20%EF%EE%EC%EE%F9%FC%FE%20%F1%F1%FB%EB%EA%E8%20vk.me';

const MODULE_GUIDE = {
  [MODULE_KIND.EMAIL]:
    'Включите модуль и приобретите пакет писем. После этого в настройках бота будет доступен шаг "отправить email".',
  [MODULE_KIND.REF_SOURCE]: (
    <>
      Приобретите и включите модуль. После этого в пользователях будет доступна
      информация о ref и ref_source.
      <br />
      <a href={REF_URL} target="_blank" rel="noopener noreferrer">
        Подробнее
      </a>{' '}
      о ref и ref_source.
    </>
  ),
  [MODULE_KIND.GAMIFICATION]: (
    <div>
      Модуль добавляет полезные возможности для игровых ботов. После включения
      появятся 3 новых шага:
      <br />
      1) вывести отсортированный топ по выбранной переменной <br />
      2) исключение выбранного пользователя из беседы <br />
      3) смена контекста выполнения бота. Шаг позволит переключить выполнение на
      указанного пользователя. Это может быть полезно, например, для изменения
      переменных другого пользователя.
    </div>
  ),
  [MODULE_KIND.GOOGLE_SHEETS]: (
    <p>
      Модуль добавляет возможность добавлять записи в гугл-таблицу. Для работы
      модуля необходимо получить токен (кнопка ниже), затем в цепочке добавить
      шаг &ldquo;Google Таблицы -&gt; Добавить запись&rdquo;
    </p>
  ),
  [MODULE_KIND.LANDINGS]: <>Модуль добавляет возможность создавать лендинги</>,
  [MODULE_KIND.YANDEX_MONEY]: (
    <>
      Модуль даёт возможность подключить к боту ЮMoney для оплаты{' '}
      <Link to={urls.landings}>на лендингах</Link>.
    </>
  ),
  [MODULE_KIND.HTTP_API]: (
    <div>
      Модуль добавляет возможность выполнять HTTP-запросы из бота. Это
      необходимо для интеграций со сторонними сервисами.
    </div>
  ),
  [MODULE_KIND.WIDGET]: (
    <div>
      Модуль добавляет отображения виджета в группе. В виджете можно отобразить
      пользователей с сортировкой по определенной переменной
    </div>
  ),
  [MODULE_KIND.MARKET]: (
    <div>
      Модуль даёт возможность настроить реакции на покупки через{' '}
      <a href="https://vk.com/dev/goods" target="_blank">
        товары ВК
      </a>
    </div>
  ),
  [MODULE_KIND.LIMITS]: (
    <div>
      Модуль позволяет изменить ограничения приложения.
      <br />
      Например, вы&nbsp;можете ускорить рассылки, увеличить размеры переменных
      или количество цепочек или вопросов.
    </div>
  ),
};

const moduleKindToPacketUnits = {
  [MODULE_KIND.EMAIL]: 'писем',
  [MODULE_KIND.REF_SOURCE]: 'дней',
  [MODULE_KIND.GAMIFICATION]: 'дней',
  [MODULE_KIND.GOOGLE_SHEETS]: 'дней',
  [MODULE_KIND.LANDINGS]: 'дней',
  [MODULE_KIND.HTTP_API]: 'запросов',
  [MODULE_KIND.WIDGET]: 'дней',
  [MODULE_KIND.MARKET]: 'дней',
};

type ModuleType = {
  kind: MODULE_KIND;
  name: string;
  enabled: boolean;
  installed: boolean;
  balance: ModuleBalance;
  auto_prolong: boolean;
  auto_prolong_price: AutoProlongPrice;
  prices: { [items: number]: number };
  steps: Array<MODULES_STEPS>;
  params: ModuleParamsModel;
  description: string;
  state: ModelStateModelFromJsonData;
};

export {
  MODULE_GUIDE,
  moduleKindToPacketUnits,
  ModuleBalance,
  AutoProlongPrice,
  MODULES_STEPS,
  MODULE_KIND,
  ModuleType
};
