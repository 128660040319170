import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import BaseSelector from 'components/Selector';
import CreateListModal from './CreateListModal';

const Selector = styled(BaseSelector)`
  width: 100%;
`;

const CREATE_LIST = 'LIST_SELECTOR_CREATE_LIST';
const ALL = 'LIST_SELECTOR_ALL';

export default ({
  selectedList,
  onSelect,
  canBeNull = false,
  canSelectAll = true
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const lists = useSelector(state => state.lists.lists);

  const toggleCreateModal = useCallback(() => {
    setShowCreateModal(s => !s);
  });

  const onChange = useCallback(
    value => {
      if (value === CREATE_LIST) {
        toggleCreateModal();
      } else {
        onSelect(value === ALL ? null : value);
      }
    },
    [onSelect]
  );

  const onCreate = useCallback(
    list => {
      onSelect(list._id);
    },
    [onSelect]
  );

  const auditories = [
    {
      label: <b>+ Создать новый список</b>,
      value: CREATE_LIST
    },
    canSelectAll && {
      label: 'Все',
      value: ALL
    },
    ...lists.map(({ _id, name }) => ({
      label: name,
      value: _id
    }))
  ].filter(a => Boolean(a));

  return (
    <>
      {showCreateModal && (
        <CreateListModal
          onCreate={onCreate}
          isOpen={showCreateModal}
          onClose={toggleCreateModal}
        />
      )}
      <Selector
        value={selectedList ? selectedList : canBeNull ? '' : ALL}
        placeholder="Выберите список"
        onChange={onChange}
        notFoundContent="Списков не найдено"
        options={auditories}
      />
    </>
  );
};
