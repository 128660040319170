import initVk from 'utils/initVk';
import { VK_SCOPE, validateScope } from 'config/permissions';

import UserStore from '../UserStore';

export const getPermissions = async (scope, userStore: UserStore) => {
  if (validateScope([scope])) {
    return true;
  }

  const newScope = [...window.scope, scope];

  const hasPermissions = await initVk({
    scope: newScope
  });

  if (!hasPermissions) {
    return;
  }

  return userStore.setToken(newScope);
};

export const getWidgetPermissions = (userStore: UserStore) =>
  getPermissions(VK_SCOPE.APP_WIDGET, userStore);
