import React, { Suspense } from 'react';
import styled from 'styled-components';
import Spinner from 'components/Spinner';
import spinner from './spinner.gif';

const Loader = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SpinnerGif = styled.div`
  background: url(${spinner}) no-repeat center;
  background-size: contain;
  width: 28px;
  height: 28px;
`;

export const PageLoadableComponent = props => {
  return props.pastDelay ? (
    <Loader>
      <Spinner />
      <div>ЗАГРУЗКА</div>
    </Loader>
  ) : null;
};

export const PageLoaderSuspense = ({ children }) => (
  <Suspense
    fallback={
      <Loader>
        <Spinner />
        <div>ЗАГРУЗКА</div>
      </Loader>
    }
  >
    {children}
  </Suspense>
);

export default Component => {
  class PageLoader extends React.Component {
    render() {
      return (
        <PageLoaderSuspense>
          <Component {...this.props} />
        </PageLoaderSuspense>
      );
    }
  }

  return Component;
};
