// @flow
import queryString from 'query-string';

import encodeQueryParams from '../utils/encodeQueryParams';

export const HASH_PARAMS = {
  landingId: 'landing'
};

export function parseHash(hash) {
  const params = {};

  const hashValue = (hash || window.location.hash).slice(2);
  const parsedHash = queryString.parse(hashValue, {
    parseBooleans: true,
    parseNumbers: true
  });

  Object.values(HASH_PARAMS).forEach(key => {
    const value = parsedHash[key];
    if (value !== null && value !== undefined) {
      params[key] = value;
    }
  });

  return params;
}

export function getHashParam(param) {
  return window.HASH_PARAMS[param];
}

export function setHashParams(params: { [key: string]: any }) {
  const hashObject = Object.keys(params).reduce(
    (acc, key) => ({
      ...acc,
      [key]: params[key]
    }),
    {}
  );

  return encodeQueryParams(hashObject);
}
