export const hasVarOrSQRe = /(%.*%)|({{.*}})/;

export const urlRe = /^(ftp|http|https):\/\/[^ "]+$/;
export const isCorrectUrl = (url) => urlRe.test(url) || hasVarOrSQRe.test(url);

export const isCorrectGoogleDocsUrl = (url) =>
  /.*spreadsheets\/d\/(\S+).*/.test(url) || hasVarOrSQRe.test(url);

export const isMax = (value, max) => value?.length > max;

export const googleDocsRangeRe = /^[a-zA-Z]\d+(:[a-zA-Z]\d+)?$/;
export const isCorrectGoogleDocsRange = (range) =>
  googleDocsRangeRe.test(range) || hasVarOrSQRe.test(range);

export const validateJson = (jsonStr) => {
  try {
    JSON.parse(jsonStr);
    return true;
  } catch (e) {
    return false;
  }
};
