import React from 'react';
import { Popconfirm } from 'antd';
import { Link, withRouter } from 'react-router-dom';

import { block as blockUrl } from 'config/urls';

type Props = {
  blockId: string,
  history: any
};

@withRouter
class EditBlockLink extends React.Component<Props> {
  state = {
    showDangerModal: false
  };

  gotoBlock = () => {
    this.props.history.push(blockUrl(this.props.blockId));
  };

  render() {
    const { blockId } = this.props;

    return (
      <Popconfirm
        title={
          <span>
            При переходе на блок <b>все несохраненные данные потеряются.</b>{' '}
            <br />
            Перейти на этот блок?
          </span>
        }
        onConfirm={this.gotoBlock}
        onCancel={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        okText="Да"
        cancelText="Нет"
      >
        <Link to={blockUrl(blockId)}>Редактировать блок</Link>
      </Popconfirm>
    );
  }
}

export default EditBlockLink;
