// @flow
import React from 'react';

import { HASH_PARAMS, setHashParams } from './hashParams';

export type ModuleLandingType = {
  id: string,
  name: string,
  title: string,
  description: string,
  vk_pixel: string,
  image_url: string,
  items: Array<SubscribeItemType | ProductItemType>
};

export type ItemType = {
  _id: string,
  kind: string,
  title: string,
  description: string,
  image_url: string,
  block_id: string,
  buttons: { [type: ItemButtonsType]: string }
};

export type SubscribeItemType = {
  list_id: string,
  status: string
} & ItemType;

export type ProductItemType = {
  name: string,
  price: number
} & ItemType;

export const LANDING_ITEM_KIND = {
  SUBSCRIPTION: 'subscription',
  PRODUCT: 'product',
  BASIC: 'basic'
};

export type LandingItemKindType = $Values<typeof LANDING_ITEM_KIND>;

export const LANDING_ITEM = [
  {
    value: LANDING_ITEM_KIND.SUBSCRIPTION,
    label: 'Подписка'
  },
  {
    value: LANDING_ITEM_KIND.PRODUCT,
    label: 'Оплата'
  },
  {
    value: LANDING_ITEM_KIND.BASIC,
    label: 'Действие'
  }
];

export const LANDING_ITEM_BUTTON = {
  subscription: {
    subscribe: 'subscribe',
    unsubscribe: 'unsubscribe'
  },
  product: {
    pay: 'pay'
  },
  basic: {
    click: 'click'
  }
};
const SUBSCRIPTION_BUTTONS =
  LANDING_ITEM_BUTTON[LANDING_ITEM_KIND.SUBSCRIPTION];
const PRODUCT_BUTTONS = LANDING_ITEM_BUTTON[LANDING_ITEM_KIND.PRODUCT];

export type SubscriptionItemButtonsType = $Values<typeof SUBSCRIPTION_BUTTONS>;
export type ProductItemButtonsType = $Values<typeof PRODUCT_BUTTONS>;

export type ItemButtonsType =
  | ProductItemButtonsType
  | SubscriptionItemButtonsType;

export const ITEM_STATUS = {
  SUBSCRIBED: 'subscribed',
  UNSUBSCRIBED: 'unsubscribed'
};

export const landingGlobalLink = () =>
  `https://vk.com/app${window.APP_ID}_-${window.GROUP_ID}`;
