// @flow
import * as React from 'react';
import { Modal } from 'antd';

type Props = {
  href: string,
  children?: React.Node
};

const ModalPay = (props: Props) => {
  const { href, children } = props;
  return Modal.info({
    title: 'Перейдите по ссылке для оплаты',
    content: (
      <div>
        <p>Если окно с оплатой не открылось автоматически,</p>
        <p>
          перейдите по{' '}
          <a href={href} target="_blank">
            ссылке
          </a>
        </p>
        {children}
      </div>
    ),
    onOk() {}
  });
};

ModalPay.defaultProps = {
  children: null
};

export default ModalPay;
