import { action, observable, toJS } from 'mobx';

class ExecSqParamsModel {
  @observable expr = '';

  v = 'v1';

  constructor({ expr, v = 'v1' }) {
    this.expr = expr;
    this.v = v;
  }

  @action.bound
  setExpr(expr) {
    this.expr = expr;
  }

  toJson() {
    return toJS({
      expr: this.expr,
      v: this.v
    });
  }

  static createDefault(expr = ''): ExecSqParamsModel {
    return new ExecSqParamsModel({ expr });
  }

  static fromJson(params): ExecSqParamsModel {
    return new ExecSqParamsModel({ expr: params.expr, v: params.v });
  }
}

export default ExecSqParamsModel;
