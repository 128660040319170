import styled from 'styled-components';

const Help = styled.div<{ toRight: boolean }>`
  position: absolute;
  z-index: 2;
  top: -8px;
  font-weight: 300;
  text-align: left;
  transform: translateY(-100%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px;
  border-radius: 3px;
  display: none;
  line-height: 1.4;
  min-width: 200px;

  ${(props) => (props.toRight === false ? 'left: 0;' : 'right: 0;')}
`;

export default Help;
