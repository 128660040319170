import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';

export enum STEP {
  SEND_MESSAGE = 'send_message',
  SHOW_KEYBOARD = 'show_keyboard',
  TRANSITION = 'transition',
  WAIT = 'wait_new_message',
  SLEEP = 'sleep',
  PARSE = 'parse_message',
  EXIT_CHAIN = 'exit_chain',
  SEND_MESSAGE_AND_WAIT = 'send_message_and_wait',
  CALL_ADMIN = 'call_admin',
  CHANGE_VAR = 'set_vars',
  EVENT = 'write_event',
  ADD_TO_LIST = 'add_to_list',
  REMOVE_FROM_LIST = 'remove_from_list',
  AUDIENCE = 'audience', // работа с аудиторией (добавить/удалить из списка)
  PLAN_EVENT = 'plan_event',
  CANCEL_EVENT = 'cancel_event',
  PLANNED_EVENT = 'planned_event', // работа с запланированными переходами
  PRE_FILTER_CONTINUE = 'pre_filter_continue',
  PRE_FILTER_BREAK = 'pre_filter_break',
  PRE_FILTER = 'pre_filter',
  EXEC_SQ = 'exec_sq',
  MARK_ANSWERED = 'mark_answered',
  CALLBACK_BUTTONS = 'callback_buttons', // Обработка Callback-кнопок
  EVENT_SHOW_SNACKBAR = 'event_show_snackbar',
  EVENT_OPEN_LINK = 'event_open_link',
  EVENT_OPEN_APP = 'event_open_app',
}

export enum STEP_MODULE {
  SEND_EMAIL = 'send_email',
  GOOGLE_SHEETS = 'google_sheets',
  GOOGLE_SHEETS_ADD = 'gsheets_add',
  GOOGLE_SHEETS_GET = 'gsheets_get',
  GOOGLE_SHEETS_SET = 'gsheets_set',
  GAMIFICATION = 'gamification',
  SHOW_TOP = 'show_top',
  SWITCH_CONTEXT = 'switch_context',
  KICK_FROM_CHAT = 'kick_from_chat',
  HTTP_REQUEST = 'http_request',
}

export const STEP_TYPES_LIST = Object.values(STEP).filter(
  (step) => step !== STEP.PRE_FILTER
);
export const STEP_MODULE_TYPES_LIST = Object.values(STEP_MODULE);

export const FAQ_STEPS = [
  STEP.WAIT,
  STEP.SEND_MESSAGE,
  STEP.SEND_MESSAGE_AND_WAIT,
  STEP.SHOW_KEYBOARD,
  STEP.CALL_ADMIN,
  STEP.CHANGE_VAR,
  STEP.EXEC_SQ,
  STEP.EVENT,
  STEP.SLEEP,
  STEP.AUDIENCE,
  STEP.MARK_ANSWERED,
];

export const REACTION_BASE_STEPS = [
  STEP.WAIT,
  STEP.SEND_MESSAGE,
  STEP.SEND_MESSAGE_AND_WAIT,
  STEP.SHOW_KEYBOARD,
  STEP.CALL_ADMIN,
  STEP.CHANGE_VAR,
  STEP.EXEC_SQ,
  STEP.EVENT,
  STEP.SLEEP,
  STEP.AUDIENCE,
  STEP.PLANNED_EVENT,
  STEP.MARK_ANSWERED,
];

export type STEP_TYPES =
  | STEP.PARSE
  | STEP.SEND_MESSAGE
  | STEP.SHOW_KEYBOARD
  | STEP.TRANSITION
  | STEP.WAIT
  | STEP.SLEEP
  | STEP.EXIT_CHAIN
  | STEP.SEND_MESSAGE_AND_WAIT
  | STEP.CALL_ADMIN
  | STEP.CHANGE_VAR
  | STEP.EVENT
  | STEP_MODULE.SEND_EMAIL
  | STEP.ADD_TO_LIST
  | STEP.REMOVE_FROM_LIST
  | STEP.AUDIENCE
  | STEP.PLAN_EVENT
  | STEP.CANCEL_EVENT
  | STEP.PLANNED_EVENT
  | STEP.PRE_FILTER
  | STEP.PRE_FILTER_BREAK
  | STEP.PRE_FILTER_CONTINUE
  | STEP.EXEC_SQ
  | STEP_MODULE.GOOGLE_SHEETS_ADD
  | STEP_MODULE.GOOGLE_SHEETS_GET
  | STEP_MODULE.GOOGLE_SHEETS_SET
  | STEP_MODULE.GAMIFICATION
  | STEP_MODULE.SHOW_TOP
  | STEP_MODULE.SWITCH_CONTEXT
  | STEP_MODULE.KICK_FROM_CHAT
  | STEP.MARK_ANSWERED
  | STEP_MODULE.HTTP_REQUEST;

export const STEP_TITLE = {
  [STEP.EXIT_CHAIN]: 'Выйти из цепочки',
  [STEP.TRANSITION]: 'Переход',
  [STEP.WAIT]: 'Ждать сообщения',
  [STEP.SLEEP]: 'Таймер',
  [STEP.PARSE]: 'Извлечь данные',
  [STEP.SEND_MESSAGE]: 'Отправить сообщение',
  [STEP.SEND_MESSAGE_AND_WAIT]: 'Отправить сообщение и ждать',
  [STEP.SHOW_KEYBOARD]: 'Показать кнопки',
  [STEP.CALL_ADMIN]: 'Написать админу',
  [STEP.CHANGE_VAR]: 'Установить переменную',
  [STEP.EVENT]: 'Записать событие в статистику',
  [STEP_MODULE.SEND_EMAIL]: 'Отправить email',
  [STEP.ADD_TO_LIST]: 'Добавить в список',
  [STEP.REMOVE_FROM_LIST]: 'Удалить из списка',
  [STEP.AUDIENCE]: 'Работа с аудиторией',
  [STEP.PLAN_EVENT]: 'Запланировать переход',
  [STEP.CANCEL_EVENT]: 'Отменить запланированный переход',
  [STEP.PLANNED_EVENT]: 'Запланированные переходы',
  [STEP.EXEC_SQ]: 'Выполнить SmartQuery',
  [STEP.PRE_FILTER]: 'Фильтр предобработки',
  [STEP.PRE_FILTER_CONTINUE]: 'Продолжить обработку сообщения',
  [STEP.PRE_FILTER_BREAK]: 'Прервать обработку сообщения',
  [STEP_MODULE.GOOGLE_SHEETS]: 'Google Таблица',
  [STEP_MODULE.GOOGLE_SHEETS_ADD]: 'Добавить строку',
  [STEP_MODULE.GOOGLE_SHEETS_GET]: 'Получить значения',
  [STEP_MODULE.GOOGLE_SHEETS_SET]: 'Изменить ячейку',
  [STEP_MODULE.GAMIFICATION]: 'Геймификация',
  [STEP_MODULE.SHOW_TOP]: 'Показать ТОП',
  [STEP_MODULE.SWITCH_CONTEXT]: 'Смена контекста',
  [STEP_MODULE.KICK_FROM_CHAT]: 'Удалить из чата',
  [STEP.MARK_ANSWERED]: 'Отметить отвеченным',
  [STEP_MODULE.HTTP_REQUEST]: 'HTTP-запрос',
  [STEP.CALLBACK_BUTTONS]: 'Обработка Callback-кнопок',
  [STEP.EVENT_SHOW_SNACKBAR]: 'Показать уведомление',
  [STEP.EVENT_OPEN_LINK]: 'Открыть ссылку',
  [STEP.EVENT_OPEN_APP]: 'Открыть приложение',
};

export const STEP_ICON = {
  [STEP.EXIT_CHAIN]: 'logout',
  [STEP.TRANSITION]: 'arrow-right',
  [STEP.WAIT]: 'clock-circle',
  [STEP.SLEEP]: 'hourglass',
  [STEP.PARSE]: 'pic-left',
  [STEP.SEND_MESSAGE]: 'message',
  [STEP.SEND_MESSAGE_AND_WAIT]: 'message',
  [STEP.SHOW_KEYBOARD]: 'message',
  [STEP.CALL_ADMIN]: 'bell',
  [STEP.CHANGE_VAR]: 'percentage',
  [STEP.EVENT]: 'flag',
  [STEP_MODULE.SEND_EMAIL]: 'mail',
  [STEP.ADD_TO_LIST]: 'user-add',
  [STEP.REMOVE_FROM_LIST]: 'user-delete',
  [STEP.AUDIENCE]: 'team',
  [STEP.PLAN_EVENT]: 'carry-out',
  [STEP.CANCEL_EVENT]: 'disconnect',
  [STEP.PLANNED_EVENT]: 'carry-out',
  [STEP.EXEC_SQ]: 'code',
  [STEP.PRE_FILTER]: 'filter',
  [STEP.PRE_FILTER_CONTINUE]: 'filter',
  [STEP.PRE_FILTER_BREAK]: 'filter',
  [STEP_MODULE.GOOGLE_SHEETS]: 'table',
  [STEP_MODULE.GOOGLE_SHEETS_ADD]: 'table',
  [STEP_MODULE.GOOGLE_SHEETS_GET]: 'table',
  [STEP_MODULE.GOOGLE_SHEETS_SET]: 'table',
  [STEP_MODULE.GAMIFICATION]: 'smile',
  [STEP_MODULE.SHOW_TOP]: 'profile',
  [STEP_MODULE.SWITCH_CONTEXT]: 'sync',
  [STEP_MODULE.KICK_FROM_CHAT]: 'usergroup-delete',
  [STEP.MARK_ANSWERED]: 'check',
  [STEP_MODULE.HTTP_REQUEST]: 'cloud-upload',
  [STEP.CALLBACK_BUTTONS]: 'rollback',
  [STEP.EVENT_SHOW_SNACKBAR]: 'notification',
  // [STEP.EVENT_OPEN_LINK]: 'link',
  // [STEP.EVENT_OPEN_APP]: 'appstore'
};

const makeCascaderItem = (stepKind: STEP | STEP_MODULE) => ({
  value: stepKind,
  icon: <LegacyIcon type={STEP_ICON[stepKind]} />,
  label: STEP_TITLE[stepKind],
});

export const STEP_TITLE_CASCADER = [
  ...[
    STEP.EXIT_CHAIN,
    STEP.TRANSITION,
    STEP.WAIT,
    STEP.SLEEP,
    STEP.PARSE,
    STEP.SEND_MESSAGE,
    STEP.SEND_MESSAGE_AND_WAIT,
    STEP.SHOW_KEYBOARD,
    STEP.CALL_ADMIN,
    STEP.CHANGE_VAR,
    STEP.EXEC_SQ,
    STEP.EVENT,
    STEP.MARK_ANSWERED,
    STEP_MODULE.HTTP_REQUEST,
  ].map(makeCascaderItem),
  {
    ...makeCascaderItem(STEP.PRE_FILTER),
    children: [
      makeCascaderItem(STEP.PRE_FILTER_CONTINUE),
      makeCascaderItem(STEP.PRE_FILTER_BREAK),
    ],
  },
  makeCascaderItem(STEP_MODULE.SEND_EMAIL),
  {
    ...makeCascaderItem(STEP.AUDIENCE),
    children: [
      makeCascaderItem(STEP.ADD_TO_LIST),
      makeCascaderItem(STEP.REMOVE_FROM_LIST),
    ],
  },
  {
    ...makeCascaderItem(STEP.PLANNED_EVENT),
    children: [
      makeCascaderItem(STEP.PLAN_EVENT),
      makeCascaderItem(STEP.CANCEL_EVENT),
    ],
  },
  {
    ...makeCascaderItem(STEP_MODULE.GOOGLE_SHEETS),
    children: [
      makeCascaderItem(STEP_MODULE.GOOGLE_SHEETS_ADD),
      makeCascaderItem(STEP_MODULE.GOOGLE_SHEETS_GET),
      makeCascaderItem(STEP_MODULE.GOOGLE_SHEETS_SET),
    ],
  },
  makeCascaderItem(STEP_MODULE.GOOGLE_SHEETS_ADD),
  {
    ...makeCascaderItem(STEP_MODULE.GAMIFICATION),
    children: [
      makeCascaderItem(STEP_MODULE.SHOW_TOP),
      makeCascaderItem(STEP_MODULE.SWITCH_CONTEXT),
      makeCascaderItem(STEP_MODULE.KICK_FROM_CHAT),
    ],
  },
  {
    ...makeCascaderItem(STEP.CALLBACK_BUTTONS),
    children: [
      makeCascaderItem(STEP.EVENT_SHOW_SNACKBAR),
      // makeCascaderItem(STEP.EVENT_OPEN_LINK),
      // makeCascaderItem(STEP.EVENT_OPEN_APP)
    ],
  },
];
