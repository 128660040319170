import React from 'react';
import { observer } from 'mobx-react';

import SubscribeItemModel from 'models/Landing/Elements/SubscribeItemModel';
import LandingModel from 'models/Landing/LandingModel';

import BaseEdit from '../../editor/BaseEdit';
import EditInput from '../../editor/EditInput';
import EditImage from '../../editor/EditImage';
import EditSelector from '../../editor/EditSelector';
import EditSelectorList from '../../editor/EditSelectorList';
import OpenChatFlag from '../Basic/OpenChatFlag';

import SubscriptionElement from './SubscriptionElement';

type Props = {
  item: SubscribeItemModel,
  landing: LandingModel,
  index: number,
  isEditMode?: boolean
};

const EditButtonSubscription = ({
  item,
  landing,
  index,
  isEditMode
}: Props) => {
  const view = <SubscriptionElement item={item} isEditMode={isEditMode} />;

  if (!isEditMode) {
    return view;
  }

  return (
    <BaseEdit
      index={index}
      landing={landing}
      item={item}
      view={view}
      editorComponent={
        <>
          <EditInput
            title="Заголовок"
            value={item.title}
            onChange={item.setTitle}
            errorMessage={item.errors.get('title')}
          />
          <EditInput
            title="Описание"
            value={item.description}
            onChange={item.setDescription}
            errorMessage={item.errors.get('description')}
          />
          <EditImage
            title="Изображение"
            value={item.imageUrl}
            onChange={value => item.uploadFile(value, landing.id)}
            onRemove={item.removeFile}
          />
          <EditInput
            onChange={item.getSubscribeButton().setText}
            value={item.getSubscribeButton().text}
            title="Текст на кнопке подписки"
          />
          <OpenChatFlag
            onChange={item.getSubscribeButton().setOpenChat}
            value={item.getSubscribeButton().openChat}
          />
          <EditInput
            onChange={item.getUnsubscribeButton().setText}
            value={item.getUnsubscribeButton().text}
            title="Текст на кнопке отписки"
          />
          <OpenChatFlag
            onChange={item.getUnsubscribeButton().setOpenChat}
            value={item.getUnsubscribeButton().openChat}
          />
          <EditSelector
            title="Переход на блок"
            value={item.blockId}
            onChange={item.setBlockId}
            placeholder="Выберите блок"
          />
          <EditSelectorList
            title="Добавление в список"
            value={item.listId}
            onChange={item.setListId}
          />
        </>
      }
    />
  );
};

EditButtonSubscription.defaultProps = {
  isEditMode: false
};

export default observer(EditButtonSubscription);
