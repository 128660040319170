import styled from 'styled-components';

import { globalSpacingStyle } from 'styles/constants';

export const ButtonDelete = styled.div`
  position: relative;
  margin-left: ${globalSpacingStyle.m};
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;
