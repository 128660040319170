// @flow
import { observer } from 'mobx-react';
import React from 'react';
import { message } from 'antd';

import { vkClientApi } from 'store/api';
import generateId from 'utils/generateId';
import apiUrls from 'config/api';
import { ITEM_STATUS } from 'types/moduleLanding';
import replaceTextToLink from 'utils/replaceTextToLink';
import SubscribeItemModel from 'models/Landing/Elements/SubscribeItemModel';
import { openChatWithDelay } from 'components/landing/utils';

import {
  Title,
  Description,
  ButtonWrapper,
  Wrapper,
  Image,
  Container
} from '../styles';

type Props = {
  item: SubscribeItemModel,
  isEditMode?: boolean
};

const SubscriptionElement = (props: Props) => {
  const {
    item: { imageUrl, title, description, status, unsubscribe, subscribe },
    item,
    isEditMode
  } = props;

  const onSubscribe = async () => {
    if (window.IS_IFRAME) {
      const success = await vkClientApi('VKWebAppAllowMessagesFromGroup', {
        group_id: Number(window.GROUP_ID),
        key: generateId()
      });

      if (!success) {
        return;
      }
    }

    if (ITEM_STATUS.UNSUBSCRIBED === status) {
      const result = await subscribe();
      if (result) {
        message.success('Вы успешно подписались на рассылку');
        if (item.getSubscribeButton().openChat) {
          openChatWithDelay();
        }
        return;
      }
    }

    if (ITEM_STATUS.SUBSCRIBED === status) {
      const result = await unsubscribe();
      if (result) {
        message.success('Вы успешно отписались от рассылки');
        if (item.getUnsubscribeButton().openChat) {
          openChatWithDelay();
        }
      }
    }
  };

  // если кнопка Подписаться одна, то поставить ее по центру
  const isSingleButton = !title && !description && !imageUrl;

  return (
    <Container>
      {imageUrl && (
        <Image src={`${apiUrls.filesGet}${imageUrl}`} alt="Изображение" />
      )}
      {!isSingleButton && (
        <Wrapper>
          <Title>{replaceTextToLink(title)}</Title>
          <Description>{replaceTextToLink(description)}</Description>
        </Wrapper>
      )}
      <ButtonWrapper
        type="primary"
        onClick={isEditMode ? () => {} : onSubscribe}
      >
        {ITEM_STATUS.SUBSCRIBED === status && !isEditMode
          ? item.getUnsubscribeButton().text
          : item.getSubscribeButton().text}
      </ButtonWrapper>
    </Container>
  );
};

SubscriptionElement.defaultProps = {
  isEditMode: false
};

export default observer(SubscriptionElement);
