import { observable, action, toJS } from 'mobx';

export const TRANSITION_ERROR = {
  BLOCK_ERROR_MESSAGE: 'Не найден выбранный блок, возможно он удалён',
  STEPS_GROUP_ERROR_MESSAGE:
    'Не найдена выбранная группа шагов, возможно она удалёна',
  STEP_ERROR_MESSAGE: 'Не найден выбранный шаг, возможно он удалён'
};

export class TransitionParamsModel {
  @observable blockId: string = null;

  @observable stepGroupId: string = null;

  @observable stepId: string = null;

  constructor(blockId = null, stepGroupId = null, stepId = null) {
    this.blockId = blockId;
    this.stepGroupId = stepGroupId;
    this.stepId = stepId;
  }

  @action
  update = (blockId = null, stepGroupId = null, stepId = null) => {
    this.blockId = blockId;
    this.stepGroupId = stepGroupId;
    this.stepId = stepId;
  };

  validate = () => {
    const errors = [];

    if (!this.blockId) {
      errors.push('Не выбран блок для перехода');
      return errors;
    }

    return errors;
  };

  toJson() {
    return toJS({
      block_id: this.blockId,
      step_group_id: this.stepGroupId,
      step_id: this.stepId
    });
  }

  static createDefault(): TransitionParamsModel {
    return new TransitionParamsModel();
  }

  static fromJson(params): TransitionParamsModel {
    return new TransitionParamsModel(
      params.block_id,
      params.step_group_id,
      params.step_id
    );
  }
}
