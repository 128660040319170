import { STEP_MODULE, STEP } from 'types/step';

import { REACTION } from './reactions';
import { MODULE_KIND } from './modules';

export const DOCS = {
  QUESTIONS:
    'https://docs.smartbot-vk.ru/sozdanie-scenariev/znakomstvo-s-prilozheniem',
  CHAINS: 'https://docs.smartbot-vk.ru/sozdanie-scenariev/cepochka-dialoga',
  VARS: 'https://docs.smartbot-vk.ru/sozdanie-scenariev/peremennye',
  VARS_CREATE:
    'https://docs.smartbot-vk.ru/sozdanie-scenariev/peremennye/polzovatelskie-peremennye#sozdanie-peremennoi',
  STATS: 'https://docs.smartbot-vk.ru/rabota-s-podpischikami/sbor-statistiki',
  USERS:
    'https://docs.smartbot-vk.ru/rabota-s-podpischikami/razdel-polzovateli',

  SMART_QUERY: {
    ALL: 'https://docs.smartbot-vk.ru/smartquery/vvedenie',
    DICT:
      'https://docs.smartbot-vk.ru/smartquery/spravochnik-funkcii#rabota-so-slovaryami',
    LIST:
      'https://docs.smartbot-vk.ru/smartquery/spravochnik-funkcii#rabota-s-massivami'
  },

  CONDITIONS: {
    ALL: 'https://docs.smartbot-vk.ru/sozdanie-scenariev/usloviya',
    COMPLEX:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/usloviya#slozhnye-usloviya'
  },

  DELIVERY: {
    ALL: 'https://docs.smartbot-vk.ru/rabota-s-podpischikami/rassylki',
    CREATE:
      'https://docs.smartbot-vk.ru/rabota-s-podpischikami/rassylki/sozdanie-rassylok',
    TARGETING:
      'https://docs.smartbot-vk.ru/rabota-s-podpischikami/rassylki/targetirovanie-rassylki'
  },

  LANDINGS: {
    ALL: 'https://docs.smartbot-vk.ru/rabota-s-podpischikami/lendingi',
    VK_PIXEL:
      'https://docs.smartbot-vk.ru/rabota-s-podpischikami/lendingi/integraciya-s-reklamnym-kabinetom-vk'
  },

  MODULES: {
    // [MODULE_KIND.EMAIL]: 'email',
    [MODULE_KIND.REF_SOURCE]:
      'https://docs.smartbot-vk.ru/moduli/istochniki-perekhodov',
    [MODULE_KIND.GAMIFICATION]:
      'https://docs.smartbot-vk.ru/moduli/modul-geimifikaciya',
    [MODULE_KIND.GOOGLE_SHEETS]:
      'https://docs.smartbot-vk.ru/moduli/gugl-tablicy',
    [MODULE_KIND.YANDEX_MONEY]:
      'https://docs.smartbot-vk.ru/moduli/yandeks.-dengi',
    [MODULE_KIND.HTTP_API]: 'https://docs.smartbot-vk.ru/moduli/http-requests'
  },

  REACTIONS: {
    ALL: 'https://docs.smartbot-vk.ru/sozdanie-scenariev/reakcii',
    [REACTION.DEFAULT_ANSWER]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/reakcii#otvet-po-umolchaniyu',
    [REACTION.GROUP_LEAVE]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/reakcii#vykhod-iz-gruppy',
    [REACTION.SWEAR_WORDS]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/reakcii#reakciya-na-mat',
    [REACTION.PAYMENT]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/reakcii/reakciya-na-tranzakciyu-vk-pay',
    [REACTION.FIRST_MESSAGE]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/reakcii#reakciya-na-pervoe-soobshenie',
    [REACTION.GROUP_JOIN]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/reakcii#vykhod-iz-gruppy',
    [REACTION.PRE_FILTER]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/reakcii/filtr-predobrabotki',
    [REACTION.PHOTO_ATTACH]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/reakcii#reakciya-na-faily-i-vlozheniya',
    [REACTION.VIDEO_ATTACH]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/reakcii#reakciya-na-faily-i-vlozheniya',
    [REACTION.AUDIO_ATTACH]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/reakcii#reakciya-na-faily-i-vlozheniya',
    [REACTION.STICKER_ATTACH]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/reakcii#reakciya-na-faily-i-vlozheniya',
    [REACTION.DOC_ATTACH]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/reakcii#reakciya-na-faily-i-vlozheniya',
    [REACTION.MARKET_ATTACH]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/reakcii#reakciya-na-faily-i-vlozheniya'
  },

  STEPS: {
    [STEP.SEND_MESSAGE]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/shagi/otpravit-soobshenie',
    [STEP.SHOW_KEYBOARD]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/shagi/otpravit-soobshenie#pokazat-knopki',
    [STEP.TRANSITION]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/shagi/perekhod',
    [STEP.WAIT]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/shagi/zhdat-soobshenie',
    [STEP.SLEEP]: 'https://docs.smartbot-vk.ru/sozdanie-scenariev/shagi/taimer',
    [STEP.PARSE]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/shagi/izvlech-dannye',
    // [STEP.EXIT_CHAIN]: 'exit_chain',
    // [STEP.SEND_MESSAGE_AND_WAIT]: 'send_message_and_wait',
    [STEP.CALL_ADMIN]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/shagi/napisat-adminu',
    [STEP.CHANGE_VAR]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/shagi/ustanovit-peremennuyu-1',
    [STEP.EVENT]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/shagi/zapisat-sobytie-v-statistiku',
    [STEP.ADD_TO_LIST]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/shagi/rabota-s-auditoriei#shag-dobavit-v-spisok',
    [STEP.REMOVE_FROM_LIST]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/shagi/rabota-s-auditoriei#shag-udalit-iz-spiska',
    // [STEP.AUDIENCE]: 'audience', // работа с аудиторией (добавить/удалить из списка)
    [STEP.PLAN_EVENT]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/shagi/otlozhennyi-perekhod',
    [STEP.CANCEL_EVENT]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/shagi/otlozhennyi-perekhod',
    [STEP.PLANNED_EVENT]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/shagi/otlozhennyi-perekhod', // работа с запланированными переходами
    [STEP.PRE_FILTER_CONTINUE]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/reakcii/filtr-predobrabotki',
    [STEP.PRE_FILTER_BREAK]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/reakcii/filtr-predobrabotki',
    [STEP.EXEC_SQ]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/shagi/vypolnit-smartquery',
    [STEP_MODULE.SWITCH_CONTEXT]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/shagi/smena-konteksta',
    [STEP_MODULE.HTTP_REQUEST]:
      'https://docs.smartbot-vk.ru/sozdanie-scenariev/shagi/http-zapros'
  }
};
