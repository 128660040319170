import type { JobType, HandleUnansweredJobParams, JobStatus } from 'types/jobs';

export const JOB_START = 'job/start';
export const JOB_CANCEL = 'job/cancel';
export const JOB_START_ERROR = 'job/start/error';
export const JOB_FETCH = 'job/fetch';
export const JOB_SET_INFO = 'job/setInfo';
export const JOB_FINISHED = 'job/finished';

export type JobStartAction = {
  type: JOB_START,
  payload: {
    jobName: JobType,
    params: HandleUnansweredJobParams
  }
};

export type JobCancelAction = {
  type: JOB_CANCEL,
  jobName: JobType
};

export type JobStartErrorAction = {
  type: JOB_START_ERROR,
  payload: {
    jobName: JobType
  }
};

export type JobFetchAction = {
  type: JOB_FETCH,
  payload: {
    jobName: JobType
  }
};

export type JobFinishAction = {
  type: JOB_FINISHED,
  payload: {
    jobName: JobType,
    status: JobStatus,
    result: any
  }
};

export type JobSetInfoAction = {
  type: JOB_SET_INFO,
  payload: {
    jobName: JobType,
    status: JobStatus,
    progress: number
  }
};

export const startJob = (
  jobName: JobType,
  params: HandleUnansweredJobParams | any
): JobStartAction => ({
  type: JOB_START,
  payload: {
    jobName,
    params
  }
});

export const cancelJob = (jobName: JobType): JobCancelAction => ({
  type: JOB_CANCEL,
  jobName
});

export const startJobError = (jobName: JobType): JobStartErrorAction => ({
  type: JOB_START_ERROR,
  payload: {
    jobName
  }
});

export const finishJob = (
  jobName: JobType,
  status: JobStatus,
  result: any
): JobFinishAction => ({
  type: JOB_FINISHED,
  payload: {
    jobName,
    status,
    result
  }
});

export const fetchJobData = (jobName: JobType): JobFetchAction => ({
  type: JOB_FETCH,
  payload: {
    jobName
  }
});

export const jobSetInfo = (
  jobName: JobType,
  status: JobStatus,
  progress: number
): JobSetInfoAction => ({
  type: JOB_SET_INFO,
  payload: {
    jobName,
    status,
    progress
  }
});

export default {
  startJob,
  cancelJob,
  startJobError,
  jobSetInfo,
  finishJob,
  fetchJobData,

  JOB_START,
  JOB_START_ERROR,
  JOB_FETCH,
  JOB_SET_INFO,
  JOB_FINISHED,
  JOB_CANCEL
};
