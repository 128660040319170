import { observable, action, toJS } from 'mobx';

import cancelEventType from 'types/cancelEventType';

export default class CancelPlanEventParamsModel {
  @observable name: string;

  // название события
  @observable mode: string = cancelEventType.one.key;

  constructor({ name, mode }) {
    this.name = name;
    this.mode = mode;
  }

  @action.bound
  setName(name) {
    this.name = name;
  }

  @action.bound
  changeMode(mode) {
    this.mode = mode;
  }

  toJson() {
    return toJS({
      name: this.mode === cancelEventType.one.key ? this.name : null
    });
  }

  static createDefault(): CancelPlanEventParamsModel {
    return new CancelPlanEventParamsModel({
      mode: cancelEventType.one.key
    });
  }

  static fromJson(params): CancelPlanEventParamsModel {
    return new CancelPlanEventParamsModel({
      name: params.name,
      mode: params.name ? cancelEventType.one.key : cancelEventType.all.key
    });
  }
}
