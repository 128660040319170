import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { globalSpacingStyle, color } from 'styles/constants';

type Props = {
  description: string,
  title?: string,
  isLink?: boolean,
  trigger?: 'click' | 'hover',
};

const Wrapper = styled.div`
  cursor: pointer;
  color: ${color.primary};
  display: inline-block;
  margin-right: ${globalSpacingStyle.m};
`;

const StyledIcon = styled(QuestionCircleOutlined)`
  color: ${color.primary};
`;

const HelpWithText = ({ isLink, title, trigger, description }: Props) => {
  return (
    <Tooltip trigger={isLink ? 'hover' : trigger} title={description}>
      <Wrapper>
        <StyledIcon type="question-circle" /> {title}
      </Wrapper>
    </Tooltip>
  );
};

HelpWithText.defaultProps = {
  title: 'Помощь',
  trigger: 'click',
  isLink: false,
};

export default React.memo(HelpWithText);
