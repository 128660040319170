import React, { ReactElement } from 'react';
import { Button, message } from 'antd';
import styled from 'styled-components';

import * as urls from 'config/urls';
import { MODULE_KIND } from 'types/modules';
import history from 'utils/history';

const ErrorLimitExceeded = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonToLimitsSettings = styled(Button)`
  margin-top: 5px;
`;

function parseErrorLimitExceeded(
  errorData: {
    limit_title?: string;
    limit_value?: number;
    limit_max?: number;
  },
  messageCode: string
): ReactElement {
  const contentMessage = (
    <div>
      Превышен лимит:&nbsp;
      <b>{errorData.limit_title}</b>
      <br />
      Текущее значение лимита: {errorData.limit_value}
    </div>
  );

  if (errorData.limit_value < errorData.limit_max) {
    return (
      <ErrorLimitExceeded>
        {contentMessage}
        <ButtonToLimitsSettings
          onClick={() => {
            message.destroy(messageCode);
            history.push(urls.module(MODULE_KIND.LIMITS));
          }}
        >
          Перейти к настройке лимита
        </ButtonToLimitsSettings>
      </ErrorLimitExceeded>
    );
  }

  return contentMessage;
}

export default parseErrorLimitExceeded;
