import { observable, action, toJS } from 'mobx';

class EventParamsModel {
  @observable label = '';

  constructor(label: string) {
    this.label = label;
  }

  @action
  setLabel = (label: string): void => {
    this.label = label;
  };

  toJson() {
    return toJS({
      label: this.label,
    });
  }

  static createDefault(): EventParamsModel {
    return new EventParamsModel('');
  }

  static fromJson(params): EventParamsModel {
    return new EventParamsModel(params.label);
  }
}

export default EventParamsModel;
