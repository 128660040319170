import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/browser';
import { applyMiddleware, compose, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import createSentryMiddleware from 'redux-sentry-middleware';
import { reducers, sagas } from './index';


export default function сreate(isProd) {
  let store = null;
  const sagaMiddleware = createSagaMiddleware({
    onError: e => {
      if (isProd) {
        Sentry.captureException(e);
      }
    }
  });
  if (isProd) {
    const middleware = applyMiddleware(
      ReduxThunk,
      sagaMiddleware,
      createSentryMiddleware(Sentry)
    );
    store = createStore(reducers, middleware);
  } else {
    const middleware = applyMiddleware(ReduxThunk, sagaMiddleware); //, logger);
    let enhancer;

    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
      // eslint-disable-line
      enhancer = compose(
        middleware,
        window.__REDUX_DEVTOOLS_EXTENSION__() // eslint-disable-line
      );
    } else {
      enhancer = compose(middleware);
    }

    store = createStore(reducers, enhancer);
  }

  sagaMiddleware.run(sagas);
  return store;
}