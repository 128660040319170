import React from 'react';
import { observable, action, toJS, computed } from 'mobx';
import { message } from 'antd';

import { ChainModeType } from 'types/reactions';
import { CHAIN_TYPE, ChainKindType } from 'types/chain';
import generateId from 'utils/generateId';
import api from 'store/api';
import apiUrls from 'config/api';

import { RootStore } from '../RootStore';
import { ExternalLink } from '../../site/components/Header/TopHeader/styles';

import Transition from './Transition';
import StepsGroupModel from './StepsGroupModel';
import { pushDown, pushUp } from './utils';

export enum BLOCK_ERROR {
  NAME = 'name',
}

export default class BlockModel {
  rootStore = null;

  isNew = true;

  isChanged = false;

  id: string = generateId();

  @observable isSaving = false;

  @observable isCopying = false;

  @observable name = '';

  @observable kind: ChainKindType = CHAIN_TYPE.FAQ;

  @observable mode: ChainModeType;

  @observable reaction: string;

  @observable defaultTransition = new Transition();

  @observable stepsGroups: Array<StepsGroupModel> = [];

  @observable copiedStepsGroup: StepsGroupModel = null;

  @observable errors: { [field: string]: string } = {};

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get canPasteStepsGroup(): boolean {
    return this.copiedStepsGroup !== null;
  }

  @action
  setError = (field: string, errorText: string): void => {
    this.errors[field] = errorText;
  };

  @action
  clearErrors = (): void => {
    this.errors = {};
  };

  @action
  setName = (name: string): void => {
    this.isChanged = true;
    this.name = name;
  };

  @action
  setNameFromChain = (name: string): void => {
    if (!this.isChanged) {
      this.name = name;
    }
  };

  @action
  addStepsGroup = (): void => {
    this.stepsGroups.push(StepsGroupModel.createDefault(this));
  };

  @action
  addStepsWithEventTypeCondition = (): void => {
    this.stepsGroups.push(StepsGroupModel.createWithEventTypeCondition(this));
  };

  @action
  addStepsGroupWithCondition = (index: number = null): void => {
    const stepsGroup = StepsGroupModel.createDefault(this);
    stepsGroup.condition.addMessageCondition();
    if (index !== null) {
      this.insertStepsGroup(index, stepsGroup);
    } else {
      this.stepsGroups.push(stepsGroup);
    }
  };

  @action
  insertStepsGroup = (
    index: number,
    stepsGroup: StepsGroupModel = null
  ): void => {
    const sg = stepsGroup || StepsGroupModel.createDefault(this);
    this.stepsGroups = [
      ...this.stepsGroups.slice(0, index),
      sg,
      ...this.stepsGroups.slice(index, this.stepsGroups.length),
    ];
  };

  @action
  deleteStepsGroup = (id: string): void => {
    this.stepsGroups = this.stepsGroups.filter((sg) => sg.id !== id);
  };

  @action
  reorderStepGroups = (index: number, position: 'up' | 'down'): void => {
    if (position === 'up') {
      this.stepsGroups = pushUp(this.stepsGroups, index);
    } else if (position === 'down') {
      this.stepsGroups = pushDown(this.stepsGroups, index);
    }
  };

  @action
  copyStepsGroup = (stepsGroup: StepsGroupModel): boolean => {
    this.copiedStepsGroup = StepsGroupModel.fromJson(stepsGroup.toJson(), this);
    this.copiedStepsGroup.id = generateId();
    this.copiedStepsGroup.steps.forEach((step) => {
      // eslint-disable-next-line no-param-reassign
      step._id = generateId();
    });
    return true;
  };

  @action
  pasteStepsGroup = (index: number): boolean => {
    if (!this.copiedStepsGroup) {
      return false;
    }
    this.insertStepsGroup(index, this.copiedStepsGroup);
    this.copyStepsGroup(this.copiedStepsGroup);
    return true;
  };

  @action
  pasteStepsGroupToLast = (): void => {
    this.pasteStepsGroup(this.stepsGroups.length);
  };

  save = async (): Promise<boolean> => {
    if (!this.validate()) {
      return false;
    }

    this.isSaving = true;

    const data = this.toJson();

    const url = this.isNew ? apiUrls.blocksAdd : apiUrls.blocksUpdate;

    const { error, response } = await api(url, 'POST', data, {
      errorConfig: {
        isShowError: true,
        defaultMessage: 'Произошла ошибка, проверьте, все ли поля заполнены',
      },
    });

    this.isSaving = false;

    if (error) {
      return false;
    }

    if (response._id) {
      this.id = response._id;
      this.rootStore.blockStore.saveBlock(this);
    }

    this.isNew = false;

    return true;
  };

  testRunStep = async (
    stepGroupId: string,
    stepId: string
  ): Promise<boolean> => {
    const { error } = await api(
      apiUrls.blocksTestRun,
      'POST',
      {
        block_id: this.id,
        step_group_id: stepGroupId,
        step_id: stepId,
      },
      {
        errorConfig: {
          isShowError: true,
          defaultMessage: 'Не удалось запустить тестирование шага',
        },
      }
    );

    if (!error) {
      message.success(
        <span>
          Шаг запущен.
          <ExternalLink
            target="_blank"
            href={`https://vk.com/im?media=&sel=-${window.GROUP_ID}`}
          >
            Открыть диалог
          </ExternalLink>
        </span>
      );
    }

    return !error;
  };

  @action
  copy = async (name: string): Promise<boolean> => {
    this.isCopying = true;

    const data = this.toJson();
    data._id = generateId();
    data.name = name;

    const { error, response } = await api(apiUrls.blocksAdd, 'POST', data, {
      errorConfig: {
        isShowError: true,
        defaultMessage: 'Не удалось скопировать блок, попробуйте позже',
      },
    });

    this.isCopying = false;

    if (error) {
      return false;
    }

    this.rootStore.blockStore.addBlock(
      BlockModel.fromJson(data, this.rootStore)
    );

    return response;
  };

  validate = (): boolean => {
    let result = true;
    this.clearErrors();

    if (!this.name || this.name.trim() === '') {
      this.setError(BLOCK_ERROR.NAME, 'Введите название');
      return false;
    }

    // if (this.stepsGroups.length === 0) {
    //   return false;
    // }

    this.stepsGroups.forEach((sg, i) => {
      if (!sg.validate(i === this.stepsGroups.length - 1)) {
        result = false;
      }
    });

    return result;
  };

  searchStepsGroup = (id: string): StepsGroupModel => {
    return this.stepsGroups.find((stepGroup) => stepGroup.id === id);
  };

  toJson = (): Record<string, unknown> => {
    return toJS({
      _id: this.id,
      name: this.name,
      kind: this.kind,
      reaction: this.reaction,
      mode: this.mode,
      default_transition: this.defaultTransition.toJson(),
      steps_groups: this.stepsGroups.map((sg) => sg.toJson()),
    });
  };

  static async load(_id: string, rootStore: RootStore): Promise<BlockModel> {
    const { response } = await api(apiUrls.blocksGet, 'GET', {
      _id,
    });

    if (response?.block) {
      const block = BlockModel.fromJson(response.block, rootStore);
      rootStore.blockStore.saveBlock(block);
      return block;
    }

    return null;
  }

  static createDefault(
    {
      kind = CHAIN_TYPE.FAQ,
      name = '',
      withStepsGroup = true,
      isCallback = false,
    } = {},
    rootStore: RootStore
  ): BlockModel {
    const block = new BlockModel(rootStore);

    if (withStepsGroup) {
      block.addStepsGroup();
    }
    if (isCallback) {
      block.addStepsWithEventTypeCondition();
    }
    block.kind = kind;
    block.name = name;

    return block;
  }

  static fromJson(
    data: {
      _id: string;
      name: string;
      kind: ChainKindType;
      mode: ChainModeType;
      reaction: string;
      default_transition: Record<string, unknown>;
      steps_groups: Array<Record<string, unknown>>;
    },
    rootStore: RootStore
  ): BlockModel {
    const block = new BlockModel(rootStore);

    block.isChanged = true;
    block.isNew = false;
    block.id = data._id;
    block.name = data.name;
    block.kind = data.kind;
    block.mode = data.mode;
    block.reaction = data.reaction;

    block.defaultTransition = Transition.fromJson(data.default_transition);

    block.stepsGroups = data.steps_groups.map((sg) =>
      StepsGroupModel.fromJson(sg, block)
    );

    return block;
  }
}
