import {
  VAR_PAYMENT_TYPE_OPTIONS,
  VARS,
  VAR_SEX_MALE,
  VAR_ATTACH_TYPE_VALUE,
  VAR_DAY_OF_WEEK_OPTIONS,
  VAR_LIKED_TYPE_VALUE,
  VAR_MARKET_ORDER_STATUS,
  VAR_REALM,
  VARS_EVENT_TYPE_VALUE,
} from 'types/vars';

import { COMPARE } from './condition';

export const conditionOptions = [
  {
    value: COMPARE.EXACT,
    label: 'равно',
    help:
      'Условие выполнится, только если строки равны, регистр не учитывается.\n' +
      '✔ "Привет" равно "привет"\n' +
      '❌ "привет, бот" равно "привет"',
  },
  {
    value: COMPARE.NOT_EQUAL,
    label: 'не равно',
    help:
      'Условие выполнится, только если строки не равны, регистр не учитывается.\n' +
      '✔ "привет" не равно "пока"\n' +
      '❌ "привет" не равно "привет"',
  },
  {
    value: COMPARE.LIKE,
    label: 'похоже на',
    help:
      'Условие выполнится, только если они достаточно похожи. При сравнении не учитывается порядок слов, их регистр, формы слов, опечатки и цифры. Порог вероятности — в разделе Настройки.\n' +
      '✔ "сколко товар стоит?" похоже на "сколько стоит"\n' +
      '❌ "привет" похоже на "пока"',
  },
  {
    value: COMPARE.CONTAINS,
    label: 'содержит',
    help:
      'Условие выполнится, только если одна фраза целиком содержится в другой: без учётка регистра, но с учётом порядка слов.\n' +
      '✔ "привет, как дела?" содержит "как дела"\n' +
      '❌ "привет, как у тебя дела?" содержит "как дела"\n' +
      '❌ "привет" содержит "при"',
  },
  {
    value: COMPARE.NOT_CONTAINS,
    label: 'не содержит',
    help:
      'Условие выполнится, только если одна фраза целиком не содержится в другой: без учётка регистра, но с учётом порядка слов.\n' +
      '✔ "привет" не содержит "тест"\n' +
      '❌ "где купить" не содержит "купить"',
  },
  {
    value: COMPARE.STARTS_WITH,
    label: 'начинается с',
    help:
      'Условие выполнится, только если одна строка начинается с указанной подстроки, без учёта регистра.\n' +
      '✔ "привет" начинается с "при"\n' +
      '❌ "ты кто" начинается с "кто"',
  },
  {
    value: COMPARE.ENDS_WITH,
    label: 'заканчивается на',
    help:
      'Условие выполнится, только если одна строка заканчивается указанной подстрокой, без учёта регистра.\n' +
      '✔ "ты кто" заканчивается на "кто"\n' +
      '❌ "привет" заканчивается на "при"',
  },
  {
    value: COMPARE.GREATER,
    label: '>',
    help:
      'Условие выполнится, только если одно число больше другого, строки сравниваются лексикографически, без учёта регистра.\n' +
      '✔ 5 > 4\n' +
      '✔ "я" > "а"',
  },
  {
    value: COMPARE.LESS,
    label: '<',
    help:
      'Условие выполнится, только если одно число меньше другого, строки сравниваются лексикографически, без учёта регистра.\n' +
      '✔ 4 < 5\n' +
      '✔ "A" < "Z"',
  },
  {
    value: COMPARE.GREATER_OR_EQUAL,
    label: '≥',
    help:
      'Условие выполнится, только если одно число больше или равно другому, строки сравниваются лексикографически, без учёта регистра.\n' +
      '✔ 5 >= 5\n' +
      '✔ "я" >= "а"',
  },
  {
    value: COMPARE.LESS_OR_EQUAL,
    label: '≤',
    help:
      'Условие выполнится, только если одно число меньше или равно другому, строки сравниваются лексикографически, без учёта регистра.\n' +
      '✔ 4 <= 4\n' +
      '✔ "A" <= "Z"',
  },
  {
    value: COMPARE.LIST_IN,
    label: 'в списке',
    help: 'Условие выполнится, только если пользователь входит в список.',
  },
  {
    value: COMPARE.LIST_NIN,
    label: 'не в списке',
    help: 'Условие выполнится, только если пользователь не входит в список.',
  },
];

export const exitChainConditionOptions = [
  { value: 'exact', label: 'точно совпадает с' },
  { value: 'contains', label: 'содержит' },
];

const paymentOnlyVars = [VARS.PAYMENT_AMOUNT, VARS.PAYMENT_TYPE];
const adminReplyVars = [VARS.PAYMENT_AMOUNT];
const marketModuleOnlyVars = [
  VARS.ORDER_ID,
  VARS.ORDER_STATUS,
  VARS.ORDER_PRICE,
];
const likeOnlyVars = [VARS.LIKED_TYPE, VARS.LIKED_ID, VARS.USER_ID];
const leadFormsNewVars = [
  VARS.LEAD_ID,
  VARS.LEAD_FORM_ID,
  VARS.LEAD_FORM_NAME,
  VARS.LEAD_ANSWER,
];

export const overallAllowedVars = Object.values(VARS).filter(
  (v) =>
    (!paymentOnlyVars.includes(v) &&
      !leadFormsNewVars.includes(v) &&
      !likeOnlyVars.includes(v) &&
      !marketModuleOnlyVars.includes(v)) ||
    v === VARS.USER_ID
);

export const vkPayReactionAllowedVars = [
  ...overallAllowedVars,
  ...paymentOnlyVars,
];

export const adminReplyReactionAllowedVars = [
  ...overallAllowedVars,
  ...adminReplyVars,
];

export const marketModuleReactionAllowedVars = [
  ...overallAllowedVars,
  ...marketModuleOnlyVars,
];

export const leadFormsNewAllowedVars = [
  ...overallAllowedVars,
  ...leadFormsNewVars,
];

export const likeReactionAllowedVars = likeOnlyVars;

export const repostReactionAllowedVars = [VARS.POST_ID, VARS.USER_ID];

export const DELIVERY_CONDITION_VARS = [
  VARS.SEX,
  VARS.CITY,
  VARS.REF,
  VARS.REF_SOURCE,
  VARS.MESSAGES_RECEIVED,
];

export const LISTS_COMPARING = [COMPARE.LIST_IN, COMPARE.LIST_NIN];

export const getConditionOptionsByKey = (key) => {
  if (key === VARS.MESSAGE) {
    return conditionOptions.filter((o) =>
      [
        COMPARE.EXACT,
        COMPARE.NOT_EQUAL,
        COMPARE.LIKE,
        COMPARE.CONTAINS,
        COMPARE.NOT_CONTAINS,
        COMPARE.STARTS_WITH,
        COMPARE.ENDS_WITH,
      ].includes(o.value)
    );
  }

  if (key === VARS.USER_ID) {
    return conditionOptions.filter((o) =>
      [
        COMPARE.NOT_EQUAL,
        COMPARE.EXACT,
        COMPARE.LIST_IN,
        COMPARE.LIST_NIN,
      ].includes(o.value)
    );
  }

  if (key === VARS.EVENT_TYPE) {
    return conditionOptions.filter((o) =>
      [COMPARE.NOT_EQUAL, COMPARE.EXACT].includes(o.value)
    );
  }

  if (
    [
      VARS.TIME,
      VARS.DATE,
      VARS.SEX,
      VARS.POST_ID,
      VARS.CITY,
      VARS.USER_ID,
      VARS.REF_SOURCE,
      VARS.REF,
      VARS.PAYMENT_TYPE,
      VARS.DAY_OF_WEEK,
      VARS.ATTACH_TYPE,
      VARS.ATTACH_ID,
      VARS.LIKED_TYPE,
      VARS.LIKED_ID,
      VARS.ORDER_ID,
      VARS.ORDER_STATUS,
    ].includes(key)
  ) {
    return conditionOptions.filter((o) =>
      [COMPARE.NOT_EQUAL, COMPARE.EXACT].includes(o.value)
    );
  }
  if (
    [
      VARS.MINUTE,
      VARS.HOUR,
      VARS.DAY,
      VARS.YEAR,
      VARS.PAYMENT_AMOUNT,
      VARS.MESSAGES_RECEIVED,
      VARS.TIMESTAMP,
      VARS.ORDER_PRICE,
    ].includes(key)
  ) {
    return conditionOptions.filter((o) =>
      [
        COMPARE.NOT_EQUAL,
        COMPARE.EXACT,
        COMPARE.LESS_OR_EQUAL,
        COMPARE.LESS,
        COMPARE.GREATER_OR_EQUAL,
        COMPARE.GREATER,
      ].includes(o.value)
    );
  }

  // Теперь все остальные переменные
  return conditionOptions.filter((o) =>
    [
      COMPARE.EXACT,
      COMPARE.NOT_EQUAL,
      COMPARE.CONTAINS,
      COMPARE.NOT_CONTAINS,
      COMPARE.GREATER,
      COMPARE.LESS,
      COMPARE.LESS_OR_EQUAL,
      COMPARE.GREATER_OR_EQUAL,
    ].includes(o.value)
  );
};

export const getDefaultValueForVar = (key) => {
  switch (key) {
    case VARS.SEX:
      return VAR_SEX_MALE;
    case VARS.USER_ID:
      return null;
    case VARS.ATTACH_TYPE:
      return VAR_ATTACH_TYPE_VALUE.IMG;
    case VARS.ATTACH_ID:
      return null;
    case VARS.DAY_OF_WEEK:
      return VAR_DAY_OF_WEEK_OPTIONS[0].value;
    case VARS.PAYMENT_TYPE:
      return VAR_PAYMENT_TYPE_OPTIONS[0].value;
    case VARS.LIKED_TYPE:
      return VAR_LIKED_TYPE_VALUE.POST;
    case VARS.REALM:
      return VAR_REALM.GROUP;
    case VARS.ORDER_STATUS:
      return VAR_MARKET_ORDER_STATUS.NEW;
    case VARS.EVENT_TYPE:
      return VARS_EVENT_TYPE_VALUE.MESSAGE_EVENT;
    default:
      return '';
  }
};

export const getDefaultComparingForVar = (key) => {
  switch (key) {
    case VARS.SEX:
      return COMPARE.EXACT;
    case VARS.MESSAGE:
      return COMPARE.LIKE;
    case VARS.USER_ID:
      return COMPARE.LIST_IN;
    default:
      return COMPARE.EXACT;
  }
};
