import React from 'react';
import { observer } from 'mobx-react';

import DocsHelp from 'components/Help/DocsHelp';
import LandingModel from 'models/Landing/LandingModel';
import { DOCS } from 'types/docs';

import EditInput from '../../editor/EditInput';
import EditImage from '../../editor/EditImage';
import BaseEdit from '../../editor/BaseEdit';

import ImageElement from './ImageElement';

type Props = {
  landing: LandingModel;
  isEditMode?: boolean;
};

const EditBarLanding: React.FC<Props> = (props) => {
  const {
    isEditMode,
    landing: {
      title,
      description,
      imageUrl,
      vkPixel,
      errors,
      setTitle,
      setVkPixel,
      setDescription,
      uploadFile,
      removeFile,
    },
    landing,
  } = props;

  const view = (
    <ImageElement title={title} description={description} imageUrl={imageUrl} />
  );

  if (!isEditMode) {
    return view;
  }

  return (
    <BaseEdit
      landing={landing}
      item={landing}
      index={-1}
      canDelete={false}
      view={view}
      editorComponent={
        <>
          <EditInput
            title="Заголовок"
            value={title}
            onChange={setTitle}
            errorMessage={errors.get('title')}
          />
          <EditInput
            title="Описание"
            value={description}
            onChange={setDescription}
            errorMessage={errors.get('description')}
          />
          <EditImage
            title="Изображение"
            value={imageUrl}
            onChange={uploadFile}
            onRemove={removeFile}
            // eslint-disable-next-line max-len
            placeholder="Загрузите изображение. Максимальный размер файла - 10 Мб. Используйте следующие форматы изображений: .jpeg, .png, .svg. Рекомендуемый размер изображения 1000x300 пикселей."
          />
          <EditInput onChange={setVkPixel} value={vkPixel} title="VK пиксель" />
          <div>
            Создайте пиксель в{' '}
            <a
              href="https://vk.com/ads?act=retargeting&show=pixels"
              target="_blank"
            >
              рекламном кабинете ВК
            </a>{' '}
            и пользователи, открывающие лендинг, будут попадать в выбранную
            аудиторию. <DocsHelp url={DOCS.LANDINGS.VK_PIXEL} />
          </div>
        </>
      }
    />
  );
};

EditBarLanding.defaultProps = {
  isEditMode: false,
};

export default observer(EditBarLanding);
