import { action, computed, observable, toJS } from 'mobx';
import { arrayMove } from 'react-sortable-hoc';

import api from 'store/api';
import apiUrls from 'config/api';
import type { ModuleLandingType } from 'types/moduleLanding';
import generateId from 'utils/generateId';
import { isMax } from 'utils/validation';
import parseItems from 'models/Landing/utils/parseItems';

import switchItemType from './utils/switchItemType';
import SubscribeItemModel from './Elements/SubscribeItemModel';
import ProductItemModel from './Elements/ProductItemModel';
import defaultImg from './img/defaultImage.svg';

export const ITEM_MOVE = {
  UP: 'up',
  DOWN: 'down',
};

class LandingModel {
  id: string = generateId();

  @observable isLoaded = false;

  @observable name = '';

  @observable title = '';

  @observable description = '';

  @observable imageUrl: string = null;

  @observable vkPixel: string = null;

  @observable errors: Map<string, string> = new Map();

  @observable itemIds: string[] = [];

  @observable items: Map<string, SubscribeItemModel | ProductItemModel> =
    new Map();

  @computed
  get itemList() {
    return this.itemIds.map((_id) => this.items.get(_id));
  }

  @computed
  get itemsCount() {
    return this.itemIds.length;
  }

  @computed
  get hasErrors() {
    return this.errors.size > 0;
  }

  constructor({
    name = '',
    title = '',
    description = '',
    imageUrl = null,
    items = [],
    vkPixel = null,
    id = generateId(),
  }) {
    this.id = id;
    this.name = name;
    this.title = title;
    this.description = description;
    this.imageUrl = imageUrl;
    this.vkPixel = vkPixel;
    this.items = parseItems(items);
    this.itemIds = items.map((i) => i._id);
  }

  @action.bound
  setLoaded() {
    this.isLoaded = true;
  }

  @action.bound
  deleteItem(id) {
    this.items.delete(id);
    this.itemIds = this.itemIds.filter((_id) => id !== _id);
  }

  @action.bound
  validate() {
    this.errors.clear();

    if (isMax(this.name, 1024)) {
      this.errors.set('name', 'Максимальное число символов 1024');
    }
    if (isMax(this.title, 1024)) {
      this.errors.set('title', 'Максимальное число символов 1024');
    }
    if (isMax(this.description, 1024)) {
      this.errors.set('description', 'Максимальное число символов 1024');
    }

    return !this.hasErrors;
  }

  @action.bound
  async uploadFile(file) {
    const { response, error } = await api(
      apiUrls.filesUpload,
      'POST',
      {
        ref: JSON.stringify({
          kind: 'landings',
          _id: this.id,
          item_id: null,
        }),
        file,
      },
      {},
      true
    );

    if (response) {
      this.imageUrl = response.url;
    }
  }

  @action.bound
  removeFile() {
    this.imageUrl = null;
  }

  @action.bound
  setVkPixel(value) {
    this.vkPixel = value;
  }

  @action.bound
  setTitle(value) {
    this.title = value;
  }

  @action.bound
  setDescription(value) {
    this.description = value;
  }

  @action.bound
  setName(value) {
    this.name = value;
  }

  @action.bound
  addItem(kind, index) {
    const item = switchItemType(kind);

    this.items.set(item.id, item);
    this.itemIds.splice(index + 1, 0, item.id);
  }

  @action.bound
  moveItem(id, position: $Values<typeof ITEM_MOVE>): boolean {
    const index = this.itemIds.findIndex((_id) => _id === id);

    if (
      index < 0 ||
      (index === 0 && position === ITEM_MOVE.UP) ||
      (index === this.itemsCount - 1 && position === ITEM_MOVE.DOWN)
    ) {
      return false;
    }

    this.itemIds = arrayMove(
      this.itemIds,
      index,
      position === ITEM_MOVE.UP ? index - 1 : index + 1
    );

    return true;
  }

  @action.bound
  async save() {
    const { response } = await api(
      apiUrls.landingUpdate,
      'POST',
      {
        _id: this.id,
        name: this.name,
        title: this.title,
        image_url: this.imageUrl ? this.imageUrl : null,
        vk_pixel: this.vkPixel,
        description: this.description,
        items: this.itemList.map((item) => item.toJson()),
      },
      {
        errorConfig: {
          isShowError: true,
          defaultMessage: 'Ошибка! Изменения не были сохранены',
        },
      }
    );

    return Boolean(response);
  }

  @action.bound
  async get(id): boolean {
    const { response, error } = await api(apiUrls.landingGet, 'GET', {
      _id: id,
    });

    if (response) {
      this.fromJson(response.landing);
      this.isLoaded = true;
      return true;
    }

    return false;
  }

  @action.bound
  async loadView(id): boolean {
    const { response, error } = await api(
      `${apiUrls.landingLoad}${window.LOGIN_PARAMS}`,
      'GET',
      {
        landing_id: id,
      }
    );

    if (response) {
      this.fromJson(response.landing);
      this.isLoaded = true;
      return true;
    }

    return false;
  }

  static createDefault(): LandingModel {
    const id = generateId();

    return new LandingModel({
      id,
      name: null,
      title: 'Заголовок',
      description: 'Описание',
      imageUrl: null,
      items: [],
    });
  }

  toJson() {
    return toJS({
      _id: this.id,
      name: this.name,
      title: this.title,
      image_url: this.imageUrl,
      vk_pixel: this.vkPixel,
      description: this.description,
      items: this.itemList.map((item) => item.toJson()),
    });
  }

  @action.bound
  fromJson(params: ModuleLandingType): LandingModel {
    this.id = params._id;
    this.name = params.name;
    this.title = params.title;
    this.description = params.description;
    this.vkPixel = params.vk_pixel;
    this.imageUrl =
      !params.image_url || params.image_url === 'None'
        ? null
        : params.image_url;
    this.items = parseItems(params.items);
    this.itemIds = params.items.map((i) => i._id);
  }
}

export default LandingModel;
