import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { allVarsListSelector } from 'store/variables/selectors';

export const useEditorVarCompletions = () => {
  const vars = useSelector(allVarsListSelector);

  const visibleVars = useMemo(
    () =>
      vars
        .filter(item => !item.hidden)
        .map(({ key, title }) => ({
          value: key,
          meta: title
        })),
    [vars]
  );

  return useMemo(
    () => [
      {
        identifierRegexps: [/%/],
        getCompletions: (editor, session, pos, prefix, callback) => {
          callback(null, visibleVars);
        }
      }
    ],
    [visibleVars]
  );
};
