import React from 'react';
import { message } from 'antd';

import api, { vkClientApi } from 'store/api';
import apiUrl from 'config/api';
import generateId from 'utils/generateId';
import ButtonLink from 'components/ButtonLink';
import { PageLoadableComponent } from 'components/PageLoader/PageLoader';

import LandingEdit from './LandingEdit';
import { FooterWrapper, UserViewWrapper } from './styles';

type Props = {
  id?: string
};

class LandingUserView extends React.PureComponent<Props> {
  state = {
    isLoading: true,
    buttonVisible: false,
    isSubscribed: false
  };

  async componentDidMount(): void {
    if (!window.GROUP_ID || !window.IS_IFRAME) {
      this.setState({ isLoading: false });
      return;
    }

    const { response } = await api(
      `${apiUrl.deliveryIsSubscribed}${window.LOGIN_PARAMS}`
    );

    if (response) {
      this.setState({
        isSubscribed: response.subscribed
      });
    }

    this.setState({
      buttonVisible: true,
      isLoading: false
    });
  }

  onSubscribe = async () => {
    const { isSubscribed } = this.state;
    const key = generateId();

    if (!isSubscribed) {
      const success = await vkClientApi('VKWebAppAllowMessagesFromGroup', {
        group_id: Number(window.GROUP_ID),
        key
      });
      if (!success) {
        return;
      }
    }

    const url = isSubscribed
      ? apiUrl.deliveryUnsubscribe
      : apiUrl.deliverySubscribe;

    const { response, error } = await api(
      `${url}${window.LOGIN_PARAMS}`,
      'POST'
    );

    if (!error) {
      this.setState(state => ({
        isSubscribed: !state.isSubscribed
      }));

      message.success(
        `Вы успешно ${
          isSubscribed ? 'отписались от рассылки' : 'подписались на рассылку'
        }`
      );
    }
  };

  render() {
    const { buttonVisible, isSubscribed, isLoading } = this.state;

    if (isLoading) {
      return <PageLoadableComponent />;
    }

    return (
      <UserViewWrapper>
        <LandingEdit preview id={this.props.id} />
        {buttonVisible && window.GROUP_ID && (
          <FooterWrapper>
            <ButtonLink onClick={this.onSubscribe}>
              {isSubscribed
                ? 'Отписаться от всех рассылок'
                : 'Подписаться на рассылки'}
            </ButtonLink>
          </FooterWrapper>
        )}
      </UserViewWrapper>
    );
  }
}

LandingUserView.defaultProps = {
  id: undefined
};

export default LandingUserView;
