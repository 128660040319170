export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_SETTINGS_ERROR = 'GET_SETTINGS_ERROR';

export const SAVE_SETTINGS = 'SAVE_SETTINGS';

export const getSettings = () => ({
    type: GET_SETTINGS
});

export const saveSettings = settings => ({
    type: SAVE_SETTINGS,
    settings
});

export default {
    getSettings,
    saveSettings,

    SAVE_SETTINGS,
    GET_SETTINGS, GET_SETTINGS_ERROR
}