import { observable, action, toJS } from 'mobx';

const DEFAULT_KEY = '';
const DEFAULT_COUNT = 5;
const DEFAULT_HEADER = 'Топ:';
const DEFAULT_FORMAT = '';
const FORMAT = '%top_pos%) %имя% %фамилия% - ';

export default class ShowTopParamsModel {
  @observable key: string = DEFAULT_KEY;

  @observable count: number = DEFAULT_COUNT;

  @observable header: string = DEFAULT_HEADER;

  @observable format: string = DEFAULT_FORMAT;

  isChanged: boolean = false;

  constructor({ key, count, header, format, isChanged }) {
    this.key = key;
    this.count = count;
    this.header = header;
    this.format = format;
    this.isChanged = isChanged;
  }

  @action.bound
  setKey(value) {
    this.key = value;
    if (!this.isChanged) {
      this.isChanged = true;
      this.format = FORMAT + value;
    }
  }

  @action.bound
  setCount(value) {
    const numberValue = Number(value);
    this.count = numberValue < 0 ? 0 : numberValue;
  }

  @action.bound
  setHeader(value) {
    this.header = value;
  }

  @action.bound
  setFormat(value) {
    this.format = value;
    this.isChanged = true;
  }

  validate = () => {
    const errors = [];
    if (!this.key || !this.key.trim()) {
      errors.push('Не заполнено поле переменная.');
    }
    if (!this.header || !this.header.trim()) {
      errors.push('Не заполнено поле заголовок списка.');
    }
    if (!this.format || !this.format.trim()) {
      errors.push('Не заполнено поле формат строки списка.');
    }

    return errors;
  };

  toJson() {
    return toJS({
      key: this.key,
      count: this.count,
      header: this.header,
      format: this.format
    });
  }

  static createDefault(): ShowTopParamsModel {
    return new ShowTopParamsModel({
      key: DEFAULT_KEY,
      count: DEFAULT_COUNT,
      header: DEFAULT_HEADER,
      format: DEFAULT_FORMAT,
      isChanged: false
    });
  }

  static fromJson(params): ShowTopParamsModel {
    return new ShowTopParamsModel({
      key: params.key,
      count: params.count,
      header: params.header,
      format: params.format,
      isChanged: true
    });
  }
}
