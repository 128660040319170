import React from 'react';
import { Select } from 'antd';

const Option = Select.Option;

export default React.forwardRef(
  (
    {
      options,
      multi = false,
      value = null,
      placeholder = 'Выберите',
      onChange,
      ...props
    },
    ref
  ) => {
    if (value !== undefined && value !== null && value !== '') {
      props.value = value;
    }

    return (
      <Select
        ref={ref}
        mode={multi ? 'multiple' : 'default'}
        placeholder={placeholder}
        onChange={onChange}
        optionFilterProp="label"
        optionLabelProp="label"
        filterOption={(input, option) => {
          let result = false;
          try {
            result =
              option?.props?.children?.props?.label
                ?.toLowerCase()
                .indexOf(input.toLowerCase()) >= 0;
          } catch (e) {}

          try {
            result =
              option?.props?.label
                ?.toLowerCase()
                .indexOf(input.toLowerCase()) >= 0;
          } catch (e) {}

          return result;
          // try {
          //   return (
          //     option.props?.children?.props?.children
          //       .toLowerCase()
          //       .indexOf(input.toLowerCase()) >= 0
          //   );
          // } catch (e) {}
          //
          // try {
          //   return (
          //     option.props.children
          //       .toLowerCase()
          //       .indexOf(input.toLowerCase()) >= 0
          //   );
          // } catch (e) {
          //   return false;
          // }
        }}
        {...props}
      >
        {options.map(({ value, label, renderLabel }) => (
          <Option key={value} label={label}>
            {renderLabel ? renderLabel(label) : label}
          </Option>
        ))}
      </Select>
    );
  }
);
