import styled from 'styled-components';
import { Input as BaseInput } from 'antd';
import { color, globalSpacingStyle } from 'styles/constants';

export const Wrapper = styled.div`
  padding: ${globalSpacingStyle.m};
  font-size: 12px;
`;

export const Input = styled(BaseInput)`
  margin: ${globalSpacingStyle.m} 0 !important;
`;

export const Error = styled.div`
  color: ${color.error};
`;