import { observable, action, computed } from 'mobx';

import api from 'store/api';
import apiUrls from 'config/api';
import type { ModuleType } from 'types/modules';
import { MODULE_KIND } from 'types/modules';

import ModuleModel from './Module';

export const parseModules = modulesToParse =>
  modulesToParse.reduce(
    (acc, next) => ({
      ...acc,
      [next.kind]: ModuleModel.fromJson(next)
    }),
    {}
  );

class ModuleStore {
  rootStore = null;

  @observable modules: Map<ModuleModel> = new Map();

  // флаг ошибки списка модулей

  @observable modulesListIsError: boolean = false;

  // флаг ошибки в модуле
  @observable moduleEntityIsError: boolean = false;

  // флаг загрузки списка модулей
  @observable modulesListIsLoading: boolean = false;

  // флаг загрузки модуля
  @observable moduleEntityIsLoading: boolean = false;

  @observable order = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get modulesList() {
    return this.order.map(key => this.modules.get(key));
  }

  @computed
  get installModulesSteps(): Array<string> {
    return this.modulesList
      .filter(module => module.enabled)
      .map(module =>
        module.steps.length === 1 && module.kind !== MODULE_KIND.GOOGLE_SHEETS
          ? module.steps[0]
          : module.kind
      );
  }

  @action.bound
  async loadModules() {
    this.modulesListIsLoading = true;

    const { response, error } = await api(apiUrls.modulesList);

    this.modulesListIsLoading = false;

    return this.setModules(response?.results);
  }

  @action.bound
  setModules(modules: ModuleType[]) {
    this.modulesListIsError = false;

    if (modules || !modules.length) {
      this.modules.merge(parseModules(modules));
      this.order = modules.map(({ kind }) => kind);
      return true;
    }

    this.modulesListIsError = true;
    return false;
  }

  getModule(kind: string) {
    return this.modules.get(kind);
  }

  @action
  async loadModule(kind: string) {
    this.moduleEntityIsLoading = true;
    this.moduleEntityIsError = false;

    const module: ModuleModel = await ModuleModel.load(kind);
    module.setRootStore(this.rootStore);

    this.moduleEntityIsLoading = false;

    if (!module) {
      this.moduleEntityIsError = true;
      return;
    }

    this.modules.set(kind, module);
  }
}

export default ModuleStore;
