export const WIDGET_COLUMN_ALIGN = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right'
};

export type WidgetVKHeaderItem = {
  text: string,
  align?: $Values<typeof WIDGET_COLUMN_ALIGN>
};

export type WidgetVKCell = {
  text: string,
  url?: string,
  icon_id?: string
};

export type WidgetVKRow = WidgetVKCell[];

export type WidgetVKPreview = {
  title: string,
  title_url: string,
  title_counter: number,
  more: string,
  more_url: string,
  head: WidgetVKHeaderItem[],
  body: WidgetVKRow[]
};
