import { action, computed, observable, toJS } from 'mobx';

import apiUrls from 'config/api';
import api from 'store/api';
import type { ItemButtonsType, ItemType } from 'types/moduleLanding';
import generateId from 'utils/generateId';
import { isMax } from 'utils/validation';

export class LandingButtonModel {
  @observable text = '';

  @observable openChat = true;

  @observable error = '';

  constructor({ text, openChat }) {
    this.text = text;
    this.openChat = openChat;
  }

  @action.bound
  setText(value) {
    this.text = value;
  }

  @action.bound
  setOpenChat(e) {
    this.openChat = e.target.checked;
  }

  validate(): boolean {
    this.error = '';

    if (this.text.length < 1 || this.text.length > 50) {
      this.error = 'Текст на кнопке должен быть от 1 до 50 символов';
    }

    return !this.error;
  }

  toJson() {
    return toJS({
      text: this.text,
      open_chat: this.openChat,
    });
  }

  static fromJson(json): LandingButtonModel {
    return new LandingButtonModel({
      text: json.text,
      openChat: json.open_chat !== undefined ? json.open_chat : false, // old default
    });
  }

  static createDefault(text, openChat): LandingButtonModel {
    return new LandingButtonModel({
      text,
      openChat,
    });
  }
}

class ItemModel {
  id: string = generateId();

  @observable kind: string;

  @observable title: string;

  @observable description: string;

  @observable imageUrl: string;

  @observable blockId: string;

  @observable buttons: { [type: ItemButtonsType]: LandingButtonModel };

  @observable errors: Map<string, string> = new Map();

  constructor({
    id = generateId(),
    kind,
    title,
    description,
    imageUrl,
    buttons,
    blockId,
  }) {
    this.id = id;
    this.kind = kind;
    this.title = title;
    this.description = description;
    this.imageUrl = imageUrl;
    this.blockId = blockId;
    this.buttons = buttons;
  }

  @computed
  get hasErrors() {
    return this.errors.size > 0;
  }

  @action
  setTitle(value) {
    this.title = value;
  }

  @action
  setDescription(value) {
    this.description = value;
  }

  @action.bound
  setBlockId(value) {
    this.blockId = value;
  }

  @action.bound
  async uploadFile(file, landingId) {
    const { response, error } = await api(
      apiUrls.filesUpload,
      'POST',
      {
        ref: JSON.stringify({
          kind: 'landings',
          _id: landingId,
          item_id: this.id,
        }),
        file,
      },
      {},
      true
    );

    if (response) {
      this.imageUrl = response.url;
    }

    return response;
  }

  @action.bound
  removeFile() {
    this.imageUrl = null;
  }

  // true if OK
  @action
  validate() {
    this.errors.clear();

    if (isMax(this.title, 1024)) {
      this.errors.set('title', 'Максимальное число символов 1024');
    }
    if (isMax(this.description, 1024)) {
      this.errors.set('description', 'Максимальное число символов 1024');
    }

    let buttonsValid = true;
    Object.values(this.buttons).forEach((b) => {
      if (!b.validate()) {
        buttonsValid = false;
      }
    });

    return !this.hasErrors && buttonsValid;
  }

  static createDefault(): ItemModel {
    return new ItemModel({
      kind: '',
      title: 'Заголовок',
      description: 'Описание',
      imageUrl: null,
      blockId: '',
      buttons: {},
    });
  }

  toJson() {
    return toJS({
      _id: this.id,
      title: this.title,
      description: this.description,
      image_url: this.imageUrl,
      block_id: this.blockId,
      kind: this.kind,
      buttons: Object.keys(this.buttons).reduce(
        (acc, type) => ({
          ...acc,
          [type]: this.buttons[type].toJson(),
        }),
        {}
      ),
    });
  }

  static fromJson(params: ItemType): ItemModel {
    return new ItemModel({
      id: params._id,
      kind: params.kind,
      title: params.title,
      description: params.description,
      imageUrl: params.image_url,
      blockId: params.block_id,
      buttons: Object.keys(params.buttons || {}).reduce(
        (acc, type) => ({
          ...acc,
          [type]: LandingButtonModel.fromJson(params.buttons[type]),
        }),
        {}
      ),
    });
  }
}

export default ItemModel;
