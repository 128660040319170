import styled from 'styled-components';

import { color, mediaSizes, globalSpacingStyle } from 'styles/constants';

export const Image = styled.div`
  position: relative;
  background: url(${(props) => props.image}) no-repeat center;
  background-size: cover;
  width: 100%;
  padding-bottom: 30%;
`;

export const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: ${mediaSizes.maxWidthLanding};

  .is-mobile & {
    padding: ${globalSpacingStyle.l};
  }
`;

export const Title = styled.div`
  position: relative;
  font-size: 30px;
  line-height: 1.5;
  font-weight: 700;
  white-space: pre-wrap;
  color: ${color.vkText};
  text-align: center;
  margin: ${globalSpacingStyle.l} auto;
`;

export const Description = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
  margin: 0 auto ${globalSpacingStyle.m} auto;
`;
