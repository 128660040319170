import { VARS } from './vars';

export const EXPR = 'expr';

export enum CONDITION_KEY {
  MESSAGE = 'messageCondition',
  VARS = 'varsCondition',
  SQ = 'sqCondition',
}

export enum CONCAT_OPTIONS {
  OR = '@or',
  AND = '@and',
}

export enum COMPARE {
  EXACT = '@eq',
  LIKE = '@like',
  CONTAINS = '@str_in',
  NOT_CONTAINS = '@str_nin',
  STARTS_WITH = '@str_starts',
  ENDS_WITH = '@str_ends',
  GREATER = '@gt',
  LESS = '@lt',
  NOT_EQUAL = '@ne',
  LESS_OR_EQUAL = '@lte',
  GREATER_OR_EQUAL = '@gte',
  LIST_IN = '@list_in',
  LIST_NIN = '@list_nin',
}

export type ComparingType = {
  key: string;
  value: string;
  comparing: COMPARE;
};

export type SingleConditionType = {
  conditions: Array<ComparingType>;
  concatOption: CONCAT_OPTIONS.OR | CONCAT_OPTIONS.AND;
};

export type ConditionType = {
  messageCondition: SingleConditionType;
  varsCondition: SingleConditionType;
};

export function createDefaultCondition(
  type: CONDITION_KEY.MESSAGE | CONDITION_KEY.VARS = CONDITION_KEY.MESSAGE
) {
  return {
    key: type === CONDITION_KEY.MESSAGE ? VARS.MESSAGE : VARS.USER_ID,
    value: type === CONDITION_KEY.MESSAGE ? '' : null,
    comparing: type === CONDITION_KEY.MESSAGE ? COMPARE.LIKE : COMPARE.LIST_IN,
  };
}

export function createEmptyConditions(
  withDefaultCondition = false,
  withDefaultVarCondition = false
): ConditionType {
  return {
    messageCondition: {
      conditions: withDefaultCondition ? [createDefaultCondition()] : [],
      concatOption: CONCAT_OPTIONS.OR,
    },
    varsCondition: {
      conditions: withDefaultVarCondition
        ? [createDefaultCondition(CONDITION_KEY.VARS)]
        : [],
      concatOption: CONCAT_OPTIONS.OR,
    },
  };
}

export function normalizeSingleCondition(condition): SingleConditionType {
  let concatOption = CONCAT_OPTIONS.OR;
  let conditions = [];

  if (!condition || Object.keys(condition).length === 0) {
    return {
      concatOption,
      conditions,
    };
  }

  const topLevelKey = Object.keys(condition)[0];

  if ([CONCAT_OPTIONS.OR, CONCAT_OPTIONS.AND].includes(topLevelKey)) {
    // Это объединение
    concatOption = topLevelKey;

    conditions = condition[concatOption].map((singleCondition) => {
      if (singleCondition.kind === 'expr') {
        return {
          comparing: '@eq',
          key: 'expr',
          value: singleCondition.params.expr,
        };
      }

      const comparing = Object.keys(singleCondition.params.value)[0];

      return {
        comparing,
        key: singleCondition.params.key,
        value: singleCondition.params.value[comparing],
      };
    });

    return {
      concatOption,
      conditions,
    };
  }
  // Это единственное условие

  if (condition.kind === 'expr') {
    return {
      concatOption,
      conditions: [
        {
          comparing: '@eq',
          key: 'expr',
          value: condition.params.expr,
        },
      ],
    };
  }

  const comparing = Object.keys(condition.params.value)[0];

  return {
    concatOption,
    conditions: [
      {
        comparing,
        key: condition.params.key,
        value: condition.params.value[comparing],
      },
    ],
  };
}

export function convertSingleCondition(rootCondition: SingleConditionType) {
  const { concatOption, conditions } = rootCondition;

  const concat = concatOption;

  let conditionData = {};
  if (concat) {
    conditionData[concat] = [];
  }

  conditions.forEach((condition: ComparingType) => {
    const data = {
      kind: 'var',
      params: {
        key: condition.key,
        value: {
          [condition.comparing]: condition.value,
        },
      },
    };
    if (conditions.length > 1) {
      conditionData[concat].push(data);
    } else {
      conditionData = data;
    }
  });

  return conditionData;
}

export function normalizeCondition(stepsGroup): ConditionType {
  return {
    messageCondition: normalizeSingleCondition(stepsGroup.message_condition),
    varsCondition: normalizeSingleCondition(stepsGroup.vars_condition),
  };
}

export function convertConditionsToServerFormat(condition: ConditionType) {
  const { messageCondition, varsCondition } = condition;
  return {
    message_condition: convertSingleCondition(messageCondition),
    vars_condition: convertSingleCondition(varsCondition),
  };
}
