import { observable, action, toJS } from 'mobx';

import { isCorrectGoogleDocsUrl } from 'utils/validation';
import {
  MAX_CELL_SIZE,
  MAX_ROW,
  DEFAULT_CELLS
} from 'models/Step/config/modelsVarsValidate';

import { validateSheetUrl } from './utils';

export default class GoogleSheetsAddParamsModel {
  @observable rows: Array<string> = DEFAULT_CELLS;

  @observable list = '';

  @observable sheetUrl: string = '';

  constructor({ rows, list, sheetUrl }) {
    this.rows = rows;
    this.list = list;
    this.sheetUrl = sheetUrl;
  }

  @action.bound
  setList(list) {
    this.list = list;
  }

  @action.bound
  addRow() {
    this.rows.push('');
  }

  @action.bound
  deleteRow(index) {
    this.rows.splice(index, 1);
  }

  @action.bound
  setUrl(sheetUrl) {
    this.sheetUrl = sheetUrl;
  }

  @action.bound
  setRow(index, row) {
    this.rows[index] = row;
  }

  validate() {
    const errors = [];

    errors.push(...validateSheetUrl(this.sheetUrl));

    if (this.rows.some((row: string) => row.length >= MAX_CELL_SIZE)) {
      errors.push(
        `Ячейка не может быть длиной более, чем ${MAX_CELL_SIZE} символа.`
      );
    }

    if (this.rows.length > MAX_ROW) {
      errors.push(`Количество ячеек может быть не более ${MAX_ROW}.`);
    }

    return errors;
  }

  toJson() {
    return toJS({
      spreadsheet_url: this.sheetUrl,
      row: this.rows,
      list: this.list
    });
  }

  static createDefault() {
    return new GoogleSheetsAddParamsModel({
      rows: DEFAULT_CELLS,
      list: '',
      sheetUrl: ''
    });
  }

  static fromJson(params): GoogleSheetsAddParamsModel {
    const data = {
      rows: params.row,
      list: params.list,
      sheetUrl: params.spreadsheet_url
    };

    return new GoogleSheetsAddParamsModel(data);
  }
}
