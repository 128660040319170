export default {
  all: {
    key: 'all',
    value: 'отменить все события',
    valueMobile: 'отменить все'
  },
  one: {
    key: 'one',
    value: 'отменить одно конкретное',
    valueMobile: 'отменить одно'
  }
};
